<template>
  <div class="col-12 vh-100 m-auto">
    <div class="col calendar-header d-flex" v-if="windowWidth <= 1600">
      <select
        class="form-select mt-auto"
        style="max-width: 360px!important; min-width: 100px!important; height: 40px!important;"
        v-model="year"
      >
        <option :key="i" v-for="(y, i) in years">{{ y }}</option>
      </select>
      <div :class="['calendar-month-header', {active: month === i}]" v-for="(m, i) in months"
           :key="i"
           @click="() => {
             month = i;
           }"
      >
        {{ m }}
      </div>
    </div>
    <div class="col calendar-header d-flex m-auto" v-else>
      <select
        class="form-select mt-auto"
        style="max-width: 360px!important; min-width: 100px!important; height: 40px!important;"
        v-model="year"
      >
        <option :key="i" v-for="(y, i) in years">{{ y }}</option>
      </select>
      <div :class="['calendar-month-header', {active: month === i}]" v-for="(m, i) in monthLabel"
           :key="i"
           @click="() => {
             month = i;
           }"
      >
        {{ m }}
      </div>
    </div>
    <div class="calendar-sub-header row mt-5 mb-4 d-flex">
      <div class="col-8">
        <h3>{{ monthLabel[month][0].toUpperCase() + monthLabel[month].substring(1) }}</h3>
        <div class="col d-flex">
          <span class="mt-2" style="margin-right: 5px">Visualização:</span>
          <div class="input-group rounded-5 border-1"
               style="max-height:40px!important;border-color: #e9edef!important;background-color: rgba(226, 230, 232, 0.4);max-width: 180px!important">
            <label class="input-group-text rounded-4 border-0" style="max-height: 35px!important;"
                   for="floatingInputSelect">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_7482_69160)">
                  <path
                    d="M18 5H6C4.89543 5 4 5.89543 4 7V19C4 20.1046 4.89543 21 6 21H18C19.1046 21 20 20.1046 20 19V7C20 5.89543 19.1046 5 18 5Z"
                    stroke="#373C3F" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M16 3V7" stroke="#373C3F" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                  <path d="M8 3V7" stroke="#373C3F" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                  <path d="M4 11H20" stroke="#373C3F" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                  <path d="M9.5 15H8" stroke="#373C3F" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_7482_69160">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>

            </label>
            <select
              id="floatingInputSelect"
              class="form-select border-0 rounded-4"
              style="max-height: 40px!important;max-width: 150px!important; background-color: transparent!important;"
              v-model="viewMode"
            >
              <option :key="i" v-for="(y, i) in ['Mensal', 'Semanal']">{{ y }}</option>
            </select>
          </div>
          <div v-if="viewMode === 'Semanal'" class="frame-week mx-4 align-content-center">
            <svg @click="viewWeek = (viewWeek > 0) ? viewWeek-1 : 0" width="24" height="24"
                 viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_7482_69483)">
                <rect width="24" height="24" rx="12" fill="#F2F3F6" />
                <path d="M15 6L9 12L15 18" stroke="#373C3F" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_7482_69483">
                  <rect width="24" height="24" rx="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <p class="m-auto">Semana {{ viewWeek + 1 }}</p>
            <svg
              @click="viewWeek = ((viewWeek + 1) < viewWeekLimit) ? viewWeek + 1 : (viewWeekLimit - 1)"
              width="24" height="24" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_7482_69488)">
                <rect width="24" height="24" rx="12" fill="#F2F3F6" />
                <path d="M9 6L15 12L9 18" stroke="#373C3F" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_7482_69488">
                  <rect width="24" height="24" rx="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>

      <div class="col-4 d-flex justify-content-end">
        <div class="mt-5">
          <EpLegend
            :legend="[
              {
                title: 'Status de evento: ',
                symbol: '',
                native: true
              },
              {
                title: 'Pendente',
                symbol: 'ellipse ellipse-light',
                native: true
              },
              {
                title: 'Publicado',
                symbol: 'ellipse ellipse-info',
                native: true
              },
              {
                title: 'Aberto',
                symbol: 'ellipse ellipse-success',
                native: true
              },
              {
                title: 'Fechado',
                symbol: 'ellipse ellipse-danger',
                native: true
              }
            ]"></EpLegend>
        </div>
      </div>
    </div>

    <div class="">
      <div class='row-weekdays-header mb-2'>
        <div class='align-content-center weekdays-header'
             v-for="(weekday, d) in weekDays" :key="d">
          {{ weekday }}
        </div>
      </div>
      <div v-if="viewMode === 'Semanal'">
        <div class='d-flex'>
          <div
            title="Clique duas vezes para criar um novo evento!"
            :class="[
            'align-content-center week-cell border-2',
            {
              'bg-light' :
                day === new Date().getDate() &&
                month === new Date().getMonth()
            }
          ]"
            @dblclick="$emit('createdEvent', new Date(year, month, day))"
            style="height: 800px"
            v-for="(day, k) in daysCalendar[viewWeek]" :key="k"
          >
            <div style="width: 100%; display: inline-block"
                 class="label-day text-end align-content-end mb-1">
            <span
              style="font-size: 14px"
              :class="{
                'badge rounded-pill text-bg-dark' :
                  day === new Date().getDate() &&
                  month === new Date().getMonth()
              }">
              {{ day }}
            </span>
            </div>
            <div v-show="eventsDate[day] !== undefined">
              <div
                @click="$emit('updatedEvent', event.id)"
                :class="[
                  'label-event mb-1 pointer',
                  {
                    'open': event.status === 'A',
                    'closed': event.status === 'F',
                    'pedding': event.status === 'P'
                  }]" :key="e" v-for="(event, e) in eventsDate[day]">
                {{ event.name }} <br />
                <span>
                {{ event.start }} - {{ event.end }}
              </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class='d-flex' v-for="(row, i) in daysCalendar" :key="i">
          <div
            title="Clique duas vezes para criar um novo evento!"
            :class="[
            'align-content-center weekdays-cell border-2',
            {
              'bg-light' :
                day === new Date().getDate() &&
                month === new Date().getMonth()
            }
          ]"
            @dblclick="$emit('createdEvent', new Date(year, month, day))"
            v-for="(day, k) in row" :key="k"
          >
            <div style="width: 100%; display: inline-block"
                 class="label-day text-end align-content-end mb-1">
            <span
              style="font-size: 14px"
              :class="{
                'badge rounded-pill text-bg-dark' :
                  day === new Date().getDate() &&
                  month === new Date().getMonth()
              }">
              {{ day }}
            </span>
            </div>
            <div v-show="eventsDate[day] !== undefined">

              <div
                @click="$emit('updatedEvent', event.id)"
                :class="[
              'label-event mb-1 pointer',
              {
                'open': event.status === 'A',
                'closed': event.status === 'F',
                'pedding-publish': event.status === 'P' && event.publicado <= 0,
                'pedding': event.status === 'P' && event.publicado > 0,
              }
              ]"

                :key="e" v-for="(event, e) in eventsDate[day]">
                {{ event.name }} <br>
                <span>
                {{ event.start }} - {{ event.end }}
              </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import EpLegend from '@/components/global/EpLegend.vue';

export default {
  components: { EpLegend },
  props: ['eventsAll'],
  data: function() {
    return {
      windowWidth: window.innerWidth,
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
      monthLabel: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ],
      years: Array.from({ length: 25 }, (_, i) => Number(new Date().getFullYear()) + 1 - i),
      days: [],
      months: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez'
      ],
      weekDays: [
        'Domingo',
        'Segunda',
        'Terça',
        'Quarta',
        'Quinta',
        'Sexta',
        'Sábado'
      ],
      viewMode: 'Mensal',
      viewWeek: 0,
      eventsDate: new Array(42)
    };
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  computed: {
    daysCalendar: function() {
      let m = this.month;
      let y = this.year;

      let compileDays = () => {
        let daysAll = [];
        let date = new Date(y, Number((Number(m) + 1)), 0);
        let daysMonth = date.getDate();

        daysAll = new Array(daysMonth);
        for (let i = 1; i <= daysMonth; i++) {
          daysAll.push(i);
        }
        return daysAll;
      };

      let days = compileDays();

      let data = [];
      let week = 0;
      let initialPosition = 0;
      let firstDay = new Date(y, m, 1);
      days.forEach((day) => {
        if (Number(day) === 1) {
          initialPosition = firstDay.getDay();
        }

        if (!data[week]) {
          data[week] = new Array(7);
        }

        data[week][initialPosition] = day;

        initialPosition++;
        if ((initialPosition % 7) === 0) {
          week++;
          initialPosition = 0;
        }
      });
      this.eventProcess(this.eventsAll);
      return data;
    },
    viewWeekLimit: function() {
      return this.daysCalendar.length;
    }
  },
  watch: {
    year: function(value) {
      let lastDay = new Date(value, (this.month + 1), 0).getDate();
      this.$emit('change-filter', {
        start: new Date(value, this.month, 1).toLocaleDateString(),
        end: new Date(value, this.month, lastDay).toLocaleDateString()
      });
    },
    month: function(value) {
      let lastDay = new Date(this.year, (value + 1), 0).getDate();
      this.$emit('change-filter', {
        start: new Date(this.year, value, 1).toLocaleDateString(),
        end: new Date(this.year, value, lastDay).toLocaleDateString()
      });
    }
  },
  methods: {
    eventProcess(events) {
      let lastDay = new Date(this.year, (this.month + 1), 0).getDate();
      this.eventsDate = new Array(lastDay);


      events.map(event => {
        for (let i = 1; i <= lastDay; i++) {
          let date = new Date(this.year, this.month, i);
          let weekDay = date.getDay();
          if (!this.eventsDate[i]) {
            this.eventsDate[i] = [];
          }

          if (
            event.recorrence.find((v, k) => Number(v) === Number(weekDay)) !== undefined &&
            (
              new Date(event.dateStart).getTime() <= date.getTime() &&
              new Date(event.dateEnd).getTime() >= date.getTime()
            )
          ) {

            let name = event.name;
            let child = null;
            let extendLabel = false;
            let validateChild = false;
            if (event.eventChild) {
              validateChild = true;
              child = event.eventChild.find((v, k) => new Date(v.data_event).toLocaleDateString() === new Date(date).toLocaleDateString());

              if (child) {
                extendLabel = new Date(child.data_event).toLocaleDateString() !== new Date(child.data_event_fim).toLocaleString();

                if (name !== child.descr_event) {
                  name = child.descr_event;
                }
              }
            }

            if (validateChild && !child) {
              continue;
            }

            let ev = {
              id: child ? child.cod_event : '',
              gevent: event.id,
              name: name,
              recorrence: event.recorrence,
              date: date,
              start: child ? child.inicio : event.start,
              end: child ? child.fim : event.end,
              status: child ? child.status_event : event.status,
              publicado: child ? child.publicado : event.publicado,
              extendLabel: extendLabel
            };

            this.eventsDate[i].push(ev);
          }
        }
      });
    }
  }
};
</script>


<style scoped lang="scss">
#app {
  overflow: auto !important;
}

.label-event {
  /* Frame 20224 */
  font-size: 14px;

  /* Auto layout */
  justify-content: center;
  align-items: flex-start;
  padding: 6px;
  gap: 10px;

  width: 148.29px;

  border-radius: 3px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.open {
  background: #B0FFD9;
}

.closed {
  background: #FFA8C2;
}

.pedding {
  background: #a4c2f1;
}
.pedding-publish {
  background: #DCDDE4;
}

.frame-week {
  /* Frame 20192 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;

  width: 162px;
  height: 40px;

  border-radius: 89px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

}

.calendar-header {
  /* Frame 20187 */

  /* Auto layout */
  align-items: center;
  padding: 0px;
  gap: 25px;

  width: 100%;
  height: 38px;
}

.calendar-month-header {
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  gap: 15px;

  height: 38px;

  /* Neutral Color/01 Neutral color */
  background: #FFFFFF;
}

.calendar-sub-header {
  /* Frame 20200 */
  min-width: 1266px;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 3px;

  width: 100%;
  height: 79px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.row-weekdays-header {
  /* Weeks */
  min-width: 1266px;

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px !important;

  width: 100%;
  height: 54px;

  /* Neutral Color/01

  Neutral color
  */
  background: #FFFFFF;
  /* Neutral Color/03 */
  border: 1px solid #DCDDE4;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.weekdays-header {
  /* Frame 20209 */
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 30px 45px;
  gap: 10px;

  width: 182.58px;
  height: 54px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0;
}

.weekdays-cell {
  /* Day */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 10px;

  width: 182.58px;
  height: 177px;

  /* Neutral Color/01

  Neutral color
  */
  background: #FFFFFF;
  /* Neutral Color/03 */
  border: 1px solid #DCDDE4;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px -1px;
}

.week-cell {
  /* Day */

  /* Day */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 10px;

  width: 182.58px;

  /* Neutral Color/01

  Neutral color
  */
  background: #FFFFFF;
  /* Neutral Color/03 */
  border: 1px solid #DCDDE4;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
  margin: 0px -1px;
}

.active {
  /* Brand/ERP/Pure */
  color: #FFFFFF;
  background: #872240 !important;
  border-radius: 8px;
}

</style>
