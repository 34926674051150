import importProductsService from '@/services/importProducts.service';

export const importModule = {
  namespaced: true,
  state: {
    products: {
      links: {},
      meta: {},
      results: []
    },
    page: null,
    perPage: 15,
    filters: '',
    search: ''
  },
  mutations: {
    FILL_IMPORT(state, products) {
      if (this.state.importModule.page) {
        state.products.links = products.links;
        state.products.meta = products.meta;
        products.results.map((obj) => {
          state.products.results.push(obj);
        });
      } else {
        state.products = products;
      }
    }
  },
  actions: {
    async getProducts({ commit }, id) {
      const response = await importProductsService.list(
        this.state.importModule.page || `/importacao-produto/${id}?page=1`,
        this.state.importModule.perPage,
        this.state.importModule.filters
      );
      response.code === 200 ? commit('FILL_IMPORT', response.data) : null;
    }
  },
  getters: {
    page: (state) => state.page,
    perPage: (state) => state.perPage,
    search: (state) => state.search,
    products: (state) => state.products
  }
};
