import PaymentMethodsService from '@/services/paymentMethods.service';

export const paymentMethods = {
  namespaced: true,
  state: {
    payment: {},
    paymentMethods: {
      links: {},
      meta: {},
      results: [],
    },
    newPaymentMethod: {},
    orderSelected: 'asc',
    perPage: 15,
    banks: {},
    coastCenter: {},
    accountPlanRevenue: {},
    accountPlanExpense: {},
    accountPlanRevenueOmie: {},
    accountPlanExpenseOmie: {},
    cardAdmin: {},
  },
  mutations: {
    FILL_PAYMENT_METHODS(state, paymentMethods) {
      state.paymentMethods = paymentMethods;
    },
    FILL_PAYMENT(state, payment) {
      state.payment = payment;
    },
    FILL_NEW_PAYMENT(state, newPaymentMethod) {
      state.newPaymentMethod = newPaymentMethod;
    },
    SELECT_PAYMENT_METHODS_PER_PAGE(state, perPage) {
      state.perPage = perPage;
    },
    FILL_BANKS(state, banks) {
      state.banks = banks;
    },
    FILL_COAST(state, coastCenter) {
      state.coastCenter = coastCenter;
    },
    FILL_ACCOUNT_PLAN_REVENUE(state, accountPlanRevenue) {
      state.accountPlanRevenue = accountPlanRevenue;
    },
    FILL_ACCOUNT_PLAN_EXPENSE(state, accountPlanExpense) {
      state.accountPlanExpense = accountPlanExpense;
    },
    FILL_ACCOUNT_PLAN_REVENUE_OMIE(state, accountPlanRevenueOmie) {
      state.accountPlanRevenueOmie = accountPlanRevenueOmie;
    },
    FILL_ACCOUNT_PLAN_EXPENSE_OMIE(state, accountPlanExpenseOmie) {
      state.accountPlanExpenseOmie = accountPlanExpenseOmie;
    },
    FILL_CARD_ADMIN(state, cardAdmin) {
      state.cardAdmin = cardAdmin;
    },
  },
  actions: {
    async newPaymentMethod({ commit }, payload) {
      await PaymentMethodsService.newPaymentMethod(payload)
        .then((response) => {
          commit('FILL_NEW_PAYMENT', response.data);
          Toast.fire('Forma de pagamento cadastrada com sucesso!', '', 'success');
        })
        .catch((error) => {
          Toast.fire({
            title: 'Atenção',
            html: `
              Erro ao cadastrar Forma de pagamento! Por favor, tente novamente.<br>
              <span style="font-size: 12px">- ${error.response.data.data.message}</span>
              `,
            icon: 'error',
            width: 600,
            showCloseButton: true,
            timerProgressBar: false,
          });
          throw error.response.data;
        });
    },
    async getPaymentMethods({ commit }) {
      const paymentMethods = await PaymentMethodsService.getPaymentMethods(
        this.state.paymentMethods.orderSelected
      );
      paymentMethods.code === 200 ? commit('FILL_PAYMENT_METHODS', paymentMethods.data) : null;
    },
    async searchPaymentMethod({ commit }, term) {
      const paymentMethod = await PaymentMethodsService.searchPaymentMethod(term);
      paymentMethod.code === 200 ? commit('FILL_PAYMENT_METHODS', paymentMethod.data) : null;
    },
    async removePaymentMethod({ commit }, payment) {
      let paymentMethods = { ...this.state.paymentMethods.paymentMethods };
      paymentMethods.result = paymentMethods.results.filter(
        (item) => item.cod_tpag != payment.cod_tpag
      );
      return PaymentMethodsService.removePaymentMethod(payment)
        .then(() => {
          Toast.fire('Forma de pagamento removida com sucesso!', '', 'success');
        })
        .catch((error) => {
          Toast.fire('Erro ao remover Forma de pagamento, por favor tente novamente!', '', 'error');
        });
    },
    async changePage({ commit }, page) {
      if (page.url) {
        const paymentMethods = await PaymentMethodsService.getPaymentMethodsByUrl(
          page.url,
          this.state.paymentMethods.orderSelected,
          this.state.paymentMethods.perPage
        );
        paymentMethods.code === 200 ? commit('FILL_PAYMENT_METHODS', paymentMethods.data) : null;
      }
    },
    async getPerPage({ commit }, payload) {
      const paymentMethods = await PaymentMethodsService.getPaymentMethodsPerPage(
        this.state.paymentMethods.paymentMethods.meta.current_page,
        this.state.paymentMethods.orderSelected,
        payload
      );
      paymentMethods.code === 200 ? commit('FILL_PAYMENT_METHODS', paymentMethods.data) : null;
      commit('SELECT_PAYMENT_METHODS_PER_PAGE', payload);
    },
    async getBanks({ commit }) {
      const response = await PaymentMethodsService.getBanks();
      response.code === 200 ? commit('FILL_BANKS', response.data) : null;
    },
    async getCoastCenter({ commit }) {
      const response = await PaymentMethodsService.getCoastCenter();
      response.code === 200 ? commit('FILL_COAST', response.data) : null;
    },
    async getAccountPlanRevenue({ commit }) {
      const response = await PaymentMethodsService.getAccountPlanRevenue();
      response.code === 200 ? commit('FILL_ACCOUNT_PLAN_REVENUE', response.data) : null;
    },
    async getAccountPlanExpense({ commit }) {
      const response = await PaymentMethodsService.getAccountPlanExpense();
      response.code === 200 ? commit('FILL_ACCOUNT_PLAN_EXPENSE', response.data) : null;
    },
    async getAccountPlanRevenueOmie({ commit }) {
      const response = await PaymentMethodsService.getAccountPlanRevenueOmie();
      response.code === 200 ? commit('FILL_ACCOUNT_PLAN_REVENUE_OMIE', response.data) : null;
    },
    async getAccountPlanExpenseOmie({ commit }) {
      const response = await PaymentMethodsService.getAccountPlanExpenseOmie();
      response.code === 200 ? commit('FILL_ACCOUNT_PLAN_EXPENSE_OMIE', response.data) : null;
    },
    async getAdminCard({ commit }) {
      const response = await PaymentMethodsService.getAdminCard();
      response.code === 200 ? commit('FILL_CARD_ADMIN', response.data) : null;
    },
    async editPaymentStatus({ commit }, { id, payload }) {
      return PaymentMethodsService.editStatus(id, payload)
        .then(() => {
          Toast.fire('Status do pagamento atualizado com sucesso!', '', 'success');
        })
        .catch((error) => {
          Toast.fire('Erro ao atualizar Status do pagamento!', '', 'error');
        });
    },
    async clearPayment({ commit }) {
      commit('payment', {});
    },
    async getPayment({ commit }, id) {
      const response = await PaymentMethodsService.getPaymentById(id);
      return response.code === 200 ? commit('FILL_PAYMENT', response.data.results) : null;
    },
    async editPayment({ commit }, { id, payload }) {
      return PaymentMethodsService.editPayment(id, payload)
        .then(() => {
          Toast.fire('Forma de pagamento atualizado com sucesso!', '', 'success');
        })
        .catch((error) => {
          Toast.fire('Erro ao atualizar Forma de pagamento!', '', 'error');
        });
    },
  },
  getters: {
    paymentMethods: (state) => state.paymentMethods,
    payment: (state) => state.payment,
    newPaymentMethod: (state) => state.newPaymentMethod,
    orderSelected: (state) => state.orderSelected,
    perPage: (state) => state.perPage,
  },
};
