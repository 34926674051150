import router from '@/router';
import EmployeesService from '@/services/employees.service';
import { mainAxios } from '@/plugins/axios';
import TablePriceService from '@/services/tablePrice.service';
import CompanyService from '@/services/company.service';

export const employees = {
  namespaced: true,
  state: {
    companies: [],
    employees: {
      links: {},
      meta: {},
      results: []
    },
    orderSelected: 'desc',
    states: {
      results: []
    },
    newEmployee: {},
    employee: {},
    profiles: {}
  },
  mutations: {
    FILL_EMPLOYEES(state, employees) {
      state.employees = employees;
    },
    FILL_COMPANIES_EMPLOYEES(state, companies) {
      state.companies = companies;
    },
    FILL_STATES(state, states) {
      state.states = states;
    },
    FILL_NEW_EMPLOYEE(state, newEmployee) {
      state.newEmployee = newEmployee;
    },
    FILL_EMPLOYEE(state, employee) {
      state.employee = employee;
    },
    FILL_PROFILES(state, profiles) {
      state.profiles = profiles;
    }
  },
  actions: {
    async getCompanies({ commit }) {
      const response = await CompanyService.listAll();
      response.code === 200
        ? commit('FILL_COMPANIES_EMPLOYEES', response.data)
        : null;
    },
    async getEmployees({ commit }, search) {
      const response = await EmployeesService.getEmployees(search);
      response.code === 200 ? commit('FILL_EMPLOYEES', response.data) : null;
    },
    async changePage({ commit }, payload) {
      if (payload.url) {
        const response = await EmployeesService.getEmployeesByUrl(
          payload.url,
          this.state.employees.orderSelected
        );
        response.code === 200 ? commit('FILL_EMPLOYEES', response.data) : null;
      }
    },
    async getPerPage({ commit }, term) {
      const response = await EmployeesService.getPerPage(
        term,
        this.state.employees.orderSelected
      );
      response.code === 200 ? commit('FILL_EMPLOYEES', response.data) : null;
    },
    async getStates({ commit }) {
      const response = await EmployeesService.getStates();
      response.code === 200 ? commit('FILL_STATES', response.data) : null;
    },
    async cepConsult({ commit }, cep) {
      const response = await EmployeesService.cepConsult(cep);
      return response.data;
    },
    async createEmployee({ commit }, payload) {
      return EmployeesService.createEmployee(payload);
    },
    async getProfiles({ commit }) {
      const response = await EmployeesService.getProfiles();
      response.code === 200 ? commit('FILL_PROFILES', response.data) : null;
    },
    async removeEmployee({ commit }, id) {
      return EmployeesService.removeEmployee(id);
    }
  },
  getters: {
    search: (state) => state.search,
    employees: (state) => state.employees,
    companies: (state) => state.companies,
    employee: (state) => state.employee,
    states: (state) => state.states
  }
};
