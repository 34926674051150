import router from '@/router';
import ProductGroupService from '@/services/productGroup.service';

export const productGroup = {
  namespaced: true,
  state: {
    productGroups: {
      links: {},
      meta: {},
      results: []
    },
    fiscalIssues: {
      links: {},
      meta: {},
      results: []
    },
    newProductGroup: null,
    orderSelected: 'asc',
    infos: {},
    fiscalRules: [],
    group: {},
    newCategory: null
  },
  mutations: {
    FILL_GROUP(state, group) {
      state.group = group;
    },
    FILL_NEW_CATEGORY(state, newCategory) {
      state.newCategory = newCategory;
    },
    FILL_NEW_PRODUCT_GROUP(state, newProductGroup) {
      state.newProductGroup = newProductGroup;
    },
    FILL_PRODUCT_GROUP(state, productGroups) {
      state.productGroups = productGroups;
    },
    FILL_INFOS(state, infos) {
      state.infos = infos;
    },
    FILL_FISCAL_ISSUER(state, fiscalIssues) {
      state.fiscalIssues = fiscalIssues;
    },
    FILL_FISCAL_RULES(state, fiscalRules) {
      state.fiscalRules = fiscalRules;
    },
    REMOVE_PRODUCT_GROUP(state, productGroup) {
      state.productGroups = productGroup;
    },
    SELECT_ORDER(state, order) {
      state.orderSelected = order;
    }
  },
  actions: {
    async changePage({ commit }, payload) {
      if (payload.url) {
        const productGroups = await ProductGroupService.getProductGroupsByUrl(
          payload.url,
          this.state.productGroup.orderSelected
        );
        productGroups.code === 200
          ? commit('FILL_PRODUCT_GROUP', productGroups.data)
          : null;
      }
    },
    async getProductGroups({ commit }) {
      const productGroups = await ProductGroupService.getProductGroups(
        this.state.productGroup.orderSelected
      );
      productGroups.code === 200
        ? commit('FILL_PRODUCT_GROUP', productGroups.data)
        : null;
    },
    async getPerPage({ commit }, term) {
      const productGroups = await ProductGroupService.getPerPage(
        term,
        this.state.productGroup.orderSelected
      );
      productGroups.code === 200
        ? commit('FILL_PRODUCT_GROUP', productGroups.data)
        : null;
    },
    async orderProductGroups({ commit, dispatch }, { type, item }) {
      const productGroups = await ProductGroupService.sortProduct(type, item);
      productGroups.code === 200
        ? commit('FILL_PRODUCT_GROUP', productGroups.data)
        : null;
      dispatch('selectOrder', type);
    },
    async selectOrder({ commit }, payload) {
      commit('SELECT_ORDER', payload);
    },
    async searchProductGroup({ commit }, search) {
      const productGroups = await ProductGroupService.searchProductGroup(
        search
      );
      productGroups.code === 200
        ? commit('FILL_PRODUCT_GROUP', productGroups.data)
        : null;
    },
    async deleteProductGroup({ commit }, id) {
      let productGroups = { ...this.state.productGroup.productGroups };
      productGroups.results = productGroups.results.filter(
        (product) => product.cod_produto_grup !== id
      );
      return await ProductGroupService.removeProductGroup(id).then(() => {
        Toast.fire('Grupo de Produto removido com sucesso!', '', 'success');
        commit('REMOVE_PRODUCT_GROUP', productGroups);
      });
    },
    async getInfosGroup({ commit }) {
      const [getCategories, getProductGroup, getProductSubGroup] =
        await Promise.all([
          ProductGroupService.getCategories(),
          ProductGroupService.getProductGroup(),
          ProductGroupService.getProductSubGroup()
        ]);
      const infosGroups = {
        categories: getCategories.data.results,
        productGroup: getProductGroup.data.results,
        productSubGroup: getProductSubGroup.data.results
      };
      commit('FILL_INFOS', infosGroups);
      return [getCategories, getProductGroup, getProductSubGroup];
    },
    async getFiscalRules({ commit }) {
      const fiscalRules = await ProductGroupService.getFiscalRules();
      fiscalRules.code === 200
        ? commit('FILL_FISCAL_RULES', fiscalRules.data)
        : null;
    },
    async submitProductGroup({ commit }, payload) {
      return ProductGroupService.createProductGroup(payload).then((result) => {
        Toast.fire('Grupo de Produto cadastrado com sucesso!', '', 'success');
        router.push('/product-group');
      });
    },
    async editProductGroup({ commit }, { id, type, payload }) {
      if (type === 'categoria') {
        return ProductGroupService.editCategory(id, payload).then((result) => {
          Toast.fire('Categoria editada com sucesso!', '', 'success');
          router.push('/product-group');
        });
      }

      if (type === 'grupo') {
        return ProductGroupService.editProductGroup(id, payload).then(
          (result) => {
            Toast.fire('Grupo de produto editado com sucesso!', '', 'success');
            router.push('/product-group');
          }
        );
      }

      return ProductGroupService.editProductSubGroup(id, payload).then(
        (result) => {
          Toast.fire('SubGrupo editado com sucesso!', '', 'success');
          router.push('/product-group');
        }
      );
    },
    async getProductById({ commit }, id) {
      const group = await ProductGroupService.getGroupById(id);
      group.code === 200 ? commit('FILL_GROUP', group.data.results) : null;
    },
    async getFiscalIssues({ commit }) {
      const response = await ProductGroupService.getFiscalIssues();
      response.code === 200
        ? commit('FILL_FISCAL_ISSUER', response.data)
        : null;
    },
    async createCategory({ commit }, payload) {
      return ProductGroupService.createCategory(payload).then((result) => {});
    },
    async createGroup({ commit }, payload) {
      return ProductGroupService.createGroup(payload).then((result) => {});
    }
  },
  getters: {
    productGroups: (state) => state.productGroups,
    group: (state) => state.group
  }
};
