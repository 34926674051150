import { accessToken, lakeAxios, mainAxios } from '@/plugins/axios';

class ScheduleService {
  async getEvents(params) {
    let query = new URLSearchParams(params).toString();
    const response = await mainAxios.get(`/eventos?${query}`);
    return response.data;
  }
  async deleteEntry(id) {
    const response = await mainAxios.delete(`/eventos/entrada/${id}`);
    return response.data;
  }
  async deleteEntryCheckin(id) {
    const response = await mainAxios.delete(`/eventos/entrada-checkin/${id}`);
    return response.data;
  }
  async getServices(type = '') {
    const response = await mainAxios.get(
      `/servicos?paginate=false&tipo=${type}`
    );
    return response.data;
  }
  async getEventById(id) {
    const response = await mainAxios.get(`/eventos/${id}`);
    return response.data;
  }
  async getSchedule(search = '') {
    const response = await mainAxios.get(
      `/agenda?paginate=false&search=${search}`
    );
    return response.data;
  }
  async createShedule(payload) {
    const response = await mainAxios.post(`/eventos`, payload);
    return response.data;
  }
  async updateShedule(id, payload) {
    const response = await mainAxios.put('/eventos/' + id, payload);
    return response.data;
  }
  async updateSheduleAll(id, payload) {
    const response = await mainAxios.put('/eventos-all/' + id, payload);
    return response.data;
  }
  async publish(cnpj) {

    const response = await lakeAxios.post(`/export-event/`, {
      export: [
        cnpj
      ]
    }, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('lake')}`,
        'x-grupo': accessToken ? accessToken['x-grupo'] : ''
      }
    });
    return response.data;
  }
}

export default new ScheduleService();
