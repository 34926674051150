import router from '@/router';
import GeneralService from '@/services/general.service';
export const newProduct = {
  namespaced: true,
  state: {
    productInformations: {},
    productParameters: {
      productGroup: [],
      productObservation: []
    },
    productPrinter: [],
    productPhasing: {
      phasing: [],
      phasingSelected: []
    },
    productUnit: [],
    productCompany: [],
    productTableValues: [],
    productTableValuesAll: [],
    catalogs: [],
    printers: [],
    subCatalogs: [],
    newProduct: null
  },
  mutations: {
    FILL_CATALOGS(state, catalogs) {
      state.catalogs = catalogs;
    },
    FILL_PRINTERS(state, printers) {
      state.printers = printers;
    },
    FILL_SUB_CATALOGS(state, subCatalogs) {
      state.subCatalogs = subCatalogs;
    },
    FILL_NEW_PRODUCT(state, newProduct) {
      state.newProduct = newProduct;
    },
    FILL_PRODUCT_TABLE_VALUE(state, productTableValues) {
      state.productTableValues = productTableValues;
    },
    FILL_PRODUCT_TABLE_VALUE_ALL(state, productTableValuesAll) {
      state.productTableValuesAll = productTableValuesAll;
    },
    FILL_PRODUCT_INFORMATION(state, productInformations) {
      state.productInformations = productInformations;
    },
    FILL_PRODUCT_PARAMETERS(state, productParameters) {
      state.productParameters = productParameters;
    },
    FILL_PRODUCT_PHASING(state, productPhasing) {
      state.productPhasing = productPhasing;
    },
    FILL_UNIT(state, productUnit) {
      state.productUnit = productUnit;
    },
    FILL_PRODUCT_COMPANY(state, productCompany) {
      state.productCompany = productCompany;
    },
    REMOVE_TABLE_FROM_TABLE_VALUES(state, table) {
      state.productTableValues = table;
    },
    ADD_TABLE_FROM_TABLE_VALUES(state, table) {
      state.productTableValues = table;
    }
  },
  actions: {
    async getCatalogs({ commit }, scope = false) {
      const catalogs = await GeneralService.getCatalog(scope);
      catalogs.code === 200
        ? commit('FILL_CATALOGS', catalogs.data.results)
        : null;
    },
    async getSubCatalogs({ commit }, payload) {
      const subCatalogs = await GeneralService.getSubCatalogs(payload);
      subCatalogs.code === 200
        ? commit('FILL_SUB_CATALOGS', subCatalogs.data.results)
        : null;
    },
    async getPrinters({ commit }, scope) {
      const printers = await GeneralService.getPrinters(scope);
      printers.code === 200
        ? commit('FILL_PRINTERS', printers.data.results)
        : null;
    },
    async getTableValue({ commit }) {
      const getTableValue = await GeneralService.getTableValue();
      getTableValue.code === 200
        ? commit('FILL_PRODUCT_TABLE_VALUE_ALL', getTableValue.data.results)
        : null;
    },
    removeTableFromProductTableValues({ commit }, payload) {
      const tableValues = [...this.state.newProduct.productTableValues];
      const updatedTableValues = tableValues.filter((tableValue) => {
        return tableValue.cod_tabela !== payload.cod_tabela;
      });
      commit('REMOVE_TABLE_FROM_TABLE_VALUES', updatedTableValues);
    },
    addTableFromProductTableValues({ commit }, payload) {
      let tableValues = [...this.state.newProduct.productTableValues];
      tableValues.push(payload);
      commit('ADD_TABLE_FROM_TABLE_VALUES', tableValues);
    },
    async compareAndRemoveTablePrices({ commit }, payload) {
      commit('FILL_PRODUCT_TABLE_VALUE', payload);
    },
    async getProduct({ commit }, payload) {
      const getProduct = await GeneralService.getProduct(payload);
      if (getProduct.code === 200) {
        commit('FILL_PRODUCT_INFORMATION', getProduct.data.results);

        const [getProductGroup, getProductObservation] = await Promise.all([
          GeneralService.getProductGroup(),
          // GeneralService.getProductObservation()
        ]);
        const productParameters = {
          ncm: getProduct.data.results.ncm,
          productGroup: getProductGroup.data.results,
          // productObservation: getProductObservation.data.results
        };
        commit('FILL_PRODUCT_PARAMETERS', productParameters);
      }
    },
    async getProductParameters({ commit }) {
      const [getProductGroup, getProductObservation] = await Promise.all([
        GeneralService.getProductGroup(),
        // GeneralService.getProductObservation()
      ]);
      const productParameters = {
        productGroup: getProductGroup.data.results,
        // productObservation: getProductObservation.data.results
      };
      commit('FILL_PRODUCT_PARAMETERS', productParameters);
    },
    async getProductCompany({ commit }) {
      const getProductCompany = await GeneralService.getProductCompany();
      getProductCompany.code === 200
        ? commit('FILL_PRODUCT_COMPANY', getProductCompany.data.results)
        : null;
    },
    async getProductPhasing({ commit }) {
      const getPhasing = await GeneralService.getPhasing();
      getPhasing.code === 200
        ? commit('FILL_PRODUCT_PHASING', getPhasing.data.results)
        : null;
    },
    async getUnit({ commit }) {
      const getUnit = await GeneralService.getUnit();
      getUnit.code === 200 ? commit('FILL_UNIT', getUnit.data.results) : null;
    },
    async submitNewProduct({ commit }, payload) {
      return GeneralService.postNewProduct(payload)
        .then((response) => {
          commit('FILL_NEW_PRODUCT', response.data);
          Toast.fire('Produto cadastrado com sucesso!', '', 'success');
          router.push('/products');
        })
        .catch((error) => {
          Toast.fire({
            title: 'Atenção',
            html: `
          Erro ao cadastrar produto! Por favor, tente novamente.<br>
          <span style="font-size: 12px">- ${error.response.data.data.message}</span>
          `,
            icon: 'error',
            width: 600,
            showCloseButton: true,
            timerProgressBar: false
          });
          throw error.response.data;
        });
    },
    async editProduct({ commit }, payload) {
      return GeneralService.putEditProduct(payload)
        .then((response) => {
          commit('FILL_NEW_PRODUCT', response.data);
          Toast.fire('Produto editado com sucesso!', '', 'success');
          router.go(-1);
        })
        .catch((error) => {
          const responseError = error.response.data.errors;
          for (const property in responseError) {
            Toast.fire(
              `Erro ao editar novo produto! ${responseError[property]}`,
              '',
              'error'
            );
          }
          throw error.response.data;
        });
    }
  },
  getters: {
    catalogs: (state) => state.catalogs,
    subCatalogs: (state) => state.subCatalogs,
    newProduct: (state) => state.newProduct,
    productParameters: (state) => state.productParameters,
    productTableValuesAll: (state) => state.productTableValuesAll,
    productTableValues: (state) => state.productTableValues,
    productInformation: (state) => state.productInformation,
    productPhasing: (state) => state.productPhasing,
    productCompany: (state) => state.productCompany
  }
};
