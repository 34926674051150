import categoryIcmsService from '@/services/categoryIcms.service';

export const categoryIcmsModule = {
  namespaced: true,
  state: {
    categoryIcms: {
      links: {},
      meta: {},
      results: []
    },
    icms: {},
  },
  mutations: {
    fill_category_icms(state, category) {
      state.categoryIcms = category;
    },
    fill_icms(state, icms) {
      state.icms = icms;
    },
  },
  actions: {
    async list({ commit }, url) {
      const response = await categoryIcmsService.list(url);
      return commit('fill_category_icms', response.data);
    },
  },
  getters: {
    categoryIcms: (state) => state.categoryIcms
  }
};
