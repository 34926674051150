import { mainAxios } from "@/plugins/axios";

class DiscountsService {
  async getDiscountsByUrl(url, order) {
    const orderField = `?order_field=cod_motivo&order=${order}&`;
    const splitString = url.split('?');
    const completeUrl = `${splitString[0]}${orderField}${splitString[1]}`;
    const response = await mainAxios.get(`${completeUrl}`);
    return response.data;
  }
  async getPerPage(perPage, order) {
    const response = await mainAxios.get(
      `/desconto-acrescimo?order_field=cod_motivo&order=${order}&per_page=${perPage}`
    );
    return response.data;
  }
  async getDiscounts(search) {
    const response = await mainAxios.get(search === undefined ? `/desconto-acrescimo` : `/desconto-acrescimo?search=${search}`);
    return response.data;
  }
  async createdDiscount(payload) {
    const dataPayload = {
      ...payload,
      flg_sem_autoriz: payload.flg_sem_autoriz === 'S' ? true : false
    };
    const response = await mainAxios.post(`/desconto-acrescimo`, dataPayload);
    return response.data;
  }
  async editDiscount(id, payload) {
    const dataPayload = {
      ...payload,
      flg_sem_autoriz: payload.flg_sem_autoriz === 'S' ? true : false
    };
    const response = await mainAxios.put(`/desconto-acrescimo/${id}`, dataPayload);
    return response.data;
  }
  async getDiscountById(id){
    const response = await mainAxios.get(`/desconto-acrescimo/${id}`);
    return response.data;
  }
  async deleteDiscount(id) {
    const response = await mainAxios.delete(`/desconto-acrescimo/${id}`);
    return response.data;
}
async editStatus(id, payload) {
  const data = {
    status_motivo: payload
  };
  const response = await mainAxios.put(`/desconto-acrescimo/${id}`, data);
  return response.data;
}
  
};

export default new DiscountsService();

