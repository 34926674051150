import EntryCategoryService from '@/services/entryCategory.service';

export const entryCategories = {
  namespaced: true, // para habilitar o nome do módulo
  state: {
    categories: {
      links: {},
      meta: {},
      results: [],
    },
    orderSelected: 'asc',
    entryCategory: {},
    newCategory: {},
  },
  mutations: {
    FILL_CATEGORIES(state, categories) {
      state.categories = categories;
    },
    FILL_NEW_CATEGORY(state, newCategory) {
      // adicionar novo
      state.newCategory = newCategory;
    },
    DELETE_CATEGORY(state, category) {
      // deletar
      state.category = category;
    },
    FILL_ENTRY_CATEGORY(state, entryCategory) {
      state.entryCategory = entryCategory;
    },
  },
  actions: {
    async getEntryCategories({ commit }, search) {
      const categories = await EntryCategoryService.getEntryCategories(search);
      categories.code === 200 ? commit('FILL_CATEGORIES', categories.data) : null;
    },
    async changeStatus({ commit }, { id, payload }) {
      return EntryCategoryService.editStatus(id, payload)
        .then(() => {
          Toast.fire('Status atualizado com sucesso!', '', 'success');
        })
        .catch((error) => {
          Toast.fire('Erro ao atualizar Status!', '', 'error');
        });
    },
    async getPerPage({ commit }, term) {
      const response = await EntryCategoryService.getPerPage(
        term,
        this.state.entryCategories.orderSelected
      );
      response.code === 200 ? commit('FILL_CATEGORIES', response.data) : null;
    },
    async changePage({ commit }, payload) {
      if (payload.url) {
        const response = await EntryCategoryService.getCategoriesByUrl(
          payload.url,
          this.state.entryCategories.orderSelected
        );
        response.code === 200 ? commit('FILL_CATEGORIES', response.data) : null;
      }
    },
    async createEntryCategory({ commit }, payload) {
      await EntryCategoryService.createEntryCategory(payload)
        .then((res) => {
          commit('FILL_NEW_CATEGORY', res.data);
          Toast.fire('Categoria de entrada cadastrada com sucesso!', '', 'success');
        })
        .catch((error) => {
          Toast.fire({
            title: 'Atenção',
            html: `
              Erro ao cadastrar categoria de entrada! Por favor, tente novamente.<br>
              <span style="font-size: 12px">- ${error.response.data.data.message}</span>
              `,
            icon: 'error',
            width: 600,
            showCloseButton: true,
            timerProgressBar: false,
          });
          throw error.response.data;
        });
    },
    async deleteCategory({ commit }, id) {
      return EntryCategoryService.deleteCategory(id)
        .then(() => {
          Toast.fire('Categoria de entrada removido com sucesso!', '', 'success');
        })
        .catch((error) => {
          Toast.fire(
            'Erro ao remover Categoria de entrada! <br> Por favor, tente novamente.',
            '',
            'error'
          );
        });
    },
    async getEntryCategory({ commit }, id) {
      const response = await EntryCategoryService.getEntryCategory(id);
      return response.code === 200 ? commit('FILL_ENTRY_CATEGORY', response.data.results) : null;
    },
    async editEntryCategory({ commit }, { id, payload }) {
      return EntryCategoryService.editEntryCategory(id, payload)
        .then(() => {
          Toast.fire('Categoria de entrada atualizada com sucesso!', '', 'success');
        })
        .catch((error) => {
          Toast.fire('Erro ao atualizar Categoria de entrada!', '', 'error');
        });
    },
  },
  getters: {
    categories: (state) => state.categories,
  },
};
