import { mainAxios } from '@/plugins/axios';

class EntryCategoryService {
  async getCategoriesByUrl(url, order) {
    const orderField = `?order_field=cod_categoria&order=${order}&`;
    const splitString = url.split('?');
    const completeUrl = `${splitString[0]}${orderField}${splitString[1]}`;
    const response = await mainAxios.get(`${completeUrl}`);
    return response.data;
  }
  async getPerPage(perPage, order) {
    const response = await mainAxios.get(
      `/categoria-entrada?order_field=cod_categoria&order=${order}&per_page=${perPage}`
    );
    return response.data;
  }
  async getEntryCategories(search) {
    const response = await mainAxios.get(
      search === undefined
        ? `/categoria-entrada`
        : `/categoria-entrada?search=${search}`
    );
    return response.data;
  }
  async createEntryCategory(payload) {
    const data = {
      ...payload,
      cod_emp: 1,
      cod_centro_custo: 0
    };
    const response = await mainAxios.post(`/categoria-entrada`, data);
    return response.data;
  }
  async editStatus(id, payload) {
    const data = {
      status_categoria: payload
    };
    const response = await mainAxios.put(`/categoria-entrada/${id}`, data); // troca de status na index
    return response.data;
  }

  async deleteCategory(id) {
    const response = await mainAxios.delete(`/categoria-entrada/${id}`);
    return response.data;
  }
  async editStatus(id, payload) {
    const data = {
      status_motivo: payload
    };
    const response = await mainAxios.put(`/categoria-entrada/${id}`, data);
    return response.data;
  }
  async getEntryCategory(id) {
    const response = await mainAxios.get(`/categoria-entrada/${id}`);
    return response.data;
  }
  async editEntryCategory(id, payload) {
    const response = await mainAxios.put(`/categoria-entrada/${id}`, payload);
    return response.data;
  }
}

export default new EntryCategoryService();
