import UserService from '@/services/user.service';

export const user = {
  namespaced: true,
  state: {
    user: {},
  },
  mutations: {
    FILL_USER(state, user) {
      state.user = user;
    },
  },
  actions: {
    async getUserData({ commit }) {
      const response = await UserService.getUser();
      response.status === 200 ? commit('FILL_USER', response.data) : null;
    },
  },
  getters: {
    user: (state) => state.user,
  },
};
