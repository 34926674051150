<template>
  <h2 :class="H2Props.class" :id="H2Props.id">
    <button :class="buttonProps.class" :type="buttonProps.type" :data-bs-toggle="buttonProps.dataBsToggle"
      :data-bs-target="buttonProps.dataBsTarget" :aria-expanded="buttonProps.ariaExpanded"
      :aria-controls="buttonProps.ariaControls" :disabled="buttonProps.disabled">
      {{ accordionName }}
      <span class="span-header d-block">{{ accordionDesc }}</span>
      <slot />
    </button>
  </h2>
</template>
<script>
export default {
  name: 'EpAccordionHeader',
  props: {
    accordionName: { required: false, type: String },
    accordionDesc: { required: false, type: String },
    buttonProps: { required: true, type: Object },
    H2Props: { required: true, type: Object },
  },
};
</script>
<style scoped lang="scss">
.accordion-item {
  border: 0 !important;
}

.accordion,
.accordion-button,
.accordion-item {
  border-radius: 0 !important;
}

.accordion-button {
  height: 80px;
  font-size: 20px;
  font-weight: 700;
  color: #2d3032;
  border-left: 8px solid #c0c5c8;
  margin-bottom: 4px;
  background-color: white;
  border-top: 1px solid #e9edef;
  border-right: 1px solid #e9edef;
  border-bottom: 1px solid #e9edef;
  box-shadow: none;
  display: inline-block;
  position: relative;

  &:disabled {
    color: #C0C5C8;
    border-left: 8px solid #e6e8e9 !important;
  }

  .span-header {
    color: #5e666a;
    margin-top: 8px;
  }

  &::after {
    content: '\F285';
    font-family: 'bootstrap-icons';
    background-image: unset;
    position: absolute;
    right: 16px;
    top: 30px;
  }

  &:not(.collapsed) {
    &::after {
      content: '\F282';
      font-family: 'bootstrap-icons';
      transform: unset;
      position: absolute;
      right: 16px;
      top: 30px;
    }
  }

  &:focus {
    box-shadow: unset;
  }

  span {
    font-weight: 400;
    font-size: 12px;
  }
}
</style>
