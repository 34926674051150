<template>
  <div>
    <div class="filters">
      <button
        :class="[
          'btn ms-lg-1 btn-primary btn-tag btn-sm btn-ellipsis',
          { 'd-none': tagSearch === '' }
        ]"
        @click="() => model.search === ''"
      >
        <!--        <em class="bi bi-x-lg"></em>-->
        {{ tagSearch }}
      </button>
      <a
        :key="i"
        v-for="(tag, i) in tagsArray"
        :class="[
          'btn btn-primary btn-tag btn-sm btn-ellipsis',
          { 'd-lg-none d-md-none d-inline': i + 1 > 2 }
        ]"
      >
        {{ tag }}
        <em class="bi bi-x-lg"></em>
      </a>
      <span class="badge badge-primary number" v-if="tagsArray.length > 2"
        >+{{ tagsArray.length - 2 }}</span
      >
      <button @click="showOffCanvas" class="btn btn-outline-dark btn-filter">
        <em class="bi bi-filter"></em> Filtros
      </button>
    </div>
    <div>
      <div
        data-bs-backdrop="static"
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasRightFilter"
        aria-labelledby="offcanvasRightFilterLabel"
      >
        <div class="offcanvas-header buttons-select">
          <button
            class="btn btn-link btn-back"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <em class="bi bi-arrow-left-short"></em> Voltar
          </button>
          <h5 class="offcanvas-title" id="offcanvasRightFilterLabel">
            Filtrar por
          </h5>
        </div>
        <div class="offcanvas-body">
          <div :key="index" v-for="(filter, index) in filters">
            <hr />

            <div v-if="filter.typeFilter === 'text'">
              <label for="search" class="form-label">{{
                filter.nameFilter
              }}</label>
              <input
                type="text"
                :name="filter.field"
                :value="tagSearch"
                @input="tagSearch = $event.target.value"
                @focusout="bindingText"
                class="form-control input-search"
                id="search"
              />
            </div>

            <div v-if="filter.typeFilter === 'tags'" class="d-flex flex-wrap">
              <div :key="indexTag" v-for="(tagName, indexTag) in filter.tags" class="mb-2 me-2">
                <input
                  type="checkbox"
                  :name="filter.tagsField[indexTag]"
                  v-model="model[filter.tagsField[indexTag]]"
                  class="btn-check"
                  autocomplete="off"
                  @change="
                    () =>
                      !model[filter.tagsField[indexTag]]
                        ? (tagsArray = tagsArray.filter((v) => v !== tagName))
                        : tagsArray.push(tagName)
                  "
                  :id="tagName"
                />
                <label
                  class="btn btn-outline-primary btn-label"
                  :for="`${tagName}`"
                  >{{ tagName }}</label
                >
              </div>
            </div>

            <div
              v-if="filter.typeFilter === 'select'"
              class="multiselect-filter"
            >
              <label class="form-label">{{ filter.nameFilter }}</label>
              <br>
              <multiselect
                v-model="selectTag[index]"
                :options="filter.options"
                :label="filter.optionFieldLabel"
                :track-by="filter.optionFieldKey"
                :loading="loadingSearch"
                :internal-search="false"
                :showNoOptions="false"
                :searchable="true"
                :optionsLimit="10"
                :showLabels="false"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :taggable="false"
                :allow-empty="true"
                selectLabel=""
                deselectLabel=""
                tag-placeholder="Adicionar ao filtro."
                placeholder="Selecione um item"
                @search-change="search($event, filter.searchCall)"
                @remove="
                  (value) => {
                    tagging(value, filter.optionFieldLabel);
                    binding(value, index, filter.optionFieldKey, filter.field);
                  }
                "
                @select="
                  (value) => {
                    tagging(value, filter.optionFieldLabel);
                    binding(value, index, filter.optionFieldKey, filter.field);
                  }
                "
              >
                <span slot="noResult">Ops! Nenhum resultado encontrado.</span>
              </multiselect>
            </div>
          </div>
          <div class="offcanvas-bottom">
            <div class="btn-saved mt-4">
              <div class="float-end mt-4 d-flex">
                <a
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  @click="reset"
                  class="btn btn-cancel"
                >
                  <em class="bi bi-x-lg"></em>
                  Limpar
                </a>
                <a
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  class="btn btn-primary btn-save next fw-bold"
                  @click="save"
                >
                  <span class="ml-2">Filtrar</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
  name: 'EpFilter',
  components: { Multiselect },
  props: {
    filter: {},
    filters: {
      Array,
      length: 0,
      Object: {
        tags: Array,
        tagsField: Array,
        typeFilter: String,
        nameFilter: String,
        field: String,
        fieldLabel: String,
        optionFieldKey: String,
        optionFieldLabel: String,
        options: [],
        searchCall: (value) => {}
      }
    }
  },
  data() {
    return {
      loadingSearch: false,
      tagSearch: '',
      tagsArray: [],
      selectTag: []
    };
  },
  computed: {
    model: {
      get() {
        return this.filter;
      },
      set(value) {
        this.$emit('update:filter', value);
      }
    }
  },
  methods: {
    search(value, call) {
      call(value);
    },
    binding(value, index, fieldKey, filter) {
      this.model[filter] = this.selectTag[index].map(
        (selecting) => selecting[fieldKey]
      );
    },
    bindingText(event) {
      this.model.search = event.target.value;
    },
    tagging(value, field) {
      let remove = this.tagsArray.find((element) => element === value[field]);
      if (remove) {
        this.tagsArray = this.tagsArray.filter((val) => val !== remove);
        return;
      }
      this.tagsArray.push(value[field]);
    },
    save() {
      this.$emit('update:filter', this.model);
    },
    reset() {
      this.tagsArray = [];
      this.tagSearch = '';
      this.selectTag = [];
      Object.keys(this.filter).map((key) => {
        let isClean = this.filters.filter((value) =>  {
          let isTag = false;
          if (value.tagsField) {
            isTag = !!value.tagsField.filter((v) => v === key).length;
          }

          if (
            isTag ||
            value.field === key
          ) {
            return value;
          }
        })

        if (!!isClean.length) {
          this.model[key] = '';
        }
      });
    },
    showOffCanvas() {
      bootstrap.Offcanvas.getOrCreateInstance(
        document.getElementById('offcanvasRightFilter')
      ).show();
    }
  }
};
</script>
<style lang="scss">
.multiselect__tag{
  background:#872240!important;
}
.multiselect__tag-icon:after{
  color:#fff!important;
}
.multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
  background: #872240!important;
}
</style>
<style lang="scss" scoped>
::v-deep {
  .badge {
    margin: 4px;

    &.number {
      background: $primary-color;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-family: $font-secondary;
      font-size: 12px;
      font-weight: 700;
    }
  }
}

.btn-filter {
  width: 160px;
  height: 42px;
  color: #373c3f;
  border-radius: 8px;
  border: 1px solid #dcdde4;
  text-align: center;
  font-family: $font-secondary;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  padding-left: 30px;
  &:hover {
    background: #fff;
  }
  em {
    font-size: 24px;
    position: absolute;
    left: 10px;
    top: 4px;
  }
}
.btn-save {
  width: 160px;
  position: relative;
  font-size: 16px;

  &.next {
    width: 220px;
    margin-right: 16px;
  }

  span {
    display: inline-block;
  }

  em {
    position: absolute;
    right: 16px;
    top: 7px;
    font-size: large;
  }
}
.btn-cancel {
  font-weight: 700;
  border: 1px solid #4b5053;
  border-radius: 8px;
  padding: 8px 16px;
  margin-right: 16px;
  font-size: 16px;
}

.offcanvas {
  width: 600px;
  padding: 50px;
  font-family: $font-secondary;
  .offcanvas-body,
  .offcanvas-header {
    padding: 0;
  }

  .offcanvas-title {
    font-family: $font-secondary;
    font-size: 24px;
    font-weight: 600;
  }

  .multiselect-filter {
    .multiselect {
      margin-right: 0;

      .multiselect:not(.multiselect--active) {
        width: 100% !important;
      }
      .multiselect__select {
        height: 50px;
      }
      .multiselect__tags {
        width: 100% !important;
        height: 56px;
        border-radius: 8px;
        border: 1px solid rgba(226, 230, 232, 0.4) !important;
        background: rgba(226, 230, 232, 0.4) !important;
      }
      .multiselect__input,
      .multiselect__single {
        background: #f4f5f6;
        width: 95% !important;
        height: 40px;
        line-height: 40px;
        font-family: $font-secondary !important;
      }

      .multiselect__option--selected.multiselect__option--highlight,
      .multiselect__option--highlight {
        background: #fafafa !important;
        color: #373c3f !important;
      }
      .multiselect__spinner {
        right: 30px !important;
        top: 10px !important;
        z-index: 1;
        background: transparent !important;
      }

      .multiselect__placeholder {
        line-height: 35px;
        font-family: $font-secondary;
        color: #373c3f !important;
        font-size: 16px;
        font-weight: 500;
      }
      .multiselect__option {
        span {
          font-weight: 500;
          font-family: $font-primary;
        }
      }
    }
  }
}
.btn-ellipsis {
  border-radius: 8px;
  margin-right: 8px;
}
.btn-label {
  border-radius: 56px;
  font-size: 12px;
  border-color: #dcdde4;
  color: #181a1b;
  padding: 8px 16px;
  &:hover {
    border-color: #dcdde4;
  }
}
.tags {
  overflow: hidden;
  gap: 10px;
}
.multiselect__tag {
  background: #872240 !important;
}
hr {
  margin: 24px 0;
  color: #dcdde4;
}
</style>
