<template>
  <div
    :class="{ 'vh-100': $route.name === 'import-products-item' }"
    :style="[
      { 'max-height': $route.name === 'import-products-item' ? '15%' : '' },
      {
        'margin-bottom':
          $route.name === 'import-products-item' ? '-10px!important' : ''
      }
    ]"
  >
    <form action="" autocomplete="off">
    <div class="d-flex">
        <h1 class="title">{{ title }}</h1>
        <div class="multiselect-sales mt-auto mx-2">
          <multiselect
            autocomplete="off"
            :name="'filterHeader'"
            :input="filter"
            :options="filter"
            :label="label"
            :track-by="trackBy"
            v-model="model"
            :internal-search="true"
            :searchable="true"
            :preserveSearch="false"
            :clearOnSelect="false"
            :showLabels="false"
            :showNoOptions="false"

            placeholder="Selecione a empresa"
          >
          </multiselect>
        </div>
    </div>
    </form>

    <nav
      class="breadcrumb-nav"
      aria-label="breadcrumb"
      style="--bs-breadcrumb-divider: '>'"
    >
      <ol class="breadcrumb">
        <li v-for="(item, i) in items" class="breadcrumb-item" :key="i">
          <router-link :to="item.path">{{ item.name }}</router-link>
        </li>
      </ol>
    </nav>
  </div>
</template>
<script lang="ts">
import { Multiselect } from 'vue-multiselect';

export default {
  name: 'EpSubHeaderFilter',
  components: { Multiselect },
  props: {
    title: { required: true, type: String },
    label: { required: true, type: String },
    trackBy: { required: true, type: String },
    filter: { required: true, type: Array },
    filterHeaderModelSelected: null,
    items: { type: Array }
  },
  computed: {
    model: {
      get() {
        return this.filterHeaderModelSelected;
      },
      set(value) {
        this.$emit('update:filterHeaderModelSelected', value);
      }
    }
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  .multiselect-sales {
    width: 500px!important;
    .multiselect {
      position: initial;
      height: 40px;
    }
    .multiselect__content-wrapper {
      width: 500px!important;
    }
    .multiselect__element {
      width: 500px!important;
    }
    .multiselect__option {
      width: 500px!important;
    }
    .multiselect__select {
      height: 40px;
    }
    .multiselect__tags {
      height: 40px;
      padding: 0!important;
      background: #f4f5f6 !important;
    }
    .multiselect:not(.multiselect--active) {
      width: auto !important;
    }
    .multiselect__input {
      width: 500px!important;
      max-width: 350px!important;
      padding-left: 5px !important;
      height: 40px;
      line-height: 40px;
      border-radius: 0;
      tab-index: -1 !important;
      background: #f4f5f6;
    }
    .multiselect__single:empty {
      background: transparent !important;
    }
    .multiselect__single {
      height: 40px;
      z-index: 999 !important;
      line-height: 40px;
      font-family: $font-secondary !important;
      background: #f4f5f6;
    }
  }
}


h1 {
  color: #272a2c;
  font-family: $font-secondary;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}

.breadcrumb {
  margin-top: 8px;

  .breadcrumb-item {
    a {
      font-size: 14px;
      color: #5e666a;
    }
  }
}

.breadcrumb-nav {
  margin-bottom: 15px;
}
</style>
