import { mainAxios } from '@/plugins/axios';

class ClientsService {
  async blackList(url) {
    const response = await mainAxios.get(url);
    return response.data;
  }
  async reassonBlackView(id) {
    const response = await mainAxios.get('/motivo-bloqueio/' + id);
    return response.data;
  }
  async reassonBlackUpdate(id, payload) {
    const response = await mainAxios.put('/motivo-bloqueio/' + id, payload);
    return response.data;
  }
  async reassonBlackCreate(payload) {
    const response = await mainAxios.post('/motivo-bloqueio', payload);
    return response.data;
  }
  async clientBlackview(id) {
    const response = await mainAxios.get('/clientes-bloqueados/' + id);
    return response.data;
  }
  async list(url) {
    const response = await mainAxios.get(url);
    return response.data;
  }
  async delete(id) {
    const response = await mainAxios.delete(`/cliente/${id}`);
    return response.data;
  }
  async create(payload) {
    let data = {
      ...payload,
      cpf_cnpj_cli: payload.cpf_cnpj_cli ? payload.cpf_cnpj_cli.match(/\d/g).join('') : null,
      tel_cli: payload.tel_cli ? payload.tel_cli.match(/\d/g).join('') : null,
    }
    const response = await mainAxios.post(`/cliente`, data);
    return response.data;
  }
  async createClientBlocked(payload) {
    const response = await mainAxios.post(`/clientes-bloqueados`, payload);
    return response.data;
  }
  async updateClientBlocked(id, payload) {
    const response = await mainAxios.put(`/clientes-bloqueados/` + id, payload);
    return response.data;
  }
  async update(id, payload) {
    let data = {
      ...payload,
      cpf_cnpj_cli: payload.cpf_cnpj_cli ? payload.cpf_cnpj_cli.match(/\d/g).join('') : null,
      tel_cli: payload.tel_cli ? payload.tel_cli.match(/\d/g).join('') : null,
    }
    const response = await mainAxios.put(`/cliente/${id}`, data);
    return response.data;
  }
  async view(id) {
    const response = await mainAxios.get(`/cliente/${id}`);
    return response.data;
  }
}

export default new ClientsService();
