import ServicesService from '@/services/services.service';

export const services = {
  namespaced: true,
  state: {
    services: {
      links: {},
      meta: {},
      results: []
    },
    queryString: '',
    url: undefined
  },
  mutations: {
    FILL_SERVICES(state, services) {
      state.services = services;
    }
  },
  actions: {
    async list({ commit }) {
      let url =
        this.state.url === undefined
          ? '/servicos?page=1'
          : this.state.url;
      const response = await ServicesService.list(url + '&' + this.state.queryString);
      return response.code === 200 ? commit('FILL_SERVICES', response.data) : null;
    }
  },
  getters: {
    services: (state) => state.services
  }
};
