<template>
  <div class="multi-select" @mouseleave="isShowedSelectableOptions = false">
    <div class="selected-options" @click="showSelectableOptions($event)">
      <p class="fake-placeholder" v-if="selectedOptions.length === 0">{{ placeholderText }}</p>
      <ul class="list-selected-options">
        <li v-for="(selectedOption, i) in selectedOptions" :key="`${selectedOption[id]}${i}`">
          <p class="selected-option" :style="`background-color:${selectedOption[color]}`">
            {{ selectedOption[label] }}
            <span class="remove-option" @click="removeSelectedOption(selectedOption)"><em class="bi bi-x"></em></span>
          </p>
        </li>
      </ul>
      <img src="../../assets/img/Vector.svg" class="fake-select" alt="" :class="isShowedSelectableOptions ? 'active' : ''"
        @click="isShowedSelectableOptions = !isShowedSelectableOptions" />
    </div>
    <div class="selectable-options" v-if="isShowedSelectableOptions">
      <ul class="w-100 overflow-auto" style="max-height: 500px!important;">
        <li v-for="(selectableOption, i) in selectableOptions" :key="`${selectableOption[id]}${i}`"
          class="selectable-option" @click="addSelectedOption(selectableOption)">
          {{ selectableOption[label] }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EpMultiSelect',
  data() {
    return {
      selectedOptions: [],
      isShowedSelectableOptions: false,
    };
  },
  props: {
    isSubmitted: { type: Boolean, required: true },
    selectableOptions: { default: () => [], required: true, type: Array },
    id: { type: String || Number, required: true },
    color: { type: String || Number, required: false },
    label: { type: String || Number, required: true },
    placeholderText: { type: String || Number, required: false },
    textError: { type: String, required: false },
    nameEmit: { type: String, required: true },
  },
  watch: {
    isSubmitted() {
      this.isShowedSelectableOptions = false;
      this.selectedOptions = [];
    },
  },
  methods: {
    emitOptions() {
      this.$emit(this.nameEmit, this.selectedOptions);
    },
    addSelectedOption(option) {
      let isValid = true;
      if (this.selectedOptions.length) {
        this.selectedOptions.map((selectedOptions) => {
          if (selectedOptions === option) {
            isValid = false;
          }
        });
      }
      if (isValid) {
        this.selectedOptions.push(option);
      }
      this.emitOptions();
    },
    removeSelectedOption(option) {
      this.selectedOptions = this.selectedOptions.filter(
        (selectedOptions) => selectedOptions !== option
      );
      this.emitOptions();
    },
    showSelectableOptions(event) {
      if (
        event.target.className == 'selected-options' ||
        event.target.className == 'fake-placeholder'
      ) {
        this.isShowedSelectableOptions = !this.isShowedSelectableOptions;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.multi-select {
  width: 100%;
  position: relative;
}

.fake-placeholder {
  margin-bottom: 0;
  margin-left: 8px;
}

.fake-select {
  cursor: pointer;
  padding: 1.5rem 1.25rem 1.2rem 1.25rem;
  transform: rotate(270deg);
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.fake-select.active {
  padding: 1.7rem 1.1rem 1.2rem 1.1rem;
  transform: rotate(0);
}

.selected-options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 56px;
  height: auto;
  flex-wrap: nowrap;
  background: #e2e6e866;
  border: 1px solid #e2e6e866;
  padding: 8px, 10px, 8px, 10px;
  border-radius: 8px;
}

.list-selected-options {
  gap: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 56px;
  height: auto;
  flex-wrap: wrap;
  padding: 8px;
}

.selectable-options {
  position: absolute;
  background: #fff;
  z-index: 100;
  width: 100%;
  display: flex;
  border-radius: 0 0 8px 8px;
  border: 1px solid #c0c5c8;
  padding: 1rem 0.5rem 1rem 0.5rem;
}

.selectable-option {
  width: 100%;
  padding: 4px;
}

.selectable-option:hover {
  border-radius: 4px;
  cursor: pointer;
  background: #e2e6e866;
}

.remove-option {
  cursor: pointer;
}

.selected-option {
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 0;
}
</style>
