<template>
  <button type="button" :class="['btn', `btn-${variant}`]" v-bind="$attrs">
    <em v-if="icon" :class="icon"></em>
    {{ label }}
  </button>
</template>

<script>
export default {
  name: 'EpButton',
  props: {
    label: {
      type: String,
      default: 'Button',
    },
    variant: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: 'bi ',
    },
  },
};
</script>

<style lang="scss"></style>
