<template>
  <h2 :class="H2Props.class" :id="H2Props.id">
    <button
      :class="buttonProps.class"
      :type="buttonProps.type"
      :data-bs-toggle="buttonProps.dataBsToggle"
      :data-bs-target="buttonProps.dataBsTarget"
      :aria-expanded="buttonProps.ariaExpanded"
      :aria-controls="buttonProps.ariaControls"
    >
      <p class="mb-4">
        <small v-if="accordionDesc">{{ accordionDesc }}</small>
        <br v-if="accordionDesc" />
        {{ accordionName }}
      </p>
      <div class="status w-100">
        <p v-if="!status" class="pendente">
          <span class="label-status">
            <em class="bi bi-exclamation-lg"></em
          ></span>
          &nbsp;Pendente
        </p>
        <p v-if="status" class="pendente">
          <span class="label-status">
            <em class="bi bi-check icon-status"></em>
          </span>
          &nbsp;Configurado
        </p>
        <p class="user">
          Usuário:
          <span class="label-user"
            ><em class="bi bi-person-fill"></em> {{ user || 'Nenhum' }}</span
          >
        </p>
        <p class="last-edition">
          Última edição
          <span class="label-edition">{{ updatedAt || '0d' }}</span>
        </p>
      </div>
      <slot />
    </button>
  </h2>
</template>
<script>
export default {
  name: 'EpAccordionHeaderStatus',
  props: {
    updatedAt: { required: false, type: String },
    status: { required: false, type: Boolean },
    user: { required: false, type: String },
    accordionName: { required: false, type: String },
    accordionDesc: { required: false, type: String },
    buttonProps: { required: true, type: Object },
    H2Props: { required: true, type: Object },
  },
};
</script>
<style scoped lang="scss">
.status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .pendente {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    em {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }
  .label-status {
    margin: auto;
    display: inline-flex;
    background: #e9edef;
    border-radius: 16px;
    em {
      width: 24px;
      height: 24px;
      font-size: 20px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      justify-content: center;
      overflow: hidden;
    }
  }
  .user {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }
  .label-user {
    margin-left: 10px;
    background: #e9edef;
    border-radius: 16px;
    padding: 2px 8px 2px 2px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    em {
      width: 24px;
      height: 24px;
      background: #373c3f;
      color: #fff;
      font-size: 28px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      justify-content: center;
      overflow: hidden;
      margin-right: 6px;
    }
  }
  .last-edition {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0;
    .label-edition {
      min-width: 30px;
      padding: 2px 2px 2px 2px;
      height: 30px;
      background: #e9edef;
      border-radius: 16px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.accordion-button {
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  color: #2d3032;
  margin-bottom: 4px;
  background-color: #fff;
  border: 1px solid #e9edef;
  border-left: 8px solid #c0c5c8;
  border-radius: 0;
  font-family: $font-secondary;
  p {
    small {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 5px;
    }
  }
  &::after {
    position: absolute;
    top: 25px;
    right: 25px;
    content: '\F285';
    font-family: 'bootstrap-icons';
    background-image: unset;
  }

  &:not(.collapsed) {
    &::after {
      position: absolute;
      top: 25px;
      right: 25px;
      content: '\F282';
      font-family: 'bootstrap-icons';
      transform: unset;
    }
  }

  &:focus {
    box-shadow: unset;
  }
}
.accordion-item .accordion-button {
  border-radius: 0 !important;
}
.accordion-item {
  background: #fff !important;
  border: 0;
  margin-bottom: 8px;
  border-radius: 0;
}
.accordion-body {
  border: 1px solid #e9edef;
  text-align: left;
  padding: 24px !important;
}
.accordion-item {
  background: #fff !important;
  border: 0;
  margin-bottom: 8px;
  border-radius: 0;
}
.accordion-body {
  border: 1px solid #e9edef;
  text-align: left;
  padding: 24px !important;
}
.accordion-item {
  border: 0 !important;
}
.icon-status {
  background: #872240;
  color: #fff;
}
</style>
