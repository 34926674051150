import router from '@/router';
import ObservationProductService from '@/services/observationProduct.service';

export const productObservation = {
  namespaced: true,
  state: {
    observations: {
      links: {},
      meta: {},
      results: []
    },
    queryString: undefined,
    url: undefined,
    newObservation: null,
    editedObservation: {},
    itemsList: [],
    products: [],
    tablePrices: [],
    orderSelected: 'asc',
    observationsPrice: []
  },
  mutations: {
    FILL_OBSERVATION(state, observations) {
      state.observations = observations;
    },
    FILL_NEW_OBSERVATION(state, newObservation) {
      state.newObservation = newObservation;
    },
    FILL_ITEMS_LIST(state, items) {
      state.itemsList = items;
    },
    FILL_TABLE_PRICE(state, tablePrices) {
      state.tablePrices = tablePrices;
    },
    FILL_PRODUCTS(state, products) {
      state.products = products;
    },
    REMOVE_OBSERVATION(state, observation) {
      state.observations = observation;
    },
    EDIT_OBSERVATION(state, observation) {
      state.editedObservation = observation;
    },
    SELECT_ORDER(state, order) {
      state.orderSelected = order;
    },
    FILL_OBSERVATIONS_PRICE(state, observationsPrice) {
      state.observationsPrice = observationsPrice;
    }
  },
  actions: {
    async changePage({ commit }, payload) {
      if (payload.url) {
        const productsObs = await ObservationProductService.getProductsByUrl(
          payload.url,
          this.state.productObservation.orderSelected
        );
        productsObs.code === 200
          ? commit('FILL_OBSERVATION', productsObs.data)
          : null;
      }
    },
    async getPerPage({ commit }, term) {
      const productsObs = await ObservationProductService.getPerPage(term, this.state.productObservation.orderSelected);
      productsObs.code === 200
        ? commit('FILL_OBSERVATION', productsObs.data)
        : null;
    },
    async getObservations({ commit }, search) {
      let url =
        this.state.url === undefined
          ? '/observacao?page=1'
          : this.state.url;

      let query = this.state.queryString || 'order=asc&order_field=desc_obs_grupo';
      const response = await ObservationProductService
        .getObservationProduct(
          url + "&" + query
        );

      response.code === 200 ? commit('FILL_OBSERVATION', response.data) : null;
    },
    async newObservation({ commit }, payload) {
      await ObservationProductService.newObservationProduct(payload)
        .then((response) => {
          commit('FILL_NEW_OBSERVATION', response.data);
          Toast.fire(
            'Observação do produto cadastrada com sucesso!',
            '',
            'success'
          );
          router.push('/product-observation');
        })
        .catch((error) => {
          Toast.fire({
            title: 'Atenção',
            html: `
              Erro ao cadastrar observação do produto! Por favor, tente novamente.<br>
              <span style="font-size: 12px">- ${error.response.data.data.message}</span>
              `,
            icon: 'error',
            width: 600,
            showCloseButton: true,
            timerProgressBar: false
          });
          throw error.response.data;
        });
    },
    async submitEditObservation({ commit }, payload) {
      await ObservationProductService.editObservationProduct(payload)
        .then((response) => {
          commit('FILL_NEW_OBSERVATION', response.data);
          Toast.fire(
            'Observação do produto editada com sucesso!',
            '',
            'success'
          );
          router.push('/product-observation');
        })
        .catch((error) => {
          Toast.fire({
            title: 'Atenção',
            html: `
              Erro ao editar observação do produto! Por favor, tente novamente.<br>
              <span style="font-size: 12px">${error.response.data.data.message}</span>
              `,
            icon: 'error',
            width: 600,
            showCloseButton: true,
            timerProgressBar: false
          });
          throw error.response.data;
        });
    },
    async deleteObservation({ commit }, paylaod) {
      let observations = { ...this.state.productObservation.observations };
      observations.results = observations.results.filter(
        (observation) => observation.cod_obs_grupo !== paylaod.cod_obs_grupo
      );
      const deleteObs = await ObservationProductService.deleteObservation(
        paylaod.cod_obs_grupo
      );
      deleteObs.code === 200
        ? commit('REMOVE_OBSERVATION', observations)
        : null;
    },
    async editObservation({ commit }, observationId) {
      const editObservation =
        await ObservationProductService.getObservationById(observationId);
      editObservation.code === 200
        ? commit('EDIT_OBSERVATION', editObservation.data.results)
        : null;
    },
    async getProducts({ commit }, param) {
      const products = await ObservationProductService.getProducts(param);
      commit('FILL_PRODUCTS', products.data.results);
    },
    async getTablePrices({ commit }) {
      const tablePrices = await ObservationProductService.getTablePrice();
      commit('FILL_TABLE_PRICE', tablePrices.data.results);
    },
    async removeProduct({ commit }, payload) {
      return ObservationProductService.removeProduct(payload);
    },
    async forceRemoveProduct({ commit }, payload) {
      return ObservationProductService.forceRemoveProduct(payload);
    },
    async restoreProduct({ commit }, payload) {
      return ObservationProductService.restoreProduct(payload);
    },
    async removePriceFromProduct({ commit }, payload) {
      return ObservationProductService.removePriceFromProduct(payload);
    },
    async getObservationProductPrice({ commit }, id) {
      const response =
        await ObservationProductService.getObservationProductPrice(id);
      response !== undefined
        ? commit('FILL_OBSERVATIONS_PRICE', response.data)
        : null;
    }
  },
  getters: {
    newObservation: (state) => state.newObservation,
    products: (state) => state.products,
    observationsPrice: (state) => state.observationsPrice
  }
};
