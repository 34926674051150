<template>
  <section
    id="app"
    :class="['section', {
      'vh-100':
        $route.name === 'manage-price-table' ||
        $route.name === 'import-products-item' ||
        $route.name === 'chat' ||
        $route.name === 'calendar'
      }
    ]"
  >
    <div :class="['d-flex', {
      'overflow-hidden': $route.name !== 'calendar'
    }]">
      <MenuSidebar v-if="!$route.meta.hideNavbar" />
      <router-view />
    </div>
    <div class="col-12 align-content-center position-absolute bottom-0" v-if="!$route.meta.hideNavbar && $route.path === '/home'">
      <div class="col-4 mx-lg-auto mb-3">
        <div class="card rounded-2" style="height: 60px; min-width: 390px; max-width: 500px;">
          <div class="row d-flex my-auto">
            <div class="col mx-2 my-auto">
              Fale com o Fino
            </div>
            <a
              class="btn btn-primary open-chat mx-3"
              type="button"
              href="/chat"
            >
              Iniciar conversa <em class="bi bi-chat"></em>
            </a>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import MenuSidebar from '@/components/layout/MenuSidebar.vue';

export default {
  components: { MenuSidebar },
  data() {
    return {
      user: undefined,
      messages: [],
      loading: false,
      listMessage: [],
      table: false
    };
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {}
};

const bc = new BroadcastChannel('sessionShared');
bc.onmessage = (e) => {
  let loggedLocal = JSON.parse(localStorage.getItem('logged'));
  let loggedSession = JSON.parse(sessionStorage.getItem('logged'));
  if (loggedLocal['x-grupo'] !== loggedSession['x-grupo']) {
    sessionStorage.setItem('logged', JSON.stringify(loggedLocal));
    sessionStorage.setItem('userData', localStorage.getItem('userData'));
    sessionStorage.setItem('totalData', localStorage.getItem('totalData'));
    window.location.reload();
  }
}
</script>

<style lang="scss">
#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  height: 100vh;
}

.offcanvas-body {
  padding-bottom: 70px !important;
  width: 100%;
}

.open-chat-card {
  font-weight: 700;
  font-family: $font-secondary;
  font-size: 14px;
}

.open-chat {
  margin: 0 auto;
  width: 220px;
  position: inherit;
  font-weight: 600;
  font-family: $font-secondary;
  font-size: 14px;

  em {
    position: inherit;
    right: 10px;
    top: 5px;
    font-size: 16px;
  }
}

.messages {
  flex-grow: 1;
  overflow: auto;
  padding: 1rem;
}

.head {
  width: 100%;
  height: 65px;
  background: $primary-color;
  display: flex;
  align-items: center;
  padding: 16px;

  p {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    color: #fff;
    font-family: $font-secondary;
  }
}

.message + .message {
  margin-top: 1rem;
}

.message {
  .right {
    margin-left: auto;
  }
}

.offcanvas-chat {
  width: 580px !important;
  height: 90%;
  bottom: 0;
  top: unset;
}
</style>
