import { chatAxios, chatTokenAxios } from '@/plugins/axios';

class ChatService {
  async messages(payload) {

    const dataPayload = {
      pergunta: payload,
      session_hash: sessionStorage.getItem('session_hash_token')
    };
    return await chatAxios.post(`/main`, dataPayload);
  }

  async token() {
    const data = JSON.parse(sessionStorage.getItem('userData'));
    const grupo = String(data.grupo.codigo_grupo).replaceAll('-', '_');

    const dataPayload = {
      dashboard_id: "99",
      session_hash: String(data.hash_session_usu),
      cod_grupo_emp: String(data.cod_grupo),
      instancia: String(data.grupo.nome_instancia),
      database: `multi_${grupo}`
    };

    const response = await chatTokenAxios.post(`/epoc-erp-token`, dataPayload);
    sessionStorage.setItem('session_hash_token', response.data.data);
    return true;
  }
}

export default new ChatService();
