import router from '@/router';
import { mainAxios } from '@/plugins/axios';

class ObservationProductService {
  async getProductsByUrl(url, order) {
    const orderField = `?order_field=cod_obs_grupo&order=${order}&`;
    const splitString = url.split('?');
    const completeUrl = `${splitString[0]}${orderField}${splitString[1]}`;
    const response = await mainAxios.get(`${completeUrl}`);
    return response.data;
  }
  async getPerPage(perPage, order) {
    const response = await mainAxios.get(
      `/observacao?order_field=cod_obs_grupo&order=${order}&per_page=${perPage}`
    );
    return response.data;
  }
  async getObservationProduct(url) {
    const response = await mainAxios.get(url);
    return response.data;
  }
  async newObservationProduct(payload) {
    const response = await mainAxios.post(`/observacao`, payload);
    return response.data;
  }
  async editObservationProduct(payload) {
    const response = await mainAxios.put(
      `/observacao/${router.history.current.params.idProductObservation}`,
      payload
    );
    return response.data;
  }
  async removeObservation(payload) {
    const response = await mainAxios.delete(
      `/observacao-produto/${payload.cod_observacao}`
    );
    return response.data;
  }
  async getObservationById(id) {
    const response = await mainAxios.get(`/observacao/${id}`);
    return response.data;
  }
  async deleteObservation(id) {
    const response = await mainAxios.delete(`/observacao/${id}`);
    return response.data;
  }
  async restoreObservation(id) {
    const response = await mainAxios.put(`/observacao/${id}/restore`);
    return response.data;
  }
  async getProducts(param) {
    const response = await mainAxios.get(
      `/produto/gateway/sem-relations?fields=cod_prod,desc_prod&search=${param}`
    );
    return response.data;
  }
  async getTablePrice() {
    const response = await mainAxios.get(`/tabela-preco`);
    return response.data;
  }
  async removeProduct(cod_observacao) {
    const response = await mainAxios.delete(
      `/observacao-produto/${cod_observacao}`
    );
    return response.data;
  }
  async forceRemoveProduct(cod_observacao) {
    const response = await mainAxios.delete(
      `/observacao-produto/${cod_observacao}/force-delete`
    );
    return response.data;
  }
  async restoreProduct(cod_observacao) {
    const response = await mainAxios.put(
      `/observacao-produto/${cod_observacao}/restore`
    );
    return response.data;
  }
  async removePriceFromProduct(cod_preco_produto) {
    const response = await mainAxios.delete(
      `tabela-preco-produto/${cod_preco_produto}/force-delete`
    );
    return response.data;
  }
  async getObservationProductPrice(id) {
    const response = await mainAxios.get(
      `/gerenciar-empresa-observacao/${id}?paginate=false`
    );
    return response.data;
  }
}

export default new ObservationProductService();
