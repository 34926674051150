<template>
  <div
    id="table-stick"
    style="max-height: 90% !important"
    class="vh-100 table-responsive position-sticky"
  >
    <div
      class="box-loading vh-100 align-middle justify-content-center text-center"
      v-if="loading"
    >
      <div class="spinner-border text-center" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <table
      :class="[
        'table',
        {
          'caption-top': paginateScroll
        }
      ]"
    >
      <thead class="sticky-top top-0 border">
        <tr>
          <th
            scope="col"
            v-for="(column, i) in columns"
            :class="[
              column.classThead,
              {
                'table-danger border border-danger':
                  columnIsInvalid[i] && !columnIsInvalidCss[i]
              },
              columnIsInvalidCss[i]
            ]"
            :key="column.field"
            :style="column.initialTheadStyle"
          >
            {{ column.title }}
          </th>
        </tr>
      </thead>
      <tbody v-if="!!rows.length && !loading">
        <tr v-for="(row, k) in rows" :key="k">
          <td
            v-for="(v, i) in row"
            :key="`${k}:${i}`"
            :class="[
              'p-0 m-0 position-relative',
              v.classRow,
              {
                'table-active': inputBind === `${v.rowId}:${v.columnId}`,
                'align-middle':
                  inputBind !== `${v.rowId}:${v.columnId}` ||
                  columns[v.columnId].inputType === 'checkbox'
              },
              {
                'table-danger border-danger':
                  !v.isValid && !columnIsInvalidCss[v.columnId]
              },
              columnIsInvalidCss[v.columnId] && !v.isValid
                ? columnIsInvalidCss[v.columnId]
                : ''
            ]"
            @keydown.right.prevent="nextCell(v)"
            @keydown.left.prevent="prevCell(v)"
            @keydown.down.prevent="nextRow(v, v.columnId)"
            @keydown.up.prevent="prevRow(v, v.columnId)"
          >
            <input
              v-if="inputBind === `${v.rowId}:${v.columnId}`"
              @keydown.enter.prevent="
                () => {
                  mode = 'writing';
                  changeMode();
                }
              "
              ref="spell"
              class="form-control input-table"
              style="
                padding: 0 !important;
                margin: 0 !important;
                width: 0 !important;
                height: 0 !important;
              "
            />
            <div
              v-if="
                columns[v.columnId].inputType !== 'checkbox' &&
                inputBind !== `${v.rowId}:${v.columnId}`
              "
              @click="
                [
                  (inputBind = `${v.rowId}:${v.columnId}`),
                  (model = v),
                  mode === 'readonly' ? (mode = 'writing') : (mode = 'readonly')
                ]
              "
              :class="['px-2 py-3 cell-label', columns[v.columnId].inputClass]"
              :style="['display: block;', columns[v.columnId].initialStyle]"
            >
              <span v-if="columns[v.columnId].inputType === 'select' && !columns[v.columnId].multiple">
                {{ v.multiSelectModel[columns[v.columnId].selectOptionsLabel] }}
              </span>
              <span class="multiselect__tags-wrap p-0 m-0" v-else-if="columns[v.columnId].inputType === 'select' && columns[v.columnId].multiple">
                <span  v-if="v.multiSelectModel.length">
                  <span  class="px-1" :key="k" v-for="(value, k) in v.multiSelectModel">
                    <span class="multiselect__tag py-1" style="font-size: 14px" v-if="value.tipo_imp !== ''">{{ value.tipo_imp }}</span>
                    <span v-else> {{ v.label.toString() }}</span>
                  </span>
                </span>
                <span v-else> {{ v.label.toString() }}</span>
              </span>
              <span v-else-if="columns[v.columnId].format === 'currency'">
                {{
                  v.value
                    | currency(columns[v.columnId].format)
                    | numeric(columns[v.columnId].format)
                }}
              </span>
              <span v-else>{{ v.value }}</span>
            </div>
            <input
              v-if="
                inputBind === `${v.rowId}:${v.columnId}` &&
                columns[v.columnId].editable &&
                columns[v.columnId].inputType === 'text' &&
                columns[v.columnId].format === 'string'
              "
              ref="focusMe"
              class="form-control input-table"
              :type="columns[v.columnId].inputType"
              :style="columns[v.columnId].inputStyle"
              :name="v.field"
              :value="model.value"
              @input="model.value = $event.target.value"
              @keydown.stop=""
              @keydown.enter.prevent="
                () => {
                  mode = 'readonly';
                  nextCell(model);
                }
              "
              @change="
                bindingText($event.target.value, v, columns[v.columnId].change)
              "
            />
            <input
              v-if="
                inputBind === `${v.rowId}:${v.columnId}` &&
                columns[v.columnId].inputType === 'text' &&
                columns[v.columnId].format === 'currency'
              "
              :class="[
                'form-control input-table',
                columns[v.columnId].inputClass
              ]"
              ref="focusMe"
              :name="v.field"
              :id="`${v.field}_${v.id}`"
              :type="columns[v.columnId].inputType"
              v-model.lazy="model.masked"
              @keydown.stop=""
              @keydown.enter.prevent="
                () => {
                  mode = 'readonly';
                  nextCell(model);
                }
              "
              @focusout="
                bindingNumber(
                  $event.target.value,
                  v,
                  columns[v.columnId].change,
                  2
                )
              "
              v-money="money"
            />
            <input
              v-if="
                inputBind === `${v.rowId}:${v.columnId}` &&
                columns[v.columnId].inputType === 'text' &&
                columns[v.columnId].format === 'numeric'
              "
              :class="[
                'form-control input-table',
                columns[v.columnId].inputClass
              ]"
              ref="focusMe"
              :name="v.field"
              :id="`quantity_${v.field}_${v.id}`"
              :type="columns[v.columnId].inputType"
              v-model.lazy="model.masked"
              @keydown.stop=""
              @keydown.enter.prevent="
                () => {
                  mode = 'readonly';
                  nextCell(model);
                }
              "
              @focusout="
                bindingNumber(
                  $event.target.value,
                  v,
                  columns[v.columnId].change,
                  3
                )
              "
              v-money="quantity"
            />
            <multiselect
              v-if="
                inputBind === `${v.rowId}:${v.columnId}` &&
                columns[v.columnId].editable &&
                columns[v.columnId].inputType === 'select' &&
                columns[v.columnId].call
              "
              :style="columns[v.columnId].inputStyle"
              :name="v.field"
              :input="columns[v.columnId].selectOptionsList"
              :options="columns[v.columnId].selectOptionsList"
              :label="`${columns[v.columnId].selectOptionsLabel}`"
              :track-by="`${columns[v.columnId].selectOptionsKey}`"
              v-model="model.multiSelectModel"
              @search-change="search($event, columns[v.columnId].call)"
              @close="
                () => {
                  mode = 'readonly';
                  // nextCell(v);
                }
              "
              @select="
                bindingMultiSelect(
                  model,
                  columns[v.columnId],
                  columns[v.columnId].change
                )
              "
              :loading="false"
              :multiple="columns[v.columnId].multiple"
              :internal-search="false"
              :searchable="true"
              :preserveSearch="false"
              :allowEmpty="true"
              :clearOnSelect="false"
              :optionsLimit="30"
              :showLabels="false"
              :hide-selected="true"
              :showNoOptions="false"
              placeholder=""
              :showNoResults="false"
            >
            </multiselect>
            <select
              v-if="
                inputBind === `${v.rowId}:${v.columnId}` &&
                columns[v.columnId].editable &&
                columns[v.columnId].inputType === 'select' &&
                !columns[v.columnId].call
              "
              ref="focusMe"
              :name="v.field"
              :value="model.value"
              @input="model.value = $event.target.value"
              class="form-control input-table input-select"
              @keydown.stop=""
              @keydown.enter.prevent="
                () => {
                  mode = 'readonly';
                  nextCell(model);
                }
              "
              @change="bindingText($event, v, columns[v.columnId].change)"
            >
              <option
                :key="index"
                :value="
                  getProperty(option, columns[v.columnId].selectOptionsKey)
                "
                v-for="(option, index) in columns[v.columnId].selectOptionsList"
              >
                {{
                  getProperty(option, columns[v.columnId].selectOptionsLabel)
                }}
              </option>
            </select>
            <div
              v-if="
                columns[v.columnId].editable &&
                columns[v.columnId].inputType === 'checkbox'
              "
              class="form-check form-switch d-flex justify-content-center"
              style="display: block"
            >
              <input
                class="form-check-input"
                :type="columns[v.columnId].inputType"
                role="switch"
                :value="/true/.test(rows[k][i].value)"
                v-model="v.value"
                :name="`${v.field}_${v.id}`"
                :id="`${v.field}_${v.id}`"
                @change="
                  () => {
                    skipCheckBox = true;
                    change(v, columns[v.columnId].change);
                  }
                "
              />
            </div>
            <span
              v-if="!v.isValid"
              style="font-size: 11px"
              class="text-body p-0 m-0 px-2 bottom-0 position-absolute"
              ><small>{{ v.message }}</small></span
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { integer } from 'vee-validate/dist/rules.esm';
import Multiselect from 'vue-multiselect';
import { VMoney } from 'v-money';
import EpAccordionHeader from '@/components/global/EpAccordionHeader.vue';
import EpStickTableHeader from '@/components/global/EpStickTableHeader.vue';

export default {
  name: 'EpStickTable',
  components: { EpStickTableHeader, EpAccordionHeader, Multiselect },
  directives: { money: VMoney },
  props: {
    loading: Boolean,
    primaryKey: String,
    paginate: {
      to: null,
      from: null,
      total: null,
      perPage: (value) => {}
    },
    arrowExcludeColumns: Number(0),
    columns: {
      Array,
      length: 0,
      Object: {
        title: String,
        field: String,
        fixed: false,
        validate: Boolean,
        validateRules: String,
        validateCustomRules: {
          Array,
          length: 0,
          Object: {
            name: String,
            rule: (value, { args, match }) => {}
          }
        },
        isValid: Boolean,
        editable: Boolean,
        inputType: String,
        inputStyle: String,
        inputClass: String,
        format: String,
        classThead: String,
        classRow: String,
        selectOptionsKey: String,
        selectOptionsLabel: String,
        selectOptionsList: []
      }
    },
    data: { type: Array },
    paginateScroll: Boolean,
    paginateScrollCall: null,
    forceRefresh: Boolean,
    legend: {
      Array,
      length: 0,
      Object: {
        title: String,
        symbol: String,
        native: Boolean
      }
    }
  },
  filters: {
    currency: (value, format) => {
      if (format !== 'currency') {
        return value;
      }

      let formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      });
      return formatter.format(Number(value));
    },
    numeric: (value, format) => {
      if (format !== 'numeric') {
        return value;
      }
      return new Intl.NumberFormat().format(
        Number(String(value).replace('.', ','))
      );
    }
  },
  data() {
    return {
      mode: 'readonly',
      skip: false,
      skipCheckBox: false,
      inputBind: '',
      total: 0,
      lastIndexUpdate: 1,
      columnIsInvalid: [],
      columnIsInvalidCss: [],
      model: {
        value: '',
        masked: '',
        multiSelectModel: {}
      },
      rows: {
        Array,
        length: 0,
        Object: {
          id: integer,
          rowId: integer,
          columnId: integer,
          field: String,
          value: null,
          label: null,
          masked: null,
          isValid: null,
          multiSelectModel: {},
          classRow: String
        }
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false
      },
      quantity: {
        decimal: ',',
        thousands: '',
        prefix: '',
        suffix: '',
        precision: 3,
        masked: false
      }
    };
  },
  watch: {
    data: function (data) {
      if (this.total !== this.paginate.total || this.forceRefresh) {
        this.skip = false;
        this.lastIndexUpdate = 1;
        this.rows = [];
      }

      this.total = this.paginate.total;
      this.casting(data);
    }
  },
  mounted() {
    if (this.paginateScroll) {
      this.paginateScrollTo(this.paginateScrollCall);
    }
  },
  methods: {
    search(value, call) {
      call(value);
    },
    change(value, call) {
      this.skip = true;
      if (
        this.columns[value.columnId].validate &&
        this.columns[value.columnId].validateRules
      ) {
        this.applyValidate(
          value.value,
          value.field,
          this.columns[value.columnId].validateRules,
          value.rowId,
          value.columnId,
          this.rows[value.rowId],
          this.columns[value.columnId].validateCustomRules
        );
        new Promise((resolve, reject) => {
          setTimeout(() => {
            if (this.rows[value.rowId][value.columnId].isValid) {
              resolve(() => true);
            }

            reject(() => false);
          }, 200);
        }).then(() => call(value));

        return;
      }

      call(value);
    },
    changeMode() {
      if (this.mode === 'writing') {
        if (this.$refs.focusMe) {
          if (this.$refs.focusMe[0] !== undefined) {
            this.$refs.focusMe[0].focus();
          } else {
            let input = document.querySelector('.multiselect__input');
            input.focus();
          }
        }
      }
    },
    nextCell(row) {
      if (this.mode === 'writing') {
        return;
      }

      let lastColumn = Math.floor(
        this.columns.length - this.arrowExcludeColumns - 1
      );
      if (Number(row.columnId) === lastColumn) {
        this.nextRow(row, 0);
        return;
      }

      let cell = Math.floor(row.columnId + 1);
      setTimeout(() => {
        this.inputBind = row.rowId + ':' + cell;
        this.model = this.rows[row.rowId][cell];
      }, 300);
    },
    prevCell(row) {
      if (this.mode === 'writing') {
        return;
      }

      if (Number(row.columnId) === 0) {
        return;
      }

      let cell = Math.floor(row.columnId - 1);
      setTimeout(() => {
        this.inputBind = row.rowId + ':' + cell;
        this.model = this.rows[row.rowId][cell];
      }, 300);
    },
    nextRow(row, cell) {
      if (this.mode === 'writing') {
        return;
      }

      let lastRow = Math.floor(this.rows.length - 1);
      if (Number(row.rowId) === lastRow) {
        return;
      }
      let rowId = Math.floor(row.rowId + 1);
      setTimeout(() => {
        this.inputBind = rowId + ':' + cell;
        this.model = this.rows[rowId][cell];
      }, 300);
    },
    prevRow(row, cell) {
      if (this.mode === 'writing') {
        return;
      }

      if (Number(row.rowId) === 0) {
        return;
      }

      let rowId = Math.floor(row.rowId - 1);
      setTimeout(() => {
        this.inputBind = rowId + ':' + cell;
        this.model = this.rows[rowId][cell];
      }, 300);
    },
    bindingText(value, obj, call) {
      obj.value = value;
      this.change(obj, call);
    },
    bindingNumber(value, obj, call) {
      if (value !== 'undefined' || value !== undefined) {
        obj.value = Number(value.replace('R$ ', '').replace(',', '.'));
        this.change(obj, call);
      }
    },
    bindingMultiSelect(value, column, call) {
      if (column.selectOptionsKey === 'cod_imp') {
        value.value = value.multiSelectModel;
        this.change(value, call);
        return;
      }

      value.value = this.getProperty(
        value.multiSelectModel,
        column.selectOptionsKey
      );
      this.change(value, call);
    },
    casting(data) {
      if (this.skip) {
        return;
      }

      if (this.rows.length === 0) {
        this.rows = Array.from(this.rows);
      }

      data.slice(Math.floor(this.lastIndexUpdate - 1)).map((row, i) => {
        let rowId = Math.floor(this.lastIndexUpdate - 1);
        this.lastIndexUpdate++;

        this.rows.push(
          this.columns.map((column, columnId) => {
            let id = this.getProperty(row, this.primaryKey);
            let value = this.getProperty(row, column.field, column.format);

            let multiSelectFilter = column.inputType === 'select' && !column.multiple ? {} : [];
            let selectLabelValue = this.getProperty(
              row,
              column.selectOptionsLabel,
              column.format
            );

            if (column.inputType === 'select' && !column.multiple) {
              let match = this.multiSelectFilter(column, row);
              multiSelectFilter = this.multiSelectFilterAddOptions(
                column,
                match,
                value,
                selectLabelValue
              );
            }

            if (column.inputType === 'select' && column.multiple) {
              value.forEach((v, k) => {
                if (v && v !== "") {
                  multiSelectFilter.push(JSON.parse(`{
                    "${column.selectOptionsKey}":"${v}",
                    "${column.selectOptionsLabel}":"${selectLabelValue[k]}"
                  }`))
                }
              })
            }

            return {
              rowId: rowId,
              columnId: columnId,
              field: column.field,
              id: id,
              value: value,
              masked:
                column.format === 'currency' || column.format === 'numeric'
                  ? value
                  : null,
              label: column.inputType !== 'select' ? null : selectLabelValue,
              multiSelectModel: multiSelectFilter,
              isValid: true,
              classRow: column.classRow
            };
          })
        );
        this.columns.map((column, columnId) => {
          if (column.validate && column.validateRules) {
            this.applyValidate(
              this.getProperty(row, column.field, column.format),
              column.field,
              column.validateRules,
              rowId,
              columnId,
              row,
              column.validateCustomRules
            );
          }
        });
      });
    },
    applyValidate(
      value,
      column,
      rules,
      rowId,
      columnId,
      args = null,
      customRules = null
    ) {
      if (customRules) {
        customRules.map((obj) => {
          let match = this.columns[columnId].selectOptionsList;
          let validator = obj.rule(value, { args, match });

          this.rows[rowId][columnId].isValid = validator.valid;
          if (validator.valid === false) {
            this.columnIsInvalid[columnId] = true;
            this.rows[rowId][columnId].message = validator.data.message;

            if (
              validator.data.message ===
              this.columns[columnId].columnIsInvalidError
            ) {
              this.columnIsInvalidCss[columnId] =
                this.columns[columnId].columnIsInvalidCss;
            }
          }
        });
        return;
      }

      let field = this.$validator.attach({
        name: column,
        rules: rules
      });

      this.$validator.validate(column, value, field).then((isValid) => {
        this.rows[rowId][columnId].isValid = isValid;
        if (!isValid) {
          const errorMessage = this.$validator.errors.first(column);
          const cssErrorDanger = 'table-danger border border-danger';

          this.columnIsInvalid[columnId] = true;
          this.columnIsInvalidCss[columnId] = cssErrorDanger;
          this.rows[rowId][columnId].message = errorMessage;
        }
      });
    },
    paginateScrollTo(call) {
      document.getElementById('table-stick').onscroll = (e) => {
        if (
          e.target.scrollTop + e.target.offsetHeight ===
            e.target.scrollHeight ||
          e.target.scrollTop + e.target.offsetHeight > e.target.scrollHeight
        ) {
          this.skip = false;
          call(true);

          e.target.onscroll = () => {};
          setTimeout(() => this.paginateScrollTo(call), 600);
        }
      };
    },
    multiSelectFilterAddOptions(column, match, value, selectLabelValue) {
      if (!match && !value) {
        match = JSON.parse(
          `{"${column.selectOptionsLabel}":"${selectLabelValue}"}`
        );
      }

      if (!match) {
        match = JSON.parse(`{
                    "${column.selectOptionsKey}":"${value}"
                  }`);
        column.selectOptionsList.push(match);
      }

      return match;
    },
    multiSelectFilter(column, row) {
      if (column.inputType !== 'select') {
        return {};
      }

      let select = column.selectOptionsList.filter(
        (obj) =>
          Number(this.getProperty(obj, column.selectOptionsKey)) ===
          Number(this.getProperty(row, column.field))
      );

      return select.length > 0 ? select[0] : null;
    },
    getProperty(object, field, type = 'string') {
      if (!Object.hasOwn(object, field)) {
        return '';
      }
      if (type === 'boolean' || type === 'bool') {
        if (object[field] === 'false') {
          return false;
        }
        return /true/.test(object[field]);
      }
      return object[field] !== {} ? object[field] : '';
    }
  },
  updated() {
    this.$nextTick(() => {
      if (this.inputBind === '' && this.rows.length > 0) {
        this.model = this.rows[0][0];
        this.inputBind = '0:0';
      }

      if (this.mode === 'readonly' && !this.skipCheckBox) {
        if (this.$refs.spell) {
          if (this.$refs.spell[0] !== undefined) {
            this.$refs.spell[0].focus();
          }
        }
      }

      this.skipCheckBox = false;
    });
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  $primary-color: #872240;
  $body-warning-color: var(--bs-orange) !important;
  $body-warning-bg: var(--bs-orange) !important;

  .border-secondary {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-orange), var(--bs-border-opacity)) !important;
  }

  .table-warning {
    --bs-table-bg: #ffdec7;
    --bs-table-striped-bg: #ffdec7;
  }

  .border-warning {
    border-color: var(--bs-orange) !important;
  }

  .custom-tooltip {
    --bs-tooltip-bg: var($primary-color) !important;
  }

  .cell-label {
    padding-top: 15px !important;
    height: 100% !important;
    font-family: 'Montserrat', sans-serif !important;
    font-style: normal !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    color: #4b5053 !important;
  }
  table > tbody > tr > td {
    border: 1px solid #dcdde4;
  }
  table > tbody > tr > td > input {
    height: 100% !important;
    background: transparent !important;
    font-family: 'Montserrat', sans-serif !important;
    font-style: normal !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    color: #4b5053 !important;
    border: none !important;

    :focus {
      outline: transparent !important;
    }

    ::placeholder {
      display: none !important;
    }
  }

  table > tbody > tr > td > select {
    background: transparent !important;
    font-family: 'Montserrat', sans-serif !important;
    font-style: normal !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
  }

  .form-control {
    border: 0 !important;
    background: transparent !important;
    font-family: 'Montserrat', sans-serif !important;
    font-style: normal !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    box-shadow: none;
    border-radius: 0;
    height: 100% !important;
    width: 100% !important;
    display: block;
    padding-left: 5px !important;
    padding-bottom: 8px !important;
    padding-top: 16px !important;
  }

  .multiselect,
  .multiselect__single,
  .multiselect__input {
    min-height: 100% !important;
    background: transparent !important;
    font-family: 'Montserrat', sans-serif !important;
    font-style: normal !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    color: #4b5053 !important;
    border: none !important;
    display: block;

    :hover {
      outline: transparent !important;
    }

    :focus {
      outline: transparent !important;
    }

    :after {
      content: attr(data-deselect);
    }

    ::placeholder {
      display: none !important;
    }
  }

  .multiselect {
    padding: 8px !important;
  }

  .multiselect__select:before {
    right: 5px !important;
    width: 100% !important;
    top: 5px !important;
    color: #4b5053 !important;
    border-color: transparent !important;
    border-style: none;
    content: '';
  }

  .multiselect__option--selected {
    background: #f3f3f3;
    width: 100% !important;
    color: #4b5053;
    font-weight: 400;
  }

  .multiselect__placeholder {
    display: none !important;
  }

  .multiselect--active .multiselect__placeholder {
    display: none !important;
  }

  .multiselect__content {
    display: block !important;
  }

  .multiselect__content-wrapper {
    margin-top: 10px !important;
    left: 0px !important;
    background: #fff !important;
    color: #4b5053 !important;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    width: 100% !important;
    overflow: auto !important;
    position: absolute !important;
    z-index: 199999;
  }

  .multiselect--above .multiselect__content-wrapper {
    bottom: 100%;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
  }

  .multiselect__option--selected {
    background: $primary-color !important;
    color: #4b5053;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
  }

  .multiselect__option--selected:after {
    content: attr(data-selected);
    color: #4b5053 !important;
    background: inherit;
  }

  .multiselect__option {
    display: block !important;
    padding: 10px !important;
    min-height: 40px;
    line-height: 16px;
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
  }

  .multiselect__option:after {
    top: 0;
    right: 0;
    background: $primary-color;
    position: absolute;
    line-height: 40px;
    padding-right: 12px;
    padding-left: 20px;
    font-size: 13px;
  }

  .multiselect__option--highlight {
    background: $primary-color;
    outline: none;
  }

  .multiselect__option--highlight:after {
    content: attr(data-select);
    background: $primary-color;
  }

  .multiselect__input {
    min-width: 100% !important;
    min-height: 100% !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    display: block;
  }

  .multiselect__single {
    display: block !important;
    margin: 0 !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    white-space: nowrap;
    max-width: 95% !important;
  }

  .form-check-input:checked {
    background-color: $primary-color;
    border-color: $primary-color;
  }

  .input-table {
    width: 100%;
    background: rgba(226, 230, 232, 0.4);
    &:focus {
      display: block;
    }
  }

  table {
    max-height: 100vh !important;
    font-family: $font-secondary;
    thead {
      tr {
        th {
          font-weight: 600;
        }
      }
    }
  }
}
</style>
