import FractionTableService from '@/services/fractionTable.service';

export const fractionTable = {
  namespaced: true,
  state: {
    fractionTables: {
      links: {},
      meta: {},
      results: {},
    },
    orderSelected: 'asc', // deixar ordernado a lista de paginação
    observationsProduct: {},
    orderSelected: 'asc',
    newFraction: {},
    fraction: {},
    newFraction: {},
  },
  mutations: {
    FILL_FRACTION_TABLES(state, fractionTables) {
      state.fractionTables = fractionTables;
    },
    FILL_NEW_FRACTION(state, newFraction) {
      // adicionar novo
      state.fraction = newFraction;
    },
    DELETE_FRACTION(state, fraction) {
      // deletar
      state.fraction = fraction;
    },
    FILL_OBSERVATIONS(state, observationsProduct) {
      state.observationsProduct = observationsProduct;
    },
    FILL_NEW_FRACTION(state, newFraction) {
      state.newFraction = newFraction;
    },
    FILL_FRACTION(state, fraction) {
      state.fraction = fraction;
    },
  },
  actions: {
    async getFractionTables({ commit }, search) {
      const response = await FractionTableService.getFractionTableList(search);
      response.code === 200 ? commit('FILL_FRACTION_TABLES', response.data) : null;
    },
    async getPerPage({ commit }, term) {
      const response = await FractionTableService.getPerPage(
        term,
        this.state.fractionTable.orderSelected
      );
      response.code === 200 ? commit('FILL_FRACTION_TABLES', response.data) : null;
    },
    async changePage({ commit }, payload) {
      if (payload.url) {
        const response = await FractionTableService.getFractionTableByUrl(
          payload.url,
          this.state.fractionTable.orderSelected
        );
        response.code === 200 ? commit('FILL_FRACTION_TABLES', response.data) : null;
      }
    },
    async getObservations({ commit }) {
      const response = await FractionTableService.getObservationProduct();
      response.code === 200 ? commit('FILL_OBSERVATIONS', response.data) : null;
    },
    async createFractionGroup({ commit }, payload) {
      return FractionTableService.createFractionGroup(payload)
        .then((response) => {
          commit('FILL_NEW_FRACTION', response.data);
          Toast.fire('Grupo de fracionamento cadastrado com sucesso!', '', 'success');
        })
        .catch((error) => {
          Toast.fire({
            title: 'Atenção',
            html: `
              Erro ao cadastrar Grupo de fracionamento! Por favor, tente novamente.<br>
              <span style="font-size: 12px">- ${error.response.data.data.message}</span>
              `,
            icon: 'error',
            width: 600,
            showCloseButton: true,
            timerProgressBar: false,
          });
          throw error.response.data;
        });
    },
    async getFractionGroup({ commit }, id) {
      const response = await FractionTableService.getFractionById(id);
      return response.code === 200 ? commit('FILL_FRACTION', response.data.results) : null;
    },
    async editFractionGroup({ commit }, { id, payload }) {
      return FractionTableService.editFractionGroup(id, payload)
        .then(() => {
          Toast.fire('Grupo de fracionamento atualizado com sucesso!', '', 'success');
        })
        .catch((error) => {
          Toast.fire('Erro ao atualizar Grupo de fracionamento!', '', 'error');
        });
    },
    async changeStatus({ commit }, { id, payload }) {
      return FractionTableService.editStatus(id, payload).then(() => {
        Toast.fire('Status atualizado com sucesso!', '', 'success');
      }).catch((error) => {
        Toast.fire('Erro ao atualizar Status!', '', 'error');
      });
    },
    async deleteFraction({ commit }, id) {
      return FractionTableService.deleteFraction(id).then(() => {
        Toast.fire('Grupo de fracionamento removido com sucesso!', '', 'success');
      })
        .catch((error) => {
          Toast.fire('Erro ao remover Grupo de fracionamento! <br> Por favor, tente novamente.', '', 'error');
        });
    },
  },
  getters: {
    fractionTables: (state) => state.fractionTables,
    fraction: (state) => state.fraction,
    observationsProduct: (state) => state.observationsProduct,
  },
};
