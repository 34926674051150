import { mainAxios } from '@/plugins/axios';

class EmployeesService {
  async getEmployeesByUrl(url, order) {
    const orderField = `?order_field=cod_func&order=${order}&`;
    const splitString = url.split('?');
    const completeUrl = `${splitString[0]}${orderField}${splitString[1]}`;
    const response = await mainAxios.get(`${completeUrl}`);
    return response.data;
  }
  async getPerPage(perPage, order) {
    const response = await mainAxios.get(
      `/funcionarios?order_field=cod_func&order=${order}&per_page=${perPage}`
    );
    return response.data;
  }
  async getEmployees(search) {
    const response = await mainAxios.get(
      `/funcionarios?order_field=cod_func&order=desc&search=${search}`
    );
    return response.data;
  }
  async getStates() {
    const response = await mainAxios.get(`/estados?paginate=false`);
    return response.data;
  }
  async cepConsult(cep) {
    const response = await mainAxios.get(`pesquisa/cep/${cep}`);
    return response.data;
  }
  async createEmployee(payload) {
    const dataPayload = {
      ...payload,
      comissao_func: payload.comissao_func ? Number(payload.comissao_func) : null,
      pontuacao_func: payload.pontuacao_func ? Number(payload.pontuacao_func) : null,
      rg_func: payload.rg_func ? payload.rg_func.match(/\d/g).join('') : null,
      cpf_func: payload.cpf_func ? payload.cpf_func.match(/\d/g).join('') : null,
      telcel_func: payload.telcel_func ? payload.telcel_func.match(/\d/g).join('') : null,
      cep_func: payload.cep_func ? payload.cep_func.match(/\d/g).join('') : null
    };
    const response = await mainAxios.post(`/funcionarios`, dataPayload);
    return response.data;
  }
  async updateEmployee(id, payload) {
    const dataPayload = {
      ...payload,
      comissao_func: payload.comissao_func ? Number(payload.comissao_func) : null,
      pontuacao_func: payload.pontuacao_func ? Number(payload.pontuacao_func) : null,
      rg_func: payload.rg_func ? payload.rg_func.match(/\d/g).join('') : null,
      cpf_func: payload.cpf_func ? payload.cpf_func.match(/\d/g).join('') : null,
      telcel_func: payload.telcel_func ? payload.telcel_func.match(/\d/g).join('') : null,
      cep_func: payload.cep_func ? payload.cep_func.match(/\d/g).join('') : null
    };
    const response = await mainAxios.put(`/funcionarios/${id}`, dataPayload);
    return response.data;
  }
  async getEmployeeById(id) {
    const response = await mainAxios.get(`/funcionarios/${id}`);
    return response.data;
  }
  async getProfiles() {
    const response = await mainAxios.get(
      `/perfil?fields=perfil_codigo,perfil_nome&paginate=false`
    );
    return response.data;
  }
  async removeEmployee(id) {
    const response = await mainAxios.delete(`/funcionarios/${id}`);
    return response.data;
  }
}

export default new EmployeesService();
