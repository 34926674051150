var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'vh-100': _vm.$route.name === 'import-products-item' },style:([
    { 'max-height': _vm.$route.name === 'import-products-item' ? '15%' : '' },
    {
      'margin-bottom':
        _vm.$route.name === 'import-products-item' ? '-10px!important' : ''
    }
  ])},[_c('form',{attrs:{"action":"","autocomplete":"off"}},[_c('div',{staticClass:"d-flex"},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"multiselect-sales mt-auto mx-2"},[_c('multiselect',{attrs:{"autocomplete":"off","name":'filterHeader',"input":_vm.filter,"options":_vm.filter,"label":_vm.label,"track-by":_vm.trackBy,"internal-search":true,"searchable":true,"preserveSearch":false,"clearOnSelect":false,"showLabels":false,"showNoOptions":false,"placeholder":"Selecione a empresa"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)])]),_c('nav',{staticClass:"breadcrumb-nav",staticStyle:{"--bs-breadcrumb-divider":"'>'"},attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},_vm._l((_vm.items),function(item,i){return _c('li',{key:i,staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":item.path}},[_vm._v(_vm._s(item.name))])],1)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }