import { mainAxios } from "@/plugins/axios";

class FractionTableService {
  async getFractionTableByUrl(url, order) {
    const orderField = `?order_field=cod_grupo_frac&order=${order}&`;
    const splitString = url.split('?');
    const completeUrl = `${splitString[0]}${orderField}${splitString[1]}`;
    const response = await mainAxios.get(`${completeUrl}`);
    return response.data;
  }
  async getPerPage(perPage, order) {
    const response = await mainAxios.get(
      `/grupo-fracionamento?order_field=cod_grupo_frac&order=${order}&per_page=${perPage}`
    );
    return response.data;
  }
  async getFractionTableList(search) {
    const response = await mainAxios.get(search === undefined ? `/grupo-fracionamento` : `/grupo-fracionamento?search=${search}`);
    return response.data;
  }
  async getObservationProduct() {
    const response = await mainAxios.get(`/observacao?paginate=false`);
    return response.data;
  }
  async createFractionGroup(payload) {
    const dataPayload = {
      ...payload,
      tipo_pagamento: payload.tipo_pagamento === '' ? 'R' : payload.tipo_pagamento,
    }
    const response = await mainAxios.post(`/grupo-fracionamento`, dataPayload);
    return response.data;
  }
  async getFractionById(id) {
    const response = await mainAxios.get(`/grupo-fracionamento/${id}`);
    return response.data;
  }
  async editFractionGroup(id, payload) {
    const dataPayload = {
      ...payload,
      tipo_pagamento: payload.tipo_pagamento === '' ? 'R' : payload.tipo_pagamento,
    };
    const response = await mainAxios.put(`/grupo-fracionamento/${id}`, dataPayload);
    return response.data;
  }
  async editStatus(id, payload) {
    const data = {
      status_grupo_frac: payload
    };
    const response = await mainAxios.put(`/grupo-fracionamento/${id}`, data);
    return response.data;
  }
  async deleteFraction(id) {
    const response = await mainAxios.delete(`/grupo-fracionamento/${id}`);
    return response.data;
  }
}

export default new FractionTableService();
