import { render, staticRenderFns } from "./EpPagination.vue?vue&type=template&id=637706c8&scoped=true"
import script from "./EpPagination.vue?vue&type=script&lang=js"
export * from "./EpPagination.vue?vue&type=script&lang=js"
import style0 from "./EpPagination.vue?vue&type=style&index=0&id=637706c8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "637706c8",
  null
  
)

export default component.exports