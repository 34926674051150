import router from '@/router';
import AuthService from '@/services/auth.service';
import Axios from 'axios';

export const auth = {
  namespaced: true,
  state: {
    fail: false,
    token: '',
    logged: {}
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    loginSuccess(state, logged) {
      state.logged = logged;
    },
    loginFailure(state) {
      state.logged = null;
      state.fail = state;
    },
    logout(state) {
      state.logged = null;
    }
  },
  actions: {
    login({ commit }, params) {
      return AuthService.login(params).then(
        (logged) => {
          commit('loginSuccess', logged);
          commit('SET_TOKEN', logged.access_token);
          return Promise.resolve(logged);
        }).catch((error) => {
          Toast.fire('Usuário ou senha incorretos!', '', 'error');
          commit('loginFailure', true);
        });
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
      router.push('/auth');
    },
    abilities({ commit }) {
      commit('loginSuccess', JSON.parse(sessionStorage.getItem('logged')));
    }
  },
  getters: {
    logged: (state) => state.logged,
    fail: (state) => state.fail
  }
};
