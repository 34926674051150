<template>
  <div
    class="d-flex justify-content-center align-items-center container-loading"
    v-if="loading"
  >
    <div class="spinner-border" role="status">
      <p class="visually-hidden">Loading...</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EpLoading',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="css" scoped>
.container-loading {
  width: 100vmax;
  height: 100vmax;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9998;
}
.spinner-border {
  width: 5rem;
  height: 5rem;
  color: #3d0f1c;
}
</style>
