import { mainAxios } from '@/plugins/axios';

class PaymentMethodsService {
  async newPaymentMethod(payload) {
    let data = {
      ...payload,
      cod_ecf_pagto: 0,
      flg_cartao_sped: false
    }
    const response = await mainAxios.post(`/tipo-pagamento`, data);
    return response.data;
  }
  async getPaymentMethods(order) {
    const response = await mainAxios.get(`/tipo-pagamento?order_field=desc_tpag&order=${order}&`);
    return response.data;
  }
  async searchPaymentMethod(term) {
    const response = await mainAxios.get(`/tipo-pagamento?search=${term}`);
    return response.data;
  }
  async removePaymentMethod(payment) {
    const response = await mainAxios.delete(`/tipo-pagamento/${payment.cod_tpag}`);
    return response.data;
  }
  async getPaymentMethodsByUrl(url, order, perPage) {
    const orderField = `?order_field=desc_tpag&order=${order}&`;
    const splitString = url.split('?');
    const completeUrl = `${splitString[0]}${orderField}${splitString[1]}`;
    const response = await mainAxios.get(`${completeUrl}&per_page=${perPage}`);
    return response.data;
  }
  async getPaymentMethodsPerPage(currentPage, order, perPage) {
    const response = await mainAxios.get(`/tipo-pagamento?page=${currentPage}?order_field=desc_tpag&order=${order}&per_page=${perPage}`);
    return response.data;
  }
  async getBanks() {
    const response = await mainAxios.get(`/bancos?paginate=false`);
    return response.data;
  }
  async getCoastCenter() {
    const response = await mainAxios.get(`/centro-custo?paginate=false`);
    return response.data;
  }
  async getAccountPlanRevenue() {
    const response = await mainAxios.get(`/plano-conta/receitas?paginate=false`);
    return response.data;
  }
  async getAccountPlanRevenueOmie() {
    const response = await mainAxios.get(`/plano-conta/receitas/omie?paginate=false`);
    return response.data;
  }
  async getAccountPlanExpense() {
    const response = await mainAxios.get(`/plano-conta/despesas?paginate=false`);
    return response.data;
  }
  async getAccountPlanExpenseOmie() {
    const response = await mainAxios.get(`/plano-conta/despesas/omie?paginate=false`);
    return response.data;
  }
  async getAdminCard() {
    const response = await mainAxios.get(`/administradora-cartao?paginate=false`);
    return response.data;
  }
  async editStatus(id, payload) {
    const response = await mainAxios.put(`/tipo-pagamento/${id}`, { flg_status_tpag: payload });
    return response.data;
  }
  async getPaymentById(id) {
    const response = await mainAxios.get(`/tipo-pagamento/${id}`);
    return response.data;
  }
  async editPayment(id, payload) {
    const response = await mainAxios.put(`/tipo-pagamento/${id}`, payload);
    return response.data;
  }
}
export default new PaymentMethodsService();
