<template>
  <div class="custom-select mb-3">
    <div class="inputSearch">
      <input v-model="searchTerm" @input="filterOptions" class="form-control" placeholder="Selecionar categoria" />
      <img src="../../assets/img/Vector.svg" class="fake-select" alt="" :class="showOptions ? 'active' : ''"
        @click="showOptions = !showOptions" />
    </div>
    <ul v-if="showOptions" class="options-list">
      <li>
        <p class="mb-0">Selecione categoria ou crie uma nova</p>
      </li>
      <li v-for="(option, index) in filteredOptions" :key="index" @click="selectOption(option)" class="option-item">
        {{ option.desc_produto_grupo }}
      </li>
    </ul>
    <button @click="createItem" v-if="searchTerm && !optionExists" class="btn btn-link create-btn">
      <span>Criar</span> {{ searchTerm }}
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      options: ['Option 1', 'Option 2', 'Option 3'],
      searchTerm: '',
      showOptions: false,
    };
  },
  computed: {
    ...mapState(['productGroup']),
    filteredOptions() {
      return this.$store.state.productGroup.infos.categories.filter((option) =>
        option.desc_produto_grupo
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase())
      );
    },
    optionExists() {
      return this.$store.state.productGroup.infos.categories.includes(
        this.searchTerm
      );
    },
  },
  methods: {
    filterOptions() {
      this.showOptions = true;
    },
    selectOption(option) {
      this.searchTerm = option.desc_produto_grupo;
      this.showOptions = false;
    },
    createItem() {
      if (this.searchTerm && !this.optionExists) {
        this.$store.dispatch('productGroup/createCategory', {
          desc_produto_grupo: this.searchTerm,
          cod_produto_grup_ant: 0,
        });
        this.searchTerm = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-select {
  position: relative;
  z-index: 9999;
}

.options-list {
  list-style-type: none;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  background-color: white;
  z-index: 1;
  padding: 0;

  li {
    p {
      color: #7f8588;
      font-family: $font-primary;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
    }
  }
}

.option-item {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.option-item:hover {
  background-color: #f8f9fa;
}

.create-btn {
  margin-top: 5px;
}

.inputSearch {
  position: relative;
}

.fake-select {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 1.5rem 1.25rem 1.2rem 1.25rem;
  transform: rotate(270deg);
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  &.active {
    padding: 1.7rem 1.1rem 1.2rem 1.1rem;
    transform: rotate(0);
  }
}
</style>
