import { mainAxios } from '@/plugins/axios';

class DirectPrintService {
  async getListByUrl(url, order) {
    const orderField = `?order_field=cod_imp&order=${order}&`;
    const splitString = url.split('?');
    const completeUrl = `${splitString[0]}${orderField}${splitString[1]}`;
    const response = await mainAxios.get(`${completeUrl}`);
    return response.data;
  }
  async getPerPage(perPage, order) {
    const response = await mainAxios.get(
      `/impressora?order_field=cod_imp&order=${order}&per_page=${perPage}`
    );
    return response.data;
  }
  async getList(search) {
    const response = await mainAxios.get(
      search === undefined
        ? `/impressora?order_field=cod_imp&order=desc`
        : `/impressora?search=${search}`
    );
    return response.data;
  }
  async getDirectPrintById(id) {
    const response = await mainAxios.get(`/impressora/${id}`);
    return response.data;
  }
  async delete(id) {
    const response = await mainAxios.delete(`/impressora/${id}`);
    return response.data;
  }
  async getListMachines(codEmp = null) {
    let url = `/equipamento?columns=tipo_hosts,cod_hosts,desc_hosts,mac_address_hosts,ip_hosts,subnet_mask_hosts&paginate=false&scope=app`
    if (codEmp) {
      url = `/equipamento?columns=tipo_hosts,cod_hosts,desc_hosts,mac_address_hosts,ip_hosts,subnet_mask_hosts&paginate=false&scope=app&cod_emp=${codEmp}`
    }

    const response = await mainAxios.get(url);
    return response.data;
  }
  async getListPrinters(codEmp = null) {
    let url = `/equipamento?columns=tipo_hosts,cod_hosts,desc_hosts,mac_address_hosts,ip_hosts,subnet_mask_hosts&paginate=false&scope=impressoras`
    if (codEmp) {
      url = `/equipamento?columns=tipo_hosts,cod_hosts,desc_hosts,mac_address_hosts,ip_hosts,subnet_mask_hosts&paginate=false&scope=impressoras&cod_emp=${codEmp}`
    }

    const response = await mainAxios.get(url);
    return response.data;
  }
  async getListKds(codEmp = null) {
    let url = `/equipamento?columns=tipo_hosts,cod_hosts,desc_hosts,mac_address_hosts,ip_hosts,subnet_mask_hosts&paginate=false&scope=kds`
    if (codEmp) {
      url = `/equipamento?columns=tipo_hosts,cod_hosts,desc_hosts,mac_address_hosts,ip_hosts,subnet_mask_hosts&paginate=false&scope=kds&cod_emp=${codEmp}`
    }

    const response = await mainAxios.get(url);
    return response.data;
  }
  async getCompanies() {
    const response = await mainAxios.get(
      `/empresas?paginate=false&fields=cod_emp,rsocial_emp,fantasia_emp`
    );
    return response.data;
  }
  async createDirectPrint(payload) {
    const data = {
      ...payload,
      flg_tela: payload.flg_tela === true ? 1 : 0,
    };
    const response = await mainAxios.post(`/impressora`, data);
    return response.data;
  }
  async deleteDirectPrint(id) {
    const response = await mainAxios.delete(`/impressora/impressora-host/${id}`);
    return response.data;
  }
  async deleteDirectKds(id) {
    const response = await mainAxios.delete(`/impressora/kds-host/${id}`);
    return response.data;
  }
  async editDirectPrint(id, payload) {
    return mainAxios
      .put(`/impressora/${id}`, payload)
      .then((response) => {
        Toast.fire('Direcionamento de impressão editado com sucesso!', '', 'success');
        return response.data;
      })
      .catch((err) => {
        Toast.fire(
          'Erro ao editar Direcionamento de impressão! Por favor, tente novamente.',
          '',
          'error'
        );
        throw err;
      });
  }
  async deleteCompany(id) {
    const response = await mainAxios.delete(`impressora/empresa/${id}`);
    return response.data;
  }
}

export default new DirectPrintService();
