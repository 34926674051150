<template>
  <span :class="`badge badge-${variant} ${classes}`">
    {{ text }}
  </span>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    text: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    classes: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.badge {
  padding: 4px 8px;
  height: 27px;
  border-radius: 56px;
  line-height: 1.5;
  font-family: $font-secondary;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
}
.badge-primary {
  background: $primary-color;
  color: #fff;
}
.badge-gray {
  background: #e9edef;
  color: #4b5053;
}
</style>
