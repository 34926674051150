import DashboardService from "@/services/dashboard.service";

export const dashboard = {
  namespaced: true,
  state: {
    dashboard: [],
  },
  mutations: {
    FILL_DASHBOARD(state, dashboard) {
      state.dashboard = dashboard;
    },
  },
  actions: {
    async getDashboardData({ commit }, payload) {
      const response = await DashboardService.dashboard(payload);
      commit('FILL_DASHBOARD', response);
    },
  },
  getters: {
    dashboard: (state) => state.dashboard,
  },
};
