<template>
  <div
    :class="{ 'vh-100': $route.name === 'import-products-item' }"
    :style="[
      { 'max-height': $route.name === 'import-products-item' ? '15%' : '' },
      {
        'margin-bottom':
          $route.name === 'import-products-item' ? '-10px!important' : ''
      }
    ]"
  >
    <h1 class="title text-start">{{ title }}</h1>
    <nav
      class="breadcrumb-nav"
      aria-label="breadcrumb"
      style="--bs-breadcrumb-divider: '>'"
    >
      <ol class="breadcrumb">
        <li v-for="item in items" class="breadcrumb-item" :key="item.name">
          <router-link :to="item.path">{{ item.name }}</router-link>
        </li>
      </ol>
    </nav>
  </div>
</template>
<script lang="ts">
export default {
  name: 'EpSubHeader',
  props: {
    title: { required: true, type: String },
    items: { type: Array }
  }
};
</script>
<style scoped lang="scss">
h1 {
  color: #272a2c;
  font-family: $font-secondary;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}

.breadcrumb {
  margin-top: 8px;

  .breadcrumb-item {
    a {
      font-size: 14px;
      color: #5e666a;
    }
  }
}

.breadcrumb-nav {
  margin-bottom: 45px;
}
</style>
