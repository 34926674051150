import { mainAxios } from '@/plugins/axios';

class ProductGroupService {
  async list(pageUrl, perPage, limit, paginate, search = '') {
    const config = {
      transformResponse: [
        (data) => {
          let response = JSON.parse(data);
          response.data.results = response.data.results.map((value) => {
            return {
              cod_produto_grup: value.cod_produto_grup,
              desc_produto_grupo: value.desc_produto_grupo,
              desc_produto_grupo_select: value.desc_produto_grupo_select
            };
          });
          return response;
        }
      ]
    };
    const { data } = await mainAxios.get(
      `${pageUrl}&per_page=${perPage}&paginate=${paginate}&`,
      config
    );
    return data;
  }
  async listAll(search = '', limit = '') {
    const config = {
      transformResponse: [
        (data) => {
          let response = JSON.parse(data);
          response.data.results = response.data.results.map((value) => {
            return {
              cod_produto_grup: value.cod_produto_grup,
              desc_produto_grupo: value.desc_produto_grupo,
              desc_produto_grupo_select: value.desc_produto_grupo_select,
              cod_regra_fiscal: value.cod_regra_fiscal,
              NCM: value.NCM
            };
          });
          return response;
        }
      ]
    };
    const { data } = await mainAxios.get(
      `/produto-subgrupo?paginate=false&limit=${limit}&search=${search}`,
      config
    );
    return data;
  }
  async getProductGroups(order) {
    const response = await mainAxios.get(
      `/produto-subgrupo?order_field=cod_produto_grup&order=${order}&per_page=15`
    );
    return response.data;
  }
  async getProductGroupsByCategoryIdAndSearch(id, search = '') {
    const response = await mainAxios.get(
      `/produto-categoria/${id}/grupos?paginate=false&search=${search}`
    );
    return response.data;
  }
  async getProductGroupsByUrl(url, order) {
    const orderField = `?order_field=cod_produto_grup&order=${order}&`;
    const splitString = url.split('?');
    const completeUrl = `${splitString[0]}${orderField}${splitString[1]}`;
    const response = await mainAxios.get(`${completeUrl}`);
    return response.data;
  }
  async getPerPage(perPage, order) {
    const response = await mainAxios.get(
      `/produto-subgrupo?order_field=cod_produto_grup&order=${order}&per_page=${perPage}`
    );
    return response.data;
  }
  async sortProduct(type, item) {
    const response = await mainAxios.get(
      `/produto-subgrupo?order=${type}&order_field=${item}`
    );
    return response.data;
  }
  async searchProductGroup(term) {
    const response = await mainAxios.get(`/produto-subgrupo?search=${term}`);
    return response.data;
  }
  async removeProductGroup(id) {
    const response = await mainAxios.delete(`/grupos/${id}`);
    return response.data;
  }
  async getCategories(search = '') {
    const response = await mainAxios.get(`/produto-categoria?paginate=false&search=${search}`);
    return response.data;
  }
  async getProductGroup(search = '') {
    const response = await mainAxios.get(`/produto-grupo?paginate=false&search=${search}`);
    return response.data;
  }
  async getProductSubGroup(search = '') {
    const response = await mainAxios.get(`/produto-subgrupo?paginate=false&search=${search}`);
    return response.data;
  }
  async getFiscalRules() {
    const response = await mainAxios.get(`/regras-fiscais?paginate=false`);
    return response.data;
  }
  async createProductGroup(payload) {
    const response = await mainAxios.post(`/produto-subgrupo`, payload);
    return response.data;
  }
  async createCategory(payload) {
    const response = await mainAxios.post(`/produto-categoria`, payload);
    return response.data;
  }
  async createGroup(payload) {
    const response = await mainAxios.post(`/produto-grupo`, payload);
    return response.data;
  }
  async editCategory(id, payload) {
    const response = await mainAxios.put(`/produto-categoria/${id}`, payload);
    return response.data;
  }
  async editProductGroup(id, payload) {
    const response = await mainAxios.put(`/produto-grupo/${id}`, payload);
    return response.data;
  }
  async editProductSubGroup(id, payload) {
    const response = await mainAxios.put(`/produto-subgrupo/${id}`, payload);
    return response.data;
  }
  async getGroupById(id) {
    const response = await mainAxios.get(`/grupos/${id}`);
    return response.data;
  }
  async getFiscalIssues() {
    const response = await mainAxios.get(
      `/emissor-nfce?columns=cod_emissor,fantasia_emp,rsocial_emp,cnpj`
    );
    return response.data;
  }
}

export default new ProductGroupService();
