import ProfileService from "@/services/profile.service";

export const profile = {
  namespaced: true,
  state: {
    attributes: {
      results: []
    }
  },
  mutations: {
    FILL_ATTRIBUTES(state, attributes) {
      state.attributes = attributes;
    }
  },
  actions: {
    async getAttributes({ commit }, type) {
      const response = await ProfileService.getModules(type);
      response.code === 200 ? commit('FILL_ATTRIBUTES', response.data) : null;
    }
  },
  getters: {
    attributes: (state) => state.attributes,
  },
}