<template>
  <div>
    <div class="input form-floating">
      <input
        v-bind="$attrs"
        class="form-control input-select"
        autocomplete="off"
        ref="input"
        :type="type"
        :placeholder="placeholderName"
        :aria-label="ariaLabelName"
        :id="idName"
        @input="verifyIfOptionIsValidAndEmitValue"
        @click="
          () => {
            receiveShowedOptions();
            showSelectableOptions = true;
          }
        "
        :class="{ inputActive: arrowCounter === -1 }"
        @keypress.prevent.enter="onSelect"
        v-model="selectedOption[label]"
        @keydown="onKeydown"
      />
      <img
        class="fake-select"
        :class="showSelectableOptions ? 'active' : ''"
        @click="receiveShowedOptions"
        src="../../assets/img/Vector.svg"
        alt=""
      />
      <label :for="idName">{{ labelName }}</label>
    </div>
    <div v-if="showSelectableOptions" class="options">
      <p
        @click="createOption"
        class="pointer create-option"
        v-if="selectedOption[label] && isOptionValid"
      >
        <strong class="fw-normal">Criar</strong>
        {{ selectedOption[label] }}
      </p>
      <p class="small-text">
        <u>Selecione</u> a {{ fieldName }} ou crie uma nova
      </p>
      <ul class="list">
        <li
          v-for="(option, index) in selectableOptions"
          :ref="`option_${index}`"
          :key="option[id]"
          @click="chooseOption(option, index)"
          :class="{ pillActive: arrowCounter === index }"
        >
          <span class="pill">{{ option[label] }}</span>
          <em class="bi bi-three-dots"></em>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EpSelectCreate',
  props: {
    options: { type: Array, required: true, default: () => [] },
    id: { type: String || Number, required: true },
    label: { type: String || Number, required: true },
    labelName: { type: String, required: true },
    fieldName: { type: String, required: true },
    idName: { type: String, required: true },
    ariaLabelName: { type: String, required: true },
    placeholderName: { type: String, required: true },
    type: { type: String, required: true },
    nameEmit: { type: String, required: true }
  },
  data() {
    return {
      selectedOption: {},
      selectableOptions: {},
      showSelectableOptions: false,
      firstShow: 0,
      arrowCounter: -1,
      isOptionValid: true
    };
  },
  methods: {
    onKeydown(evt) {
      if (!this.selectableOptions.length) {
        return;
      }
      switch (evt.code) {
        case 'ArrowDown':
          evt.preventDefault();
          this.onArrowDown();
          break;
        case 'ArrowUp':
          evt.preventDefault();
          this.onArrowUp();
          break;
        case 'Escape':
          this.onEsc();
          break;
      }
    },
    onSelect() {
      if (this.arrowCounter === -1) {
        this.createOption();
      } else {
        this.selectedOption = { ...this.selectableOptions[this.arrowCounter] };
      }
      this.verifyIfOptionIsValidAndEmitValue();
    },
    onEsc() {
      this.$refs.input.blur();
      this.resetArrowCounter();
    },
    onArrowDown() {
      if (this.arrowCounter < this.selectableOptions.length - 1) {
        this.arrowCounter += 1;
      }
      this.fixScrolling();
    },
    onArrowUp() {
      if (this.arrowCounter > -1) {
        this.arrowCounter -= 1;
      }
      this.fixScrolling();
    },
    resetArrowCounter() {
      this.arrowCounter = 0;
    },
    fixScrolling() {
      if (this.arrowCounter > 0) {
        this.$refs[`option_${this.arrowCounter}`][0].scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start'
        });
      }
    },
    receiveShowedOptions() {
      if (!this.showSelectableOptions) this.$refs.input.focus();
      if (this.firstShow === 0) {
        this.selectableOptions = [
          ...this.options.map((option) => {
            return {
              ...option
            };
          })
        ];
        this.firstShow = 1;
      }
      this.arrowCounter = -1;
      this.showSelectableOptions = !this.showSelectableOptions;
    },
    createOption() {
      let isEqual = false;
      this.selectableOptions.map((option) => {
        if (option.desc_produto_grupo === this.selectedOption[this.label]) {
          isEqual = true;
          this.isOptionValid = false;
        }
      });
      if (!isEqual) {
        const option = {
          [this.label]: this.selectedOption[this.label],
          [this.id]: null
        };
        this.selectableOptions.push(option);
      }
      this.verifyIfOptionIsValidAndEmitValue();
    },
    chooseOption(option, number) {
      this.showSelectableOptions = false;
      this.arrowCounter = number;
      this.selectedOption = { ...option };
      this.verifyIfOptionIsValidAndEmitValue();
    },
    verifyIfOptionIsValidAndEmitValue() {
      this.isOptionValid = true;
      this.selectableOptions.map((option) => {
        if (option.desc_produto_grupo === this.selectedOption[this.label]) {
          this.isOptionValid = false;
        }
      });
      this.selectableOptions.map((option) => {
        if (option[this.label] === this.selectedOption[this.label]) {
          if (this.arrowCounter >= 0) {
            this.$emit(this.nameEmit, this.selectedOption);
          }
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.options {
  position: relative;
  background: #fff;
  z-index: 9999999;
  margin-top: -16px;
  width: 100%;
  border-radius: 0 0 4px 4px;
  border: 1px solid #dcdde4;
  padding: 1rem 0.5rem;

  .create-option {
    width: 100%;
    padding: 4px;
    position: relative;
    top: 0;
    border: 1px solid #dcdde4;
    background: #fafafa;
    border-radius: 4px;
    color: #373c3f;
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 500;
    strong {
      margin-right: 5px;
      color: #7f8588;
    }
  }
}
.form-floating {
  display: flex;
  flex-direction: column;
}
.small-text {
  color: #7f8588;
  font-size: 12px;

  u {
    text-decoration: none;
  }
}
.fake-select {
  cursor: pointer;
  padding: 1.5rem 1.25rem 1.2rem 1.25rem;
  margin-left: auto;
  margin-right: 0;
  margin-top: -54px;
  transform: rotate(270deg);
}
.fake-select.active {
  padding: 1.7rem 1.1rem 1.2rem 1.1rem;
  margin-top: -56px;
  transform: rotate(0);
}
.form-control {
  padding-right: 2.5rem;
}
.pill {
  padding: 0.4rem;
  border-radius: 4px;
  width: max-content;
  cursor: pointer;
}
.pillActive {
  padding: 0.4rem 0;
  width: auto;
  background-color: #e9edef !important;
}
.list {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}
.inputActive {
  &:focus {
    background: rgba(226, 230, 232, 0.4);
    box-shadow: none;
  }
}
.input-select {
  border-radius: 4px 4px 0 0;
}
.form-floating {
  label {
    font-family: $font-secondary !important;
  }
}
</style>
