<template>
  <div class="input-field d-flex">
    <div class="div-icon"><em :class="'icon ' + icon"></em></div>
    <input :type="type" :value="value" :class="className" :placeholder="placeholder" />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: [String, Number, Boolean, Date],
      default: '',
    },
    icon: {
      type: String,
      default: 'bi bi-search',
    },
    className: {
      type: String,
      default: 'form-control',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  computed: {
    name() {
      return this.label.toLowerCase();
    },
    model: {
      get() {
        return this.value;
      },
      set() {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onInput(event) {
      this.$emit('input', event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-field {
  position: relative;
}
.div-icon{
  display: flex;
  position: absolute;
  top: 15px;
  left: 20px;
}
input{
  padding: 0px 45px;
}
</style>
