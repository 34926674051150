import router from '@/router';
import { mainAxios } from '@/plugins/axios';

class GeneralService {
  async getCatalog(scope = false) {
    const response = await mainAxios.get(`/catalogo?scope=${scope}&paginate=false`);
    return response.data;
  }
  async getSubCatalogs(payload) {
    const response = await mainAxios.get(`/subcatalogo-produto/${payload}/catalogo`);
    return response.data;
  }
  async getTableValue() {
    const response = await mainAxios.get(`/tabela-preco?paginate=false`);
    return response.data;
  }
  async postNewProduct(payload) {
    const response = await mainAxios.post(`/produto/gateway`, payload);
    return response.data;
  }
  async getProduct(sku) {
    const response = await mainAxios.get(`/produto/${sku}/sku`);
    return response.data;
  }
  async getProductGroup() {
    const response = await mainAxios.get(`/produto-subgrupo?paginate=false`);
    return response.data;
  }
  async getProductObservation() {
    const response = await mainAxios.get(`/observacao?paginate=false`);
    return response.data;
  }
  async getProductPrinter() {
    const response = await mainAxios.get(`/impressora`);
    return response.data;
  }
  async getPhasing() {
    const response = await mainAxios.get(`/faseamento`);
    return response.data;
  }
  async getProductCompany() {
    const response = await mainAxios.get(`/empresas?paginate=false`);
    return response.data;
  }
  async getUnit() {
    const response = await mainAxios.get(`/unidade?paginate=false&fields=nome_unid,cod_unid,ativo`);
    return response.data;
  }
  async putEditProduct(product) {
    const productId = router.app._route.params.productId;
    const response = await mainAxios.put(`/produto/gateway/${productId}`, product);
    return response.data;
  }
  async getHomeInfo() {
    const response = await mainAxios.get(`/totalizadores/home`);
    return response.data;
  }

  async getPrinters(scope = false, search = '', limit = '') {
    const config = {
      transformResponse: [
        (data) => {
          let response = JSON.parse(data);
          response.data.results = response.data.results.map((value) => {
            return {
              cod_imp: value.cod_imp,
              tipo_imp: value.tipo_imp
            };
          });
          return response;
        }
      ]
    };

    const response = await mainAxios.get(`/impressora?scope=${scope}&paginate=false&limit=${limit}&search=${search}`);
    return response.data;
  }
}

export default new GeneralService();
