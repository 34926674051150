import ClientsService from '@/services/clients.service';

export const clientsModule = {
  namespaced: true,
  state: {
    clients: {
      links: {},
      meta: {},
      results: []
    },
    clientsBlocked: {
      links: {},
      meta: {},
      results: []
    },
    queryString: '',
    url: undefined
  },
  mutations: {
    fill_clients(state, clients) {
      state.clients = clients;
    },
    fill_clients_blocked(state, clientsBlocked) {
      state.clientsBlocked = clientsBlocked;
    }
  },
  actions: {
    list: async function({ commit }) {
      let url =
        this.state.url === undefined
          ? '/cliente?page=1'
          : this.state.url;
      const response = await ClientsService.list(url + '&' + this.state.queryString);
      return response.code === 200 ? commit('fill_clients', response.data) : null;
    },
    listBlocked: async function({ commit }, url) {
      const response = await ClientsService.list(url);
      return response.code === 200 ? commit('fill_clients_blocked', response.data) : null;
    }
  },
  getters: {
    clients: (state) => state.clients,
    clientsBlocked: (state) => state.clientsBlocked
  }
};
