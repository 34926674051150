import { mainAxios } from '@/plugins/axios';

class ExchangeService {
  async list(url) {
    const response = await mainAxios.get(url);
    return response.data;
  }
  async delete(id) {
    const response = await mainAxios.delete(`/permuta/${id}`);
    return response.data;
  }
  async create(payload) {
    const response = await mainAxios.post(`/permuta`, payload);
    return response.data;
  }
  async update(id, payload) {
    const response = await mainAxios.put(`/permuta/${id}`, payload);
    return response.data;
  }
  async view(id) {
    const response = await mainAxios.get(`/permuta/${id}`);
    return response.data;
  }
}

export default new ExchangeService();
