<template>
  <input
    ref="inputRef"
    type="text"
    class="form-control"
    v-bind="$attrs"
    :value="value"
    @input="emitVal($event.target.value)"
  />
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input';

export default {
  name: 'EpCurrency',
  props: ['value', 'options'],
  methods: {
    emitValue(e) {
      this.$emit('input', e);
      this.$refs.inputRef.blur();
      this.$refs.inputRef.focus();
    },
    emitVal(event) {
      this.$emit('update:modelValue', event);
      this.$emit('blur');
    },
  },
  setup(props) {
    const { inputRef } = useCurrencyInput(props.options);
    return { inputRef };
  },
};
</script>
