const returnGenericColor = {
  methods: {
    createRandomColor(position) {
      const colors = [
        '#F38EB0',
        '#B19FDB',
        '#FFF49B',
        '#BDAAA4',
        '#84D2FF',
        '#A5D6A7',
        '#CE92D8',
        '#D3E5EF',
        '#FFE2DD',
      ];
      return colors[position];
    },
  },
};
export default returnGenericColor;
