import ScheduleService from '@/services/schedule.service';

export const schedule = {
  namespaced: true,
  state: {
    taxes: [],
    company: {},
    schedules: [],
    entrance: [],
  },
  mutations: {
    FILL_EVENTS(state, events) {
      state.events = events;
    },
    FILL_TAXES(state, taxes) {
      state.taxes = taxes;
    },
    FILL_SCHEDULES(state, schedules) {
      state.schedules = schedules;
    },
    FILL_ENTRANCE(state, entrance) {
      state.entrance = entrance;
    }
  },
  actions: {
    async getSchedule({ commit }, search = '') {
      const response = await ScheduleService.getSchedule(search);
      return response.code === 200 ? commit('FILL_SCHEDULES', response.data.results) : null;
    },
    async getTaxes({ commit }, type = '') {
      const response = await ScheduleService.getServices(type);
      return response.code === 200 ? commit('FILL_TAXES', response.data.results) : null;
    },
    async getEntrance({ commit }, type = '') {
      const response = await ScheduleService.getServices(type);
      return response.code === 200 ? commit('FILL_ENTRANCE', response.data.results) : null;
    },
  },
  getters: {
    schedules: (state) => state.schedules,
    taxes: (state) => state.taxes,
    entrance: (state) => state.entrance
  }
};
