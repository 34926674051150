import router from '@/router';
import DirectPrintService from "@/services/directPrint.service";

export const directPrint = {
  namespaced: true,
  state: {
    directPrints: {
      links: {},
      meta: {},
      results: [],
    },
    directPrint: {},
    machines: {
      results: []
    },
    printers: {
      results: []
    },
    kds: {
      results: []
    },
    newDirectPrint: {},
    orderSelected: 'desc',
    companies: {
      results: []
    }
  },
  mutations: {
    FILL_DIRECTS(state, directPrints) {
      state.directPrints = directPrints;
    },
    FILL_MACHINES(state, machines) {
      state.machines = machines;
    },
    FILL_PRINTERS(state, printers) {
      state.printers = printers;
    },
    FILL_KDS(state, kds) {
      state.kds = kds;
    },
    NEW_DIRECT_PRINT(state, newDirectPrint) {
      state.newDirectPrint = newDirectPrint;
    },
    FILL_DIRECT_PRINT(state, directPrint) {
      state.directPrint = directPrint;
    },
    FILL_COMPANIES(state, companies) {
      state.companies = companies;
    },
  },
  actions: {
    async getDirectPrints({ commit }, search) {
      const response = await DirectPrintService.getList(search);
      response.code === 200 ? commit('FILL_DIRECTS', response.data) : null;
    },
    async changePage({ commit }, payload) {
      if (payload.url) {
        const response = await DirectPrintService.getListByUrl(
          payload.url,
          this.state.directPrint.orderSelected
        );
        response.code === 200 ? commit('FILL_DIRECTS', response.data) : null;
      }
    },
    async getPerPage({ commit }, term) {
      const response = await DirectPrintService.getPerPage(
        term,
        this.state.directPrint.orderSelected
      );
      response.code === 200 ? commit('FILL_DIRECTS', response.data) : null;
    },
    async removeDirectPrint({ commit }, id) {
      return DirectPrintService.delete(id)
        .then(() => {
          Toast.fire('Direcionamento de impressão removido com sucesso!', '', 'success');
        })
        .catch((error) => {
          Toast.fire(
            'Erro ao remover Direcionamento de impressão! <br> Por favor, tente novamente.',
            '',
            'error'
          );
        });
    },
    async getListMachines({ commit }, codEmp = null) {
      const response = await DirectPrintService.getListMachines(codEmp);
      response.code === 200 ? commit('FILL_MACHINES', response.data) : null;
    },
    async getListPrinters({ commit }, codEmp = null) {
      const response = await DirectPrintService.getListPrinters(codEmp);
      response.code === 200 ? commit('FILL_PRINTERS', response.data) : null;
    },
    async getListKds({ commit }, codEmp = null) {
      const response = await DirectPrintService.getListKds(codEmp);
      response.code === 200 ? commit('FILL_KDS', response.data) : null;
    },
    async createDirectPrint({ commit }, payload) {
      return DirectPrintService.createDirectPrint(payload)
        .then((response) => {
          commit('NEW_DIRECT_PRINT', response.data);
          Toast.fire('Direcionamento de impressão cadastrado com sucesso!', '', 'success');
          router.push('/direct-print');
        })
        .catch((error) => {
          Toast.fire({
            title: 'Atenção',
            html: `
            Erro ao cadastrar Direcionamento de impressão! Por favor, tente novamente.<br>
            `,
            icon: 'error',
            width: 600,
            showCloseButton: true,
            timerProgressBar: false,
          });
          throw error;
        });
    },
    async editDirectPrint({ commit }, id, payload) {
      return DirectPrintService.editDirectPrint(id, payload).then((response) => {
        commit('NEW_DIRECT_PRINT', response.data);
        Toast.fire('Direcionamento de impressão editado com sucesso!', '', 'success');
        router.push('/direct-print');
      })
        .catch((error) => {
          Toast.fire({
            title: 'Atenção',
            html: `
          Erro ao editar Direcionamento de impressão! Por favor, tente novamente.<br>
          `,
            icon: 'error',
            width: 600,
            showCloseButton: true,
            timerProgressBar: false,
          });
          throw error;
        });
    },
    async getCompanies({ commit }) {
      const response = await DirectPrintService.getCompanies();
      response.code === 200 ? commit('FILL_COMPANIES', response.data) : null;
    },
    async getDirectPrint({ commit }, id) {
      const response = await DirectPrintService.getDirectPrintById(id);
      return response.code === 200 ? commit('FILL_DIRECT_PRINT', response.data.results) : null;
    },
    async deleteCompany({ commit }, id) {
      return DirectPrintService.deleteCompany(id);
    }
  },
  getters: {
    directPrints: (state) => state.directPrints,
    machines: (state) => state.machines,
    printers: (state) => state.printers,
    kds: (state) => state.kds,
  }
}

