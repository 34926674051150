<template>
  <div class="w-100 d-flex justify-content-center mt-4 position-relative">
    <nav>
      <ul class="pagination">
        <li class="page-item">
          <a class="page-link" href="#" aria-label="Previous">
            <em class="bi bi-chevron-left"></em>
          </a>
        </li>
        <li class="page-item"><a class="page-link" href="#">1</a></li>
        <li class="page-item"><a class="page-link" href="#">2</a></li>
        <li class="page-item"><a class="page-link" href="#">3</a></li>
        <li class="page-item"><a class="page-link" href="#">4</a></li>
        <li class="page-item"><a class="page-link" href="#">5</a></li>
        <li class="page-item">
          <a class="page-link" href="#" aria-label="Next">
            <em class="bi bi-chevron-right"></em>
          </a>
        </li>
      </ul>
    </nav>

    <div
      class="box-result position-absolute d-flex align-items-center justify-content-between"
    >
      <p class="text-result">Resultados: 1 - 10 de 1254</p>
      <select class="form-select">
        <option value="1">10</option>
        <option value="2">20</option>
        <option value="3">30</option>
      </select>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pagination {
  .page-item {
    &:first-child,
    &:last-child {
      .page-link {
        background: transparent;
        &:hover {
          color: #2d3032;
        }
      }
    }
  }
  .page-link {
    border: 0;
    padding: 2px 11px;
    width: 32px;
    height: 32px;
    background: rgba(226, 230, 232, 0.4);
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    color: #4b5053;
    margin: 4px;
    line-height: 2;
    &:hover {
      background: #373c3f;
      color: #fff;
    }
  }
}
.box-result {
  width: 280px;
  right: 0;
  p {
    font-size: 12px;
    font-weight: 600;
  }
  .form-select {
    width: 90px;
    height: 42px;
    border: 1px solid #c0c5c8;
    font-family: $font-secondary;
    font-size: 16px;
    font-weight: 700;
    color: #4b5053;
    background: #fff;
  }
}
</style>

<script>
export default {
  name: 'EpPagination',
};
</script>
