import ReasonReversalService from "@/services/reasonReversal.service";

export const reasons = {
  namespaced: true,
  state: {
    reason: {},
    reasons: {
      links: {},
      meta: {},
      results: [],
    },
    orderSelected: 'asc',
    newReason: null
  },
  mutations: {
    FILL_REASONS(state, reasons) {
      state.reasons = reasons;
    },
    FILL_NEW_REASON(state, newReason) {
      state.newReason = newReason;
    },
    FILL_REASON(state, reason) {
      state.reason = reason;
    },
    DELETE_REASON(state, reason) {
      state.reason = reason;
    }
  },
  actions: {
    async changePage({ commit }, payload) {
      if (payload.url) {
        const response = await ReasonReversalService.getReasonsByUrl(
          payload.url,
          this.state.reason.orderSelected
        );
        response.code === 200 ? commit('FILL_REASONS', response.data) : null;
      }
    },
    async getPerPage({ commit }, term) {
      const response = await ReasonReversalService.getPerPage(term, this.state.reason.orderSelected);
      response.code === 200 ? commit('FILL_REASONS', response.data) : null;
    },
    async getReasons({ commit }, search) {
      const response = await ReasonReversalService.getReasons(search);
      response.code === 200 ? commit('FILL_REASONS', response.data) : null;
    },
    async createReason({ commit }, payload) {
      return ReasonReversalService.createReason(payload).then((response) => {
        commit('FILL_NEW_REASON', response.data);
        Toast.fire('Motivo de estorno cadastrado com sucesso!', '', 'success');
      }).catch((error) => {
        Toast.fire({
          title: 'Atenção',
          html: `
        Erro ao cadastrar Motivo de estorno! Por favor, tente novamente.<br>
        <span style="font-size: 12px">- ${error.response.data.message}</span>
        `,
          icon: 'error',
          width: 600,
          showCloseButton: true,
          timerProgressBar: false,
        });
        throw error.response.data;
      });
    },
    async deleteReason({ commit }, id) {
      return ReasonReversalService.deleteReason(id).then(() => {
        Toast.fire('Motivo de estorno removido com sucesso!', '', 'success');
      }).catch((error) => {
        Toast.fire('Erro ao remover Motivo de estorno, por favor tente novamente!', '', 'error');
      });
    },
    async getReason({ commit }, id) {
      const response = await ReasonReversalService.getReasonById(id);
      return response.code === 200 ? commit('FILL_REASON', response.data.results) : null;
    },
    async clearReason({ commit }) {
      commit('FILL_REASON', {});
    },
    async changeStatus({ commit }, { id, payload }) {
      return ReasonReversalService.editStatus(id, payload).then(() => {
        Toast.fire('Status atualizado com sucesso!', '', 'success');
      }).catch((error) => {
        Toast.fire('Erro ao atualizar Status!', '', 'error');
      });
    },
    async editReason({ commit }, { id, payload }) {
      return ReasonReversalService.editReason(id, payload).then(() => {
        Toast.fire('Motivo de estorno atualizado com sucesso!', '', 'success');
      }).catch((error) => {
        Toast.fire('Erro ao atualizar Motivo de estorno!', '', 'error');
      });
    }
  },
  getters: {
    reasons: (state) => state.reasons,
    reason: (state) => state.reason,
  },
}
