<template>
  <div>
    <nav
      class="breadcrumb-nav"
      style="--bs-breadcrumb-divider: '>'"
      aria-label="breadcrumb"
    >
      <ol class="breadcrumb">
        <li v-for="item in items" class="breadcrumb-item" :key="item">
          {{ item }}
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'EpBreadcrumb',
};
</script>

<style lang="scss" scoped>
.breadcrumb-nav {
  --bs-breadcrumb-divider: '>';
  .breadcrumb {
    .breadcrumb-item {
      font-size: 14px;
      color: #5e666a;
      &:last-child {
        a {
          text-decoration: none;
        }
      }
    }
  }
}
</style>
