<template>
  <div class="sidemenu">
    <div class="logo">
      <img src="@/assets/img/frame.svg" width="36px" height="36px" alt="Descrição" />
    </div>
    <div class="menu-list">
      <ul class="nav flex-column">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="/home" data-bs-toggle="tooltip" data-bs-placement="bottom"
            data-bs-custom-class="custom-tooltip"><img src="@/assets/img/home.svg" alt="" /></a>
        </li>

        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="javascript:" data-bs-toggle="tooltip"
            data-bs-placement="bottom" data-bs-custom-class="custom-tooltip" @click="handlerShowMenu(4)"><img
              src="../../assets/img/dashboard.svg" alt="" /></a>
          <ul class="submenu" id="open-submenu" v-if="showMenu[4].position == 4 && showMenu[4].active"
            @mouseleave="hiddenMenu()">
            <li>
              <h1 class="mt-4 submenu-title w-75">Dashboard</h1>
            </li>
            <li>
              <a :href="'/acompanhamento-casa'">Acompanhamento Lojas</a>
            </li>
            <li>
              <a :href="'/descontos'">Descontos</a>
            </li>
            <li>
              <a :href="'/estornos'">Estornos</a>
            </li>
            <li>
              <a :href="'/faturamento'">Faturamento</a>
            </li>
            <li>
              <a :href="'/fechamento-caixa'">Fechamento de caixa</a>
            </li>
            <li>
              <a :href="'/faturamento-canal'">Faturamento por canal</a>
            </li>
            <li>
              <a :href="'/fidelity'">Fidelidade</a>
            </li>
            <li>
              <a :href="'/livro-fiscal-icms'">Livro Fiscal - ICMS</a>
            </li>
            <li>
              <a :href="'/livro-fiscal-iss'">Livro Fiscal - ISS</a>
            </li>
            <li>
              <a :href="'/mailing'">Mailing</a>
            </li>
            <li>
              <a :href="'/operacional'">Operacional</a>
            </li>
            <li>
              <a :href="'/sales-analytical'">Vendas analítico</a>
            </li>
            <li>
              <a :href="'/vendas-delivery'">Vendas em delivery</a>
            </li>
            <li>
              <a :href="'/venda-produto'">Venda de produtos</a>
            </li>
            <li>
              <a :href="'/venda-servico'">Venda de serviços</a>
            </li>
            <li>
              <a :href="'/vendas-funcionario'">Vendas por funcionário</a>
            </li>
          </ul>
        </li>

        <li class="nav-item" v-for="(item, index) in menuList" :key="index">
          <a class="nav-link active" aria-current="page" href="javascript:" data-bs-toggle="tooltip"
            data-bs-placement="bottom" data-bs-custom-class="custom-tooltip" @click="handlerShowMenu(0)"><img
              src="@/assets/img/shopping-cart.svg" alt="" /></a>
          <ul class="submenu" id="open-submenu" v-if="showMenu[0].position == 0 && showMenu[0].active"
            @mouseleave="hiddenMenu()">
            <li>
              <h1 class="mt-4 submenu-title w-75">
                {{ item.title }}
              </h1>
            </li>
            <li v-for="(submenu, idx) in item.submenu.data" :key="idx">
              <a :href="submenu.href">{{ submenu.title }}</a>
            </li>
          </ul>
        </li>
        <!-- Gerenciar PDV -->
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="javascript:" data-bs-toggle="tooltip"
            data-bs-placement="bottom" data-bs-custom-class="custom-tooltip" @click="handlerShowMenu(1)"><img
              src="@/assets/img/resturant.svg" alt="" /></a>
          <ul class="submenu" id="open-submenu" v-if="showMenu[1].position == 1 && showMenu[1].active"
            @mouseleave="hiddenMenu()">
            <li>
              <h1 class="mt-4 submenu-title w-75">Gerenciar PDV</h1>
            </li>
            <li>
              <a :href="'/catalog'">Catálogo de Produtos</a>
              <a :href="'/sales'">Tela de Vendas</a>
            </li>
          </ul>
        </li>
        <!-- Compras e estoque -->
        <li class="nav-item d-none">
          <a class="nav-link active" aria-current="page" href="javascript:" data-bs-toggle="tooltip"
            data-bs-placement="bottom" data-bs-custom-class="custom-tooltip" @click="handlerShowMenu(2)"><img
              src="@/assets/img/box.svg" alt="" /></a>
          <ul class="submenu" id="open-submenu" v-if="showMenu[2].position == 2 && showMenu[2].active"
            @mouseleave="hiddenMenu()">
            <li>
              <h1 class="mt-4 submenu-title w-75">Compras e<br />Estoque</h1>
            </li>
            <li>
              <a :href="'/product-group'">Grupo de Produtos</a>
            </li>
          </ul>
        </li>
        <!-- Empresa -->
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="javascript:" data-bs-toggle="tooltip"
            data-bs-placement="bottom" data-bs-custom-class="custom-tooltip" @click="handlerShowMenu(3)"><img
              src="../../assets/img/company.svg" alt="" /></a>
          <ul class="submenu" id="open-submenu" v-if="showMenu[3].position == 3 && showMenu[3].active"
            @mouseleave="hiddenMenu()">
            <li>
              <h1 class="mt-4 submenu-title w-75">Empresas</h1>
            </li>
            <li>
              <a :href="'/employees'">Cadastro de funcionários</a>
              <a :href="'/reversal-reason'">Motivos de estorno</a>
              <a :href="'/discounts-surcharges'">Descontos e Acréscimos</a>
              <a :href="'/payment-methods'">Formas de pagamentos</a>
              <a :href="'/entry-category'">Categoria de entrada</a>
            </li>
          </ul>
        </li>
        <!-- CRM -->
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="javascript:" data-bs-toggle="tooltip"
            data-bs-placement="bottom" data-bs-custom-class="custom-tooltip" @click="handlerShowMenu(6)">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clip-path="url(#clip0_622_111)">
                <path d="M5.93103 6.93604L7.20603 11.185M12.813 16.794L17.064 18.069" stroke="#FFFFFF" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.683 12.317L17.442 6.55798" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M5.5 7C6.32843 7 7 6.32843 7 5.5C7 4.67157 6.32843 4 5.5 4C4.67157 4 4 4.67157 4 5.5C4 6.32843 4.67157 7 5.5 7Z"
                  stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M18.5 7C19.3284 7 20 6.32843 20 5.5C20 4.67157 19.3284 4 18.5 4C17.6716 4 17 4.67157 17 5.5C17 6.32843 17.6716 7 18.5 7Z"
                  stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M18.5 20C19.3284 20 20 19.3284 20 18.5C20 17.6716 19.3284 17 18.5 17C17.6716 17 17 17.6716 17 18.5C17 19.3284 17.6716 20 18.5 20Z"
                  stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M8.5 20C10.9853 20 13 17.9853 13 15.5C13 13.0147 10.9853 11 8.5 11C6.01472 11 4 13.0147 4 15.5C4 17.9853 6.01472 20 8.5 20Z"
                  stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_622_111">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
          <ul class="submenu" id="open-submenu" v-if="showMenu[6].position == 6 && showMenu[6].active"
            @mouseleave="hiddenMenu()">
            <li>
              <h1 class="mt-4 submenu-title w-75">CRM</h1>
            </li>
            <li>
              <a :href="'/blocked-clients'">Clientes bloqueados</a>
            </li>
            <li>
              <a :href="'/registered-customers'">Clientes cadastrados</a>
            </li>
            <li>
              <a :href="'/manage-schedule'">Gerenciar agenda</a>
            </li>
            <li class="d-none">
              <a :href="'/manage-lists'">Gerenciar listas</a>
            </li>
            <li>
              <a :href="'/exchange'">Gerenciar permutas</a>
            </li>
          </ul>
        </li>
        <!-- Configurações -->
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="javascript:" data-bs-toggle="tooltip"
            data-bs-placement="bottom" data-bs-custom-class="custom-tooltip" @click="handlerShowMenu(5)"><img
              src="../../assets/img/settings.svg" alt="" /></a>
          <ul class="submenu" id="open-submenu" v-if="showMenu[5].position == 5 && showMenu[5].active"
            @mouseleave="hiddenMenu()">
            <li>
              <h1 class="mt-4 submenu-title w-75">Configurações</h1>
            </li>
            <li>
              <a :href="'/category-icms'">Categorias de ICMS e ISS</a>
            </li>
            <li>
              <a :href="'/category-pis-cofins'">Categorias de PIS e Cofins</a>
            </li>
            <li>
              <a :href="'/equipment'">Configurar Equipamentos</a>
            </li>
            <li>
              <a :href="'/tax-rules'">Configurar Regras Fiscais</a>
            </li>
            <li>
              <a :href="'/service'">Configurar Serviços</a>
            </li>
<!--            <li>-->
<!--              <a :href="'/advanced-configurations'">Configurações avançadas</a>-->
<!--            </li>-->
            <li>
              <a :href="'/direct-print'">Direcionar Impressão</a>
            </li>
          </ul>
        </li>

        <li class="nav-item mt-4">
          <a href="javascript:" class="nav-link" @click="logout">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clip-path="url(#clip0_6952_6859)">
                <path
                  d="M14 8V6C14 5.46957 13.7893 4.96086 13.4142 4.58579C13.0391 4.21071 12.5304 4 12 4H5C4.46957 4 3.96086 4.21071 3.58579 4.58579C3.21071 4.96086 3 5.46957 3 6V18C3 18.5304 3.21071 19.0391 3.58579 19.4142C3.96086 19.7893 4.46957 20 5 20H12C12.5304 20 13.0391 19.7893 13.4142 19.4142C13.7893 19.0391 14 18.5304 14 18V16"
                  stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9 12H21L18 9" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M18 15L21 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_6952_6859">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </li>
      </ul>
    </div>
    <div class="logo-epoc">
      <span><img src="@/assets/img/erp.svg" alt="" /></span>
    </div>

    <button type="button" class="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModalUnder">
      Launch demo modal
    </button>

    <!-- Modal -->
    <div class="modal fade" id="exampleModalUnder" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered under-construction">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <img src="@/assets/img/underconstruction.svg" alt="" />
            <h1>Opss, essa funcionalidade ainda não está disponível</h1>
            <p class="mb-0">
              Estamos em constante melhoria do nosso produto, em breve ela
              estará disponível
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMenu: [
        {
          position: 0,
          active: false,
        },
        {
          position: 1,
          active: false,
        },
        {
          position: 2,
          active: false,
        },
        {
          position: 3,
          active: false,
        },
        {
          position: 4,
          active: false,
        },
        {
          position: 5,
          active: false,
        },
        {
          position: 6,
          active: false,
        },
      ],

      menuList: [
        {
          title: 'Gestão de Produtos',
          icon: 'shopping-cart',
          submenu: {
            data: [
              {
                title: 'Painel de Produtos',
                href: '/products',
              },
              {
                title: 'Tabela de Preços',
                href: '/price-table',
              },
              {
                title: 'Observações de Produtos',
                href: '/product-observation',
              },
              {
                title: 'Grupo de Produtos',
                href: '/product-group',
              },
              {
                title: 'Tabela de fracionamento',
                href: '/fraction-table',
              },
            ],
          },
        },
      ],
    };
  },
  mounted() {
    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    const tooltipList = [...tooltipTriggerList].map(
      (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl)
    );
  },
  methods: {
    handlerShowMenu(index) {
      this.showMenu.map((menu) => (menu.active = false));
      this.showMenu[index].active = true;
    },
    hiddenMenu() {
      this.showMenu.map((menu) => (menu.active = false));
    },
    logout() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/auth');
    },
  },
};
</script>

<style lang="scss">
.sidemenu {
  background-color: #181a1b;
  height: 100vh;
  width: 60px;
  display: grid;
  z-index: 9999;
  position: fixed;

  .menu-list {
    .nav {
      width: 100%;
      gap: 25px;
      align-items: center;
    }
  }
}

.logo {
  width: 45px;
  height: 70px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border-bottom: 1px solid #4b5053;

  img {
    margin-top: 20px;
  }
}

.logo-epoc {
  display: flex;
  justify-content: center;
}

.submenu {
  position: absolute;
  margin-left: 52px;
  background-color: #212425;
  top: 0px;
  height: 100%;
  width: 230px;
  z-index: 1;
  padding: 0;

  .submenu-title {
    font-weight: 700;
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 24px;
    padding-left: 16px;
    font-family: $font-secondary;
  }

  li {
    a {
      height: 50px;
      line-height: 50px;
      padding-left: 16px;
      text-decoration: none;
      font-weight: 400;
      font-size: 12px;
      color: #ffffff;
      font-family: $font-secondary;
      display: block;

      &:hover {
        background: #2d3032;
      }
    }
  }
}

.custom-tooltip {
  --bs-tooltip-bg: #2f2f2f;
  --bs-tooltip-opacity: 1;
  --bs-tooltip-padding-x: 8px;
  --bs-tooltip-padding-y: 8px;
  --bs-tooltip-border-radius: 4px;
  @include rfs(14px, --bs-tooltip-font-size);
}
</style>
