import { authAxios, userAxios, mainAxios } from '@/plugins/axios';

class AuthService {
  async login(logged) {
    try {
      const response = await authAxios.post('/login', {
        email: logged.email,
        password: logged.password
      });
      const userData = await userAxios.get(`/me`, {
        headers: {
          'x-grupo': response.data['x-grupo'],
          Authorization: `Bearer ${response.data.access_token}`
        }
      });
      const totalData = await mainAxios.get(`/totalizadores/home`, {
        headers: {
          'x-grupo': response.data['x-grupo'],
          Authorization: `Bearer ${response.data.access_token}`
        }
      });
      sessionStorage.setItem('logged', JSON.stringify(response.data));
      sessionStorage.setItem('userData', JSON.stringify(userData.data));
      sessionStorage.setItem('totalData', JSON.stringify(totalData.data));

      localStorage.setItem('logged', JSON.stringify(response.data));
      localStorage.setItem('userData', JSON.stringify(userData.data));
      localStorage.setItem('totalData', JSON.stringify(totalData.data));

      return response.data;
    } catch (error) {
      throw new error();
    }
  }

  async resetPassword(payload) {
    const response = await authAxios.post('/reset-password', payload);
    return response.data;
  }

  async requestPasswordToken(email) {
    const response = await authAxios.post('/reset-password-request', {
      email: email
    });
    return response.data;
  }

  async confirmPassword(password) {
    let logged = JSON.parse(sessionStorage.getItem('userData'));
    const response = await authAxios.post('/login?referer=lake', {
      email: logged.email,
      password: password
    });
    return response.data;
  }

  logout() {
    sessionStorage.clear();
  }
}

export default new AuthService();
