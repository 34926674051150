import DiscountsService from "@/services/discounts.service";

export const discounts = {
  namespaced: true,
  state: {
    discounts: {
      links: {},
      meta: {},
      results: [],
    },
    newDiscount: {},
    orderSelected: 'asc',
    discount: {},
  },
  mutations: {
    FILL_DISCOUNTS(state, discounts) {
      state.discounts = discounts;
    },
    FILL_NEW_DISCOUNT(state, newDiscount) {
      state.newDiscount = newDiscount;
    },
    DELETE_DISCOUNTS_SURCHARGES(state, discountsSurcharges) {
      state.discountsSurcharges = discountsSurcharges;
    },
    FILL_DISCOUNT(state, discount) {
      state.discount = discount;
    },
    DELETE_DISCOUNT(state, discount) {
      state.discount = discount;
    },

  },
  actions: {
    async getDiscounts({ commit }, search) {
      const discounts = await DiscountsService.getDiscounts(search);
      discounts.code === 200 ? commit('FILL_DISCOUNTS', discounts.data) : null;
    },
    async changePage({ commit }, payload) {
      if (payload.url) {
        const discounts = await DiscountsService.getDiscountsByUrl(
          payload.url,
          this.state.discounts.orderSelected
        );
        discounts.code === 200 ? commit('FILL_DISCOUNTS', discounts.data) : null;
      }
    },
    async getPerPage({ commit }, term) {
      const discounts = await DiscountsService.getPerPage(term, this.state.discounts.orderSelected);
      discounts.code === 200 ? commit('FILL_DISCOUNTS', discounts.data) : null;
    },
    async createDiscount({ commit }, payload) {
      return DiscountsService.createdDiscount(payload).then((response) => {
        commit('FILL_NEW_DISCOUNT', response.data);
        Toast.fire('Desconto ou acréscimo cadastrado com sucesso!', '', 'success');
      }).catch((error) => {
        Toast.fire({
          title: 'Atenção',
          html: `Erro ao cadastrar Descontos e Acréscimos! <br> Por favor, tente novamente.`,
          icon: 'error',
          width: 600,
          showCloseButton: true,
          timerProgressBar: false,
        });
        throw error.response.data;
      });
    },
    async editDiscount({ commit }, { id, payload }) {
      return DiscountsService.editDiscount(id, payload)
        .then(() => {
          Toast.fire('Desconto ou acréscimo atualizado com sucesso!', '', 'sucess');
        })
        .catch((error) => {
          Toast.fire('Erro ao atualizar desconto ou acréscimo!', '', 'error');
        });
    },
    async getDiscount({ commit }, id) {
      const response = await DiscountsService.getDiscountById(id);
      return response.code === 200 ? commit('FILL_DISCOUNT', response.data.results) : null;
    },
    async deleteDiscount({ commit }, id) {
      return DiscountsService.deleteDiscount(id).then(() => {
        Toast.fire('Desconto ou acréscimo removido com sucesso!', '', 'success');
      })
        .catch((error) => {
          Toast.fire('Erro ao remover Desconto ou acréscimo! <br> Por favor, tente novamente.', '', 'error')
        });
    },
    async changeStatus({ commit }, { id, payload }) {
      return DiscountsService.editStatus(id, payload).then(() => {
        Toast.fire('Status atualizado com sucesso!', '', 'success');
      }).catch((error) => {
        Toast.fire('Erro ao atualizar Status!', '', 'error');
      });
    },
    async clearDiscount({ commit }) {
      commit('FILL_DISCOUNT', {});
    },
  },

  getters: {
    discounts: (state) => state.discounts,
    discount: (state) => state.discount,
  }
};
