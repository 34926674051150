import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.css';

import * as bootstrap from 'bootstrap';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css';
import money from 'v-money';
import VeeValidate from 'vee-validate';
import msgBR from 'vee-validate/dist/locale/pt_BR';
import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';
import App from './App.vue';
import './assets/scss/app.scss';
import router from './router';
import store from './store';

const DocumentPersonBrFilter = {
  install(Vue, options) {
    Vue.filter('DocumentPersonBr', (documentId, documentType) => {
      documentId =
        typeof documentId != 'string' ? documentId.toString() : documentId;
      if (documentType == 'J') {
        documentId = documentId.padStart(14, '0');
        documentId = documentId.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          '$1.$2.$3/$4-$5'
        );
      } else {
        documentId = documentId.padStart(11, '0');
        documentId = documentId.replace(
          /^(\d{3})(\d{3})(\d{3})(\d{2})/,
          '$1.$2.$3-$4'
        );
      }
      return documentId;
    });
  }
};
Vue.use(DocumentPersonBrFilter);

Vue.config.productionTip = false;

const requireComponent = require.context(
  '@/components/global',
  true,
  /Ep[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach((fileName) => {
  // Pegando configuração do componente
  const componentConfig = requireComponent(fileName);

  // Transformando o nome do componente para PascalCase
  const componentName = upperFirst(
    camelCase(
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  );

  // Registrando componente globalmente
  Vue.component(componentName, componentConfig.default || componentConfig);
});

window.bootstrap = bootstrap;

Vue.use(VeeValidate, {
  classes: true,
  fastExit: true
});
VeeValidate.Validator.localize('pt_BR', msgBR);
VeeValidate.Validator.localize({
  pt_BR: {
    messages: {
      required: 'Campo obrigatório, favor preencher.',
      min_value: (e, n) => 'Valor Mín. ' + n[0],
      max_value: (e, n) => 'Valor Máx. ' + n[0],
      max: (e, n) => 'Máx. ' + n[0] + ' caracteres.',
      min: (e, n) => 'Mín. ' + n[0] + ' caracteres.',
      numeric: (e, n) => 'Apenas números.',
      digits: (e, n) => 'Apenas números, e 8 caracteres.'
    }
  }
});

Vue.use(VueSweetalert2);

//configuração do Toast
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  timer: 5000,
  timerProgressBar: true,
  showConfirmButton: false
});
//salva a configuração global na palavra "Toast"
window.Toast = Toast;
Vue.use(money, {
  decimal: ',',
  thousands: '.',
  prefix: 'R$ ',
  suffix: '',
  precision: 2,
  masked: false
});

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
