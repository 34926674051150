var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'vh-100': _vm.$route.name === 'import-products-item' },style:([
    { 'max-height': _vm.$route.name === 'import-products-item' ? '15%' : '' },
    {
      'margin-bottom':
        _vm.$route.name === 'import-products-item' ? '-10px!important' : ''
    }
  ])},[_c('h1',{staticClass:"title text-start"},[_vm._v(_vm._s(_vm.title))]),_c('nav',{staticClass:"breadcrumb-nav",staticStyle:{"--bs-breadcrumb-divider":"'>'"},attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},_vm._l((_vm.items),function(item){return _c('li',{key:item.name,staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":item.path}},[_vm._v(_vm._s(item.name))])],1)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }