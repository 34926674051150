import router from '@/router';
import productService from '@/services/product.service';

export const product = {
  namespaced: true,
  state: {
    products: {
      links: {},
      meta: {},
      results: []
    },
    editedProduct: {},
    queryString: undefined,
    url: undefined
  },
  mutations: {
    FILL_PRODUCTS(state, products) {
      state.products = products;
    },
    EDIT_PRODUCT(state, product) {
      state.editedProduct = product;
    },
    REMOVE_PRODUCT(state, product) {
      state.products = product;
    }
  },
  actions: {
    async getProducts({ commit }) {
      let url =
        this.state.url === undefined
          ? '/produto/gateway?page=1'
          : this.state.url;
      const products = await productService.getListProducts(
        url + '&' + this.state.queryString
      );
      products.code === 200 ? commit('FILL_PRODUCTS', products.data) : null;
    },
    async deleteProduct({ commit }, id) {
      // let products = { ...this.state.product.products };
      // products.results = products.results.filter(
      //   (product) => product.cod_prod !== id
      // );
      // const deleteProd = await productService.removeProduct(id);
      // deleteProd.code === 200 ? commit('REMOVE_PRODUCT', products) : null;
    },
    async editProduct({ commit }, productId) {
      const editProd = await productService.getProductById(productId);
      editProd.code === 200
        ? commit('EDIT_PRODUCT', editProd.data.results)
        : null;
    }
  },
  getters: {
    products: (state) => state.products,
    url: (state) => state.url,
    queryString: (state) => state.queryString
  }
};
