import { userAxios } from '@/plugins/axios';
import { mainAxios } from '@/plugins/axios';

class UserService {
  async getUser() {
    return await userAxios.get(`/me`);
  }
  async getUsers(columns, perPage, paginate, pageUrl = '/usuarios?page=1') {
    return await mainAxios.get(
      `${pageUrl}&columns=${columns}&per_page=${perPage}&paginate=${paginate}`
    );
  }
}

export default new UserService();
