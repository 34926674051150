import generalService from "@/services/general.service";

export const home = {
  namespaced: true,
  state: {
    total: {}
  },
  mutations: {
    FILL_DATA(state, total) {
      state.total = total;
    }
  },
  actions: {
    async getData({ commit }) {
      let response = await generalService.getHomeInfo();
      response.code === 200 ? commit('FILL_DATA', response.data) : null;
    }
  },
  getters: {
    total: (state) => state.total,
  },
};
