var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vh-100 table-responsive position-sticky",staticStyle:{"max-height":"90% !important"},attrs:{"id":"table-stick"}},[(_vm.loading)?_c('div',{staticClass:"box-loading vh-100 align-middle justify-content-center text-center"},[_vm._m(0)]):_vm._e(),_c('table',{class:[
      'table',
      {
        'caption-top': _vm.paginateScroll
      }
    ]},[_c('thead',{staticClass:"sticky-top top-0 border"},[_c('tr',_vm._l((_vm.columns),function(column,i){return _c('th',{key:column.field,class:[
            column.classThead,
            {
              'table-danger border border-danger':
                _vm.columnIsInvalid[i] && !_vm.columnIsInvalidCss[i]
            },
            _vm.columnIsInvalidCss[i]
          ],style:(column.initialTheadStyle),attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(column.title)+" ")])}),0)]),(!!_vm.rows.length && !_vm.loading)?_c('tbody',_vm._l((_vm.rows),function(row,k){return _c('tr',{key:k},_vm._l((row),function(v,i){return _c('td',{key:`${k}:${i}`,class:[
            'p-0 m-0 position-relative',
            v.classRow,
            {
              'table-active': _vm.inputBind === `${v.rowId}:${v.columnId}`,
              'align-middle':
                _vm.inputBind !== `${v.rowId}:${v.columnId}` ||
                _vm.columns[v.columnId].inputType === 'checkbox'
            },
            {
              'table-danger border-danger':
                !v.isValid && !_vm.columnIsInvalidCss[v.columnId]
            },
            _vm.columnIsInvalidCss[v.columnId] && !v.isValid
              ? _vm.columnIsInvalidCss[v.columnId]
              : ''
          ],on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"]))return null;if('button' in $event && $event.button !== 2)return null;$event.preventDefault();return _vm.nextCell(v)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if('button' in $event && $event.button !== 0)return null;$event.preventDefault();return _vm.prevCell(v)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;$event.preventDefault();return _vm.nextRow(v, v.columnId)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;$event.preventDefault();return _vm.prevRow(v, v.columnId)}]}},[(_vm.inputBind === `${v.rowId}:${v.columnId}`)?_c('input',{ref:"spell",refInFor:true,staticClass:"form-control input-table",staticStyle:{"padding":"0 !important","margin":"0 !important","width":"0 !important","height":"0 !important"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {
                _vm.mode = 'writing';
                _vm.changeMode();
              }).apply(null, arguments)}}}):_vm._e(),(
              _vm.columns[v.columnId].inputType !== 'checkbox' &&
              _vm.inputBind !== `${v.rowId}:${v.columnId}`
            )?_c('div',{class:['px-2 py-3 cell-label', _vm.columns[v.columnId].inputClass],style:(['display: block;', _vm.columns[v.columnId].initialStyle]),on:{"click":function($event){[
                (_vm.inputBind = `${v.rowId}:${v.columnId}`),
                (_vm.model = v),
                _vm.mode === 'readonly' ? (_vm.mode = 'writing') : (_vm.mode = 'readonly')
              ]}}},[(_vm.columns[v.columnId].inputType === 'select' && !_vm.columns[v.columnId].multiple)?_c('span',[_vm._v(" "+_vm._s(v.multiSelectModel[_vm.columns[v.columnId].selectOptionsLabel])+" ")]):(_vm.columns[v.columnId].inputType === 'select' && _vm.columns[v.columnId].multiple)?_c('span',{staticClass:"multiselect__tags-wrap p-0 m-0"},[(v.multiSelectModel.length)?_c('span',_vm._l((v.multiSelectModel),function(value,k){return _c('span',{key:k,staticClass:"px-1"},[(value.tipo_imp !== '')?_c('span',{staticClass:"multiselect__tag py-1",staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(value.tipo_imp))]):_c('span',[_vm._v(" "+_vm._s(v.label.toString()))])])}),0):_c('span',[_vm._v(" "+_vm._s(v.label.toString()))])]):(_vm.columns[v.columnId].format === 'currency')?_c('span',[_vm._v(" "+_vm._s(_vm._f("numeric")(_vm._f("currency")(v.value,_vm.columns[v.columnId].format),_vm.columns[v.columnId].format))+" ")]):_c('span',[_vm._v(_vm._s(v.value))])]):_vm._e(),(
              _vm.inputBind === `${v.rowId}:${v.columnId}` &&
              _vm.columns[v.columnId].editable &&
              _vm.columns[v.columnId].inputType === 'text' &&
              _vm.columns[v.columnId].format === 'string'
            )?_c('input',{ref:"focusMe",refInFor:true,staticClass:"form-control input-table",style:(_vm.columns[v.columnId].inputStyle),attrs:{"type":_vm.columns[v.columnId].inputType,"name":v.field},domProps:{"value":_vm.model.value},on:{"input":function($event){_vm.model.value = $event.target.value},"keydown":[function($event){$event.stopPropagation();},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {
                _vm.mode = 'readonly';
                _vm.nextCell(_vm.model);
              }).apply(null, arguments)}],"change":function($event){return _vm.bindingText($event.target.value, v, _vm.columns[v.columnId].change)}}}):_vm._e(),(
              _vm.inputBind === `${v.rowId}:${v.columnId}` &&
              _vm.columns[v.columnId].inputType === 'text' &&
              _vm.columns[v.columnId].format === 'currency'
            )?_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.model.masked),expression:"model.masked",modifiers:{"lazy":true}},{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"}],ref:"focusMe",refInFor:true,class:[
              'form-control input-table',
              _vm.columns[v.columnId].inputClass
            ],attrs:{"name":v.field,"id":`${v.field}_${v.id}`,"type":_vm.columns[v.columnId].inputType},domProps:{"value":(_vm.model.masked)},on:{"keydown":[function($event){$event.stopPropagation();},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {
                _vm.mode = 'readonly';
                _vm.nextCell(_vm.model);
              }).apply(null, arguments)}],"focusout":function($event){return _vm.bindingNumber(
                $event.target.value,
                v,
                _vm.columns[v.columnId].change,
                2
              )},"change":function($event){return _vm.$set(_vm.model, "masked", $event.target.value)}}}):_vm._e(),(
              _vm.inputBind === `${v.rowId}:${v.columnId}` &&
              _vm.columns[v.columnId].inputType === 'text' &&
              _vm.columns[v.columnId].format === 'numeric'
            )?_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.model.masked),expression:"model.masked",modifiers:{"lazy":true}},{name:"money",rawName:"v-money",value:(_vm.quantity),expression:"quantity"}],ref:"focusMe",refInFor:true,class:[
              'form-control input-table',
              _vm.columns[v.columnId].inputClass
            ],attrs:{"name":v.field,"id":`quantity_${v.field}_${v.id}`,"type":_vm.columns[v.columnId].inputType},domProps:{"value":(_vm.model.masked)},on:{"keydown":[function($event){$event.stopPropagation();},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {
                _vm.mode = 'readonly';
                _vm.nextCell(_vm.model);
              }).apply(null, arguments)}],"focusout":function($event){return _vm.bindingNumber(
                $event.target.value,
                v,
                _vm.columns[v.columnId].change,
                3
              )},"change":function($event){return _vm.$set(_vm.model, "masked", $event.target.value)}}}):_vm._e(),(
              _vm.inputBind === `${v.rowId}:${v.columnId}` &&
              _vm.columns[v.columnId].editable &&
              _vm.columns[v.columnId].inputType === 'select' &&
              _vm.columns[v.columnId].call
            )?_c('multiselect',{style:(_vm.columns[v.columnId].inputStyle),attrs:{"name":v.field,"input":_vm.columns[v.columnId].selectOptionsList,"options":_vm.columns[v.columnId].selectOptionsList,"label":`${_vm.columns[v.columnId].selectOptionsLabel}`,"track-by":`${_vm.columns[v.columnId].selectOptionsKey}`,"loading":false,"multiple":_vm.columns[v.columnId].multiple,"internal-search":false,"searchable":true,"preserveSearch":false,"allowEmpty":true,"clearOnSelect":false,"optionsLimit":30,"showLabels":false,"hide-selected":true,"showNoOptions":false,"placeholder":"","showNoResults":false},on:{"search-change":function($event){return _vm.search($event, _vm.columns[v.columnId].call)},"close":() => {
                _vm.mode = 'readonly';
                // nextCell(v);
              },"select":function($event){return _vm.bindingMultiSelect(
                _vm.model,
                _vm.columns[v.columnId],
                _vm.columns[v.columnId].change
              )}},model:{value:(_vm.model.multiSelectModel),callback:function ($$v) {_vm.$set(_vm.model, "multiSelectModel", $$v)},expression:"model.multiSelectModel"}}):_vm._e(),(
              _vm.inputBind === `${v.rowId}:${v.columnId}` &&
              _vm.columns[v.columnId].editable &&
              _vm.columns[v.columnId].inputType === 'select' &&
              !_vm.columns[v.columnId].call
            )?_c('select',{ref:"focusMe",refInFor:true,staticClass:"form-control input-table input-select",attrs:{"name":v.field},domProps:{"value":_vm.model.value},on:{"input":function($event){_vm.model.value = $event.target.value},"keydown":[function($event){$event.stopPropagation();},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {
                _vm.mode = 'readonly';
                _vm.nextCell(_vm.model);
              }).apply(null, arguments)}],"change":function($event){return _vm.bindingText($event, v, _vm.columns[v.columnId].change)}}},_vm._l((_vm.columns[v.columnId].selectOptionsList),function(option,index){return _c('option',{key:index,domProps:{"value":_vm.getProperty(option, _vm.columns[v.columnId].selectOptionsKey)}},[_vm._v(" "+_vm._s(_vm.getProperty(option, _vm.columns[v.columnId].selectOptionsLabel))+" ")])}),0):_vm._e(),(
              _vm.columns[v.columnId].editable &&
              _vm.columns[v.columnId].inputType === 'checkbox'
            )?_c('div',{staticClass:"form-check form-switch d-flex justify-content-center",staticStyle:{"display":"block"}},[((_vm.columns[v.columnId].inputType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(v.value),expression:"v.value"}],staticClass:"form-check-input",attrs:{"role":"switch","name":`${v.field}_${v.id}`,"id":`${v.field}_${v.id}`,"type":"checkbox"},domProps:{"value":/true/.test(_vm.rows[k][i].value),"checked":Array.isArray(v.value)?_vm._i(v.value,/true/.test(_vm.rows[k][i].value))>-1:(v.value)},on:{"change":[function($event){var $$a=v.value,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=/true/.test(_vm.rows[k][i].value),$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(v, "value", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(v, "value", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(v, "value", $$c)}},() => {
                  _vm.skipCheckBox = true;
                  _vm.change(v, _vm.columns[v.columnId].change);
                }]}}):((_vm.columns[v.columnId].inputType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(v.value),expression:"v.value"}],staticClass:"form-check-input",attrs:{"role":"switch","name":`${v.field}_${v.id}`,"id":`${v.field}_${v.id}`,"type":"radio"},domProps:{"value":/true/.test(_vm.rows[k][i].value),"checked":_vm._q(v.value,/true/.test(_vm.rows[k][i].value))},on:{"change":[function($event){_vm.$set(v, "value", /true/.test(_vm.rows[k][i].value))},() => {
                  _vm.skipCheckBox = true;
                  _vm.change(v, _vm.columns[v.columnId].change);
                }]}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(v.value),expression:"v.value"}],staticClass:"form-check-input",attrs:{"role":"switch","name":`${v.field}_${v.id}`,"id":`${v.field}_${v.id}`,"type":_vm.columns[v.columnId].inputType},domProps:{"value":/true/.test(_vm.rows[k][i].value),"value":(v.value)},on:{"change":() => {
                  _vm.skipCheckBox = true;
                  _vm.change(v, _vm.columns[v.columnId].change);
                },"input":function($event){if($event.target.composing)return;_vm.$set(v, "value", $event.target.value)}}})]):_vm._e(),(!v.isValid)?_c('span',{staticClass:"text-body p-0 m-0 px-2 bottom-0 position-absolute",staticStyle:{"font-size":"11px"}},[_c('small',[_vm._v(_vm._s(v.message))])]):_vm._e()],1)}),0)}),0):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spinner-border text-center",attrs:{"role":"status"}},[_c('span',{staticClass:"visually-hidden"},[_vm._v("Loading...")])])
}]

export { render, staticRenderFns }