import { mainAxios } from "@/plugins/axios";

class ReasonReversalService {
  async getReasonsByUrl(url, order) {
    const orderField = `?order_field=cod_regra_fiscal&order=${order}&`;
    const splitString = url.split('?');
    const completeUrl = `${splitString[0]}${orderField}${splitString[1]}`;
    const response = await mainAxios.get(`${completeUrl}`);
    return response.data;
  }
  async getPerPage(perPage, order) {
    const response = await mainAxios.get(
      `/motivo-estorno?order_field=cod_mot_estr&order=${order}&per_page=${perPage}`
    );
    return response.data;
  }
  async getReasonById(id) {
    const response = await mainAxios.get(`/motivo-estorno/${id}`);
    return response.data;
  }
  async getReasons(search) {
    const response = await mainAxios.get(search === undefined ? `/motivo-estorno` : `/motivo-estorno?search=${search}`);
    return response.data;
  }
  async createReason(payload) {
    const data = {
      ...payload,
      tipo_estorno_p: payload.tipo_estorno_p === true ? 1 : 0,
      tipo_estorno_s: payload.tipo_estorno_s === true ? 1 : 0,
      tipo_estorno_c: payload.tipo_estorno_c === true ? 1 : 0,
      tipo_estorno_e: payload.tipo_estorno_e === true ? 1 : 0,
      tipo_estorno_t: payload.tipo_estorno_t === true ? 1 : 0,
    };
    const response = await mainAxios.post(`/motivo-estorno`, data);
    return response.data;
  }
  async editReason(id, payload) {
    const data = {
      ...payload,
      tipo_estorno_p: payload.tipo_estorno_p === true || payload.tipo_estorno_p === 1 ? 1 : 0,
      tipo_estorno_s: payload.tipo_estorno_s === true || payload.tipo_estorno_s === 1 ? 1 : 0,
      tipo_estorno_c: payload.tipo_estorno_c === true || payload.tipo_estorno_c === 1 ? 1 : 0,
      tipo_estorno_e: payload.tipo_estorno_e === true || payload.tipo_estorno_e === 1 ? 1 : 0,
      tipo_estorno_t: payload.tipo_estorno_t === true || payload.tipo_estorno_t === 1 ? 1 : 0,
    };
    const response = await mainAxios.put(`/motivo-estorno/${id}`, data);
    return response.data;
  }
  async deleteReason(id) {
    const response = await mainAxios.delete(`/motivo-estorno/${id}`);
    return response.data;
  }
  async editStatus(id, payload) {
    const data = {
      status_mot_estr: payload
    };
    const response = await mainAxios.put(`/motivo-estorno/${id}`, data);
    return response.data;
  }
};

export default new ReasonReversalService();
