import categoryPisCofinsService from '@/services/categoryPisCofins.service';

export const categoryPisCofinsModule = {
  namespaced: true,
  state: {
    categoryPisCofins: {
      links: {},
      meta: {},
      results: []
    },
  },
  mutations: {
    fill_category_pis_cofins(state, category) {
      state.categoryPisCofins = category;
    },
  },
  actions: {
    async list({ commit }, url) {
      const response = await categoryPisCofinsService.list(url);
      return commit('fill_category_pis_cofins', response.data);
    },
  },
  getters: {
    categoryPisCofins: (state) => state.categoryPisCofins
  }
};
