import { dashAxios } from "@/plugins/axios";

class DashboardService {
  async dashboard(payload) {
    // const response = await dashAxios.post(`/empresa/looker/report`, payload);

    const response = await dashAxios.post('/epoc-erp-token', payload);
    return response.data;
  }
}

export default new DashboardService();
