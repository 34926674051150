import { mainAxios, lakeAxios, accessToken, authAxios } from '@/plugins/axios';

class TablePriceService {
  async getCompany() {
    const response = await mainAxios.get(`/empresas?paginate=false`);
    return response.data;
  }

  async getPriceTable(status = true) {
    const response = await mainAxios.get(`/tabela-preco?paginate=false&status=${status}`);
    return response.data;
  }

  async getPriceTableAll(status = true) {
    const response = await mainAxios.get(`/tabela-preco?paginate=false`);
    return response.data;
  }

  async listAll(search = '', limit = '') {
    const response = await mainAxios.get(`/tabela-preco?paginate=false&status=true&search=${search}&limit=${limit}`);
    return response.data;
  }

  async changePriceTable(id, payload) {
    const response = await mainAxios.put(`/empresas/${id}`, payload);
    return response.data;
  }

  async changeStatusPriceTable(id, payload) {
    const response = await mainAxios.put(`/tabela-preco/${id}`, payload);
    return response.data;
  }

  async getProducts(url) {
    const response = await mainAxios.get(url);
    return response.data;
  }

  async searchProduct(term) {
    const response = await mainAxios.get(
      `/gerenciar-empresa?paginate=false&search=${term}`
    );
    return response.data;
  }

  async submitPrice(payload) {
    if (payload.cod_preco_produto === null) {
      const response = await mainAxios.post(`/gerenciar-empresa`, payload);
      return response.data;
    }

    const response = await mainAxios.put(
      `/gerenciar-empresa/${payload.cod_preco_produto}`,
      payload
    );
    return response.data;
  }

  async submitTables(payload) {
    const response = await lakeAxios.post(`/export/`, payload, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('lake')}`,
        'x-grupo': accessToken ? accessToken['x-grupo'] : ''
      }
    });
    return response.data;
  }

  async createTable(payload) {
    const response = await mainAxios.post(`/tabela-preco`, payload);
    return response.data;
  }

  async deleteTable(id) {
    const response = await mainAxios.post(`/tabela-preco/${id}`);
    return response.data;
  }

  async getModules(search = '') {
    const response = await mainAxios.get(`/publicacao?` + search);
    return response.data;
  }

  async pubishStatus(payload) {
    const response = await mainAxios.post(`/pubish/status`, payload);
    return response.data;
  }

  async orderIndexCatalogs(index) {
    let logged = JSON.parse(localStorage.getItem('logged'));

    let params = index ?  logged['x-grupo'] + '/' + index : logged['x-grupo'];
    const response = await authAxios.post(`/catalog-index/` + params , {}, {
      timeout: 320000,
    });
    return response.data;
  }

  async pubishStatusView(cnpj = '') {
    const response = await mainAxios.get(`/pubish/status?cnpj=` + cnpj);
    return response.data;
  }
}

export default new TablePriceService();
