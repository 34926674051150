<template>
  <div>
    <div class="input form-floating">
      <input ref="input" type="text" v-model="param" :id="relation" autocomplete="off" v-bind="$attrs" @input="emitValue"
        @blur="onBlur" class="form-control" :class="!selectedOption[id] ? '' : ''" />
      <div class="spinner" v-if="isLoading">
        <div class="spinner-border" role="status"></div>
      </div>
      <label :for="relation">{{ labelName }}</label>
    </div>
    <div v-if="!isLoading">
      <ul v-if="!selectedOption[id]" class="list" :class="!selectedOption[id] ? 'danger-list' : ''">
        <li class="item" v-for="option in mutableOptions" :key="option[id]" @click="selectOption(option)">
          {{ option[label] }}
        </li>
      </ul>
    </div>
    <p v-if="!selectedOption[id]" class="form-text text-danger">
      Não há {{ textOption }} selecionado. Clique em um {{ textOption }} da
      lista para selecionar
    </p>
  </div>
</template>

<script>
export default {
  name: 'EpInputSearch',

  props: {
    labelName: { type: String, required: true },
    relation: { type: String, required: true },
    textOption: { type: String, required: true },
    id: { type: String, required: true },
    label: { type: String, required: true },
    nameEmit: { type: String, required: true },
    emitSelect: { type: String, required: true },
    options: { type: Array, required: true },
    isLoading: { type: Boolean, required: true },
  },

  data() {
    return {
      param: '',
      selectedOption: {},
      isToSearch: true,
      origanalOptions: [],
      mutableOptions: [],
    };
  },

  watch: {
    options() {
      this.origanalOptions = [...this.options];
      this.mutableOptions = [...this.origanalOptions];
      this.filterOriginalOptions();
    },
    isLoading(newVal) {
      if (newVal === true) {
        this.$refs.input.disabled = true;
      } else {
        this.$refs.input.disabled = false;
        this.$refs.input.focus();
      }
    },
  },

  methods: {
    filterOriginalOptions() {
      if (this.origanalOptions.length > 0) {
        this.mutableOptions = this.origanalOptions.filter(
          (selectableOption) => {
            return selectableOption[this.label]
              .toLowerCase()
              .includes(this.param.toLowerCase());
          }
        );
      }
    },
    selectOption(option) {
      this.selectedOption = option;
      this.param = option[this.label];
      this.$emit(this.emitSelect, this.selectedOption);
      this.isToSearch = true;
    },
    onBlur() {
      this.resetOptions();
    },
    resetOptions() {
      setTimeout(() => {
        this.originalOptions = [];
        this.mutableOptions = [];
      }, 500);
    },
    emitValue() {
      this.selectedOption = '';
      this.$emit(this.emitSelect, []);
      if (this.isToSearch && this.param.length >= 3) {
        this.isToSearch = false;
        const threeCharacter = this.param.slice(0, 3);
        this.$emit(this.nameEmit, threeCharacter);
      }
      if (
        (this.isToSearch && this.param.length >= 3) ||
        this.param.length === 3
      ) {
        this.isToSearch = false;
        this.$emit(this.nameEmit, this.param);
      }
      this.filterOriginalOptions();
    },
  },
};
</script>
<style lang="scss" scoped>
.item {
  padding: 2px 4px 2px 4px;
  cursor: pointer;
}

.item:hover {
  background: #e2e6e866;
}

.list {
  border-radius: 0 0 8px 8px;
  border: 1px solid #c0c5c8;
  position: absolute;
  z-index: 9999999;
  background: white;
  width: 100%;
}

.danger-list {
  border-left: 1px solid #dc3545;
  border-right: 1px solid #dc3545;
  border-bottom: 1px solid #dc3545;
  width: 100%;
}

input.form-control {
  // border-radius: 8px 8px 0 0;
}

.spinner {
  margin-top: -44px;
  margin-left: 85%;

  .spinner-border {
    width: 2rem;
    height: 2rem;
  }
}

.accordion-body .input {
  margin-bottom: 0px;
}

.form-text {
  margin-top: 4px;
}
</style>
