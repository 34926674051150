var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"input form-floating"},[((_vm.type)==='checkbox')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.selectedOption[_vm.label]),expression:"selectedOption[label]"}],ref:"input",staticClass:"form-control input-select",class:{ inputActive: _vm.arrowCounter === -1 },attrs:{"autocomplete":"off","placeholder":_vm.placeholderName,"aria-label":_vm.ariaLabelName,"id":_vm.idName,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.selectedOption[_vm.label])?_vm._i(_vm.selectedOption[_vm.label],null)>-1:(_vm.selectedOption[_vm.label])},on:{"input":_vm.verifyIfOptionIsValidAndEmitValue,"click":() => {
          _vm.receiveShowedOptions();
          _vm.showSelectableOptions = true;
        },"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.onSelect.apply(null, arguments)},"keydown":_vm.onKeydown,"change":function($event){var $$a=_vm.selectedOption[_vm.label],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.selectedOption, _vm.label, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.selectedOption, _vm.label, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.selectedOption, _vm.label, $$c)}}}},'input',_vm.$attrs,false)):((_vm.type)==='radio')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.selectedOption[_vm.label]),expression:"selectedOption[label]"}],ref:"input",staticClass:"form-control input-select",class:{ inputActive: _vm.arrowCounter === -1 },attrs:{"autocomplete":"off","placeholder":_vm.placeholderName,"aria-label":_vm.ariaLabelName,"id":_vm.idName,"type":"radio"},domProps:{"checked":_vm._q(_vm.selectedOption[_vm.label],null)},on:{"input":_vm.verifyIfOptionIsValidAndEmitValue,"click":() => {
          _vm.receiveShowedOptions();
          _vm.showSelectableOptions = true;
        },"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.onSelect.apply(null, arguments)},"keydown":_vm.onKeydown,"change":function($event){return _vm.$set(_vm.selectedOption, _vm.label, null)}}},'input',_vm.$attrs,false)):_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.selectedOption[_vm.label]),expression:"selectedOption[label]"}],ref:"input",staticClass:"form-control input-select",class:{ inputActive: _vm.arrowCounter === -1 },attrs:{"autocomplete":"off","placeholder":_vm.placeholderName,"aria-label":_vm.ariaLabelName,"id":_vm.idName,"type":_vm.type},domProps:{"value":(_vm.selectedOption[_vm.label])},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.selectedOption, _vm.label, $event.target.value)},_vm.verifyIfOptionIsValidAndEmitValue],"click":() => {
          _vm.receiveShowedOptions();
          _vm.showSelectableOptions = true;
        },"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.onSelect.apply(null, arguments)},"keydown":_vm.onKeydown}},'input',_vm.$attrs,false)),_c('img',{staticClass:"fake-select",class:_vm.showSelectableOptions ? 'active' : '',attrs:{"src":require("../../assets/img/Vector.svg"),"alt":""},on:{"click":_vm.receiveShowedOptions}}),_c('label',{attrs:{"for":_vm.idName}},[_vm._v(_vm._s(_vm.labelName))])]),(_vm.showSelectableOptions)?_c('div',{staticClass:"options"},[(_vm.selectedOption[_vm.label] && _vm.isOptionValid)?_c('p',{staticClass:"pointer create-option",on:{"click":_vm.createOption}},[_c('strong',{staticClass:"fw-normal"},[_vm._v("Criar")]),_vm._v(" "+_vm._s(_vm.selectedOption[_vm.label])+" ")]):_vm._e(),_c('p',{staticClass:"small-text"},[_c('u',[_vm._v("Selecione")]),_vm._v(" a "+_vm._s(_vm.fieldName)+" ou crie uma nova ")]),_c('ul',{staticClass:"list"},_vm._l((_vm.selectableOptions),function(option,index){return _c('li',{key:option[_vm.id],ref:`option_${index}`,refInFor:true,class:{ pillActive: _vm.arrowCounter === index },on:{"click":function($event){return _vm.choseOption(option, index)}}},[(option.cor)?_c('span',{staticClass:"pill",style:('background-color:' + option.cor)},[_vm._v(_vm._s(option[_vm.label])+" ")]):_c('span',{staticClass:"pill",style:('background-color:rgb(233, 237, 239)')},[_vm._v(_vm._s(option[_vm.label])+" ")])])}),0)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }