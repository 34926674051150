import ExchangeService from '@/services/exchange.service';

export const exchangeModule = {
  namespaced: true,
  state: {
    exchange: {
      links: {},
      meta: {},
      results: []
    },
    queryString: '',
    url: undefined
  },
  mutations: {
    fill_exchange(state, exchange) {
      state.exchange = exchange;
    }
  },
  actions: {
    list: async function({ commit }) {
      let url =
        this.state.url === undefined
          ? '/permuta?page=1'
          : this.state.url;
      const response = await ExchangeService.list(url + '&' + this.state.queryString);
      return response.code === 200 ? commit('fill_exchange', response.data) : null;
    }
  },
  getters: {
    exchange: (state) => state.exchange
  }
};
