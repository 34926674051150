<template>
  <div class="d-flex" style="margin-bottom: 24px">
    <div class="col-auto" v-if="paginateScroll">
      <div class="d-none p-0 m-0">
        <select
          class="form-select select-pages"
          style="max-height: 40px; width: 100px !important"
          :value="paginate.per_page"
          @change="paginate.perPage($event.target.value)"
        >
          <option value="15">15</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option :value="paginate.total">Todos</option>
        </select>
        <span class="text-results p-0 m-0">
          Resultados: {{ paginate.to }} de
          {{ paginate.total }}
        </span>
      </div>
    </div>
    <div class="col">
      <EpFilter :filter="filter" :filters="filters"></EpFilter>
    </div>
    <div
      class="col-auto col-sm-auto col-md-auto col-lg-auto d-flex m-auto text-end"
    >
      <div
        style="font-size: 0.8rem"
        class="d-flex text-secondary text-end ms-1 m-auto"
        :key="i"
        v-for="(status, i) in legend"
      >
        <span :class="[status.symbol, 'rounded mt-1 mx-1']"></span>
        {{ status.title }}
      </div>
    </div>
  </div>
</template>

<script>
import EpFilter from '@/components/global/EpFilter.vue';

export default {
  name: 'EpStickTableHeader',
  components: { EpFilter },
  props: {
    filter: {},
    filters: {},
    legend: {
      Array,
      length: 0,
      Object: {
        title: String,
        symbol: String,
        native: Boolean
      }
    },
    paginate: {
      to: null,
      from: null,
      per_page: 15,
      total: null,
      perPage: (value) => {}
    },
    paginateScroll: Boolean
  },
  watch: {},
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .ellipse {
    /* Ellipse 134 */
    width: 10px;
    height: 10px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .ellipse-danger {
    /* Feedback/Warning/Warning Pure */
    background: #fa2349;
  }

  .ellipse-light {
    /* Feedback/Warning/Warning Pure */
    background: #dcdde4;
  }

  .ellipse-warning {
    /* Feedback/Warning/Warning Pure */
    background: #fc8332;
  }
}
</style>
